import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getClient } from '../../../api-utils/general-utils';
import {
  GetAccountDto,
  GetAccountDtoPayroFinanceStatusEnum,
} from '../../../api-utils/generated-client';

import { formatNumberAsDollars } from '../../../utils';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Loader from '../../../widgets/Loader';
import PayroButton from '../../../widgets/PayroButton';
import './index.scss';
import ApprovedIcon from './approved-icon.svg';
import DeclinedIcon from './declined-icon.png';
import ApplicationSubmittedIcon from './application-submitted-icon.png';

import { useContext } from 'react';
import { MessageContext } from '../../../context';
import payroLogo from '../../../../public/color-logo-new.svg';
import lendioLogo from './lendioLogo.png';
const AccountStatusPage = () => {
  const [accountInfo, setAccountInfo] = useState<GetAccountDto>();
  const [accountStatus, setAccountStatus] =
    useState<GetAccountDtoPayroFinanceStatusEnum>();
  const [selectedDate, setSelectedDate] = useState(null);
  const history = useHistory();
  const [isBorrowerQualified, setIsBorrowerQualified] =
    useState<boolean>(false);
  const [hasLendioApprovalAmount, setHasLendioApprovalAmount] =
    useState<boolean>(false);
  const [lendioApprovalAmount, setLendioApprovalAmount] =
    useState<number>(0);
  const [lendioDto, setLendioDto] = useState<any>();
  const messageContext = useContext(MessageContext);
  const [opportunityInfo, setOpportunityInfo] = useState<any>();
  useEffect(() => {
    const fetchApi = async () => {
      const client = await getClient();
      if (!client) {
        return;
      }
      const accountRes = await client.accountsControllerGetMyInfo();
      setAccountInfo(accountRes.data);
      setAccountStatus(accountRes.data.payro_finance_status);

      const opp = await client.opportunitiesControllerFindAll();
      const res = opp.data[0].is_borrower_qualified;
      const approval_amount = opp.data[0].lendio_max_approval;
      // console.log(opp.data[0], 'HERE');
      setLendioDto(opp.data[0]);
      if (res == 'No') {
        setIsBorrowerQualified(false);
      } else if (res == null) {
        setIsBorrowerQualified(true);
      }
      if (res == 'Yes' && approval_amount && approval_amount > 0) {
        setLendioApprovalAmount(approval_amount);
        setHasLendioApprovalAmount(true);
      }
      setOpportunityInfo(opp.data);
    };

    fetchApi();
  }, []);

  if (!accountInfo || !opportunityInfo) {
    return <Loader></Loader>;
  }

  const launchLoanApplication = () => {
    if (window.lendio) {
      window.lendio.launchLoanApplication();
    } else {
      // console.error('Lendio SDK is not available.');
    }
  };
  let titleText;
  let subtileText;
  let logo;
  let showLendioButton;
  switch (accountStatus) {
    case GetAccountDtoPayroFinanceStatusEnum.Approved:
      titleText = 'Application Approved';
      logo = ApprovedIcon;
      break;
    case GetAccountDtoPayroFinanceStatusEnum.New:
      titleText = 'Awesome! Your application was submitted.';
      logo = ApplicationSubmittedIcon;
      subtileText =
        'We are currently reviewing your application, check back here periodically to see the status of your application.';
      break;
    case GetAccountDtoPayroFinanceStatusEnum.Declined:
      titleText = !hasLendioApprovalAmount
        ? 'Application Declined'
        : '';
      logo = !hasLendioApprovalAmount ? DeclinedIcon : '';
      subtileText = !hasLendioApprovalAmount
        ? 'We are sorry to have to decline your application.  Declined applicants may wait 3 months and try again.'
        : '';
      showLendioButton = isBorrowerQualified;
      break;
  }
  if (!titleText && !hasLendioApprovalAmount) {
    titleText = 'Application Under Review';
  }
  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };
  return (
    <>
      <div
        id={
          accountStatus == GetAccountDtoPayroFinanceStatusEnum.New
            ? 'funding-status-header-wrapper-under-review'
            : 'funding-status-header-wrapper'
        }
      >
        <img id="funding-status-icon" src={logo}></img>
        <h2 id="funding-status-text">{titleText}</h2>
        <p className="application-status-subtitle">{subtileText}</p>
        {hasLendioApprovalAmount &&
          accountInfo.completed_lendio_application && (
            <>
              <div>
                <div className="lendio-container-main-submitted">
                  <div className="lendio-title-button-wrapper">
                    <div className="lendio-header-and-payro-logo-subwrapper">
                      <img
                        className="payro-logo-lendio-page"
                        width={130}
                        height={130}
                        src={'/color-logo-new.svg'}
                      ></img>
                      <div className="lineStyle"></div>
                      <p className="lendio-header-text">
                        Powered by Lendio<span className="tm">™</span>
                      </p>

                      <div className="lineStyleSmall"></div>
                      <img
                        width={10}
                        height={10}
                        className="lendio-logo"
                        src={lendioLogo}
                      ></img>
                      {'  '}
                    </div>
                    {/* <button
                      onClick={() => launchLoanApplication()}
                      className="apply-now-button"
                      // endIcon={arrowLeft}
                      //variant="white"
                    >
                      Go to Lendio
                    </button> */}
                  </div>

                  <p className="lendio-header-and-payro-title-wrapper">
                    Your application has been submitted.
                  </p>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault(); // Prevents default anchor behavior
                      launchLoanApplication(); // Calls the function when clicked
                    }}
                    className="lendio-link-style"
                    //className="apply-now-button"
                  >
                    View my application
                  </a>
                </div>
              </div>
            </>
          )}
        {hasLendioApprovalAmount &&
          !accountInfo.completed_lendio_application && (
            <>
              <div className="lendio-container-main">
                <div className="header-icon-wrapper-lendio">
                  <img
                    width={60}
                    height={60}
                    src={ApprovedIcon}
                  ></img>
                  <p className="lendio-header-and-payro-title-wrapper">
                    You're pre-qualified for business financing
                    through our preferred partner.
                  </p>
                  <p className="subtitle-lendio">
                    While you did not qualify for Payroll Funding
                    today, we found you another option through our
                    preferred partner, Lendio, a small business
                    financing marketplace.
                  </p>
                </div>
                {/* <div className="grey-divider-line-lendio"></div> */}

                <div className="wrapper-lendio-sub-title-explanation">
                  {/* <p className="lendio-header-and-payro-sub-title-wrapper">
                  While you did not qualify for funding with Payro
                  today, we found you another option through Lendio.
                  Lendio helps you compare options from 75+ lenders
                  all with a single application. Their team of experts
                  will help you select the right financing for your
                  business and get funded quickly.
                </p> */}
                </div>

                <div className="lendio-header-and-logo-subwrapper"></div>
                <div className="lendio-data-container">
                  <div className="lendio-title-button-wrapper">
                    <div className="lendio-header-and-payro-logo-subwrapper">
                      <img
                        className="payro-logo-lendio-page"
                        width={130}
                        height={130}
                        src={'/color-logo-new.svg'}
                      ></img>
                      <div className="lineStyle"></div>
                      <p className="lendio-header-text">
                        Powered by Lendio<span className="tm">™</span>
                      </p>

                      <div className="lineStyleSmall"></div>
                      <img
                        width={10}
                        height={10}
                        className="lendio-logo"
                        src={lendioLogo}
                      ></img>
                      {'  '}
                    </div>
                    <button
                      onClick={() => launchLoanApplication()}
                      className="apply-now-button"
                      // endIcon={arrowLeft}
                      //variant="white"
                    >
                      Apply now
                    </button>
                  </div>
                  <p className="lendio-title-amount">
                    You're pre-qualified for up to {''}$
                    {Math.floor(
                      lendioApprovalAmount,
                    ).toLocaleString()}
                    .
                  </p>

                  <div className="grey-divider-line-lendio"></div>
                  <div className="lendio-subtitle-and-amounts-main-wrapper ">
                    <div className="lendio-subtitle-and-amount-wrapper ">
                      <p className="lendio-subtitles-main">
                        Pre-qualified amount
                      </p>
                      <p className="lendio-subtitle-amounts-main">
                        $
                        {Math.floor(
                          lendioDto.lendio_min_approval,
                        ).toLocaleString()}
                        - $
                        {Math.floor(
                          lendioDto.lendio_max_approval,
                        ).toLocaleString()}
                      </p>
                    </div>

                    <div className="lendio-subtitle-and-amount-wrapper ">
                      <p className="lendio-subtitles">
                        Interest Rates
                      </p>
                      <p className="lendio-subtitle-amounts">
                        {Math.round(lendioDto.lendio_min_factor_rate)}
                        -
                        {Math.round(lendioDto.lendio_max_factor_rate)}
                        %
                      </p>
                    </div>
                    <div className="lendio-subtitle-and-amount-wrapper ">
                      <p className="lendio-subtitles">
                        Repayment Time
                      </p>
                      <p className="lendio-subtitle-amounts">
                        {lendioDto.lendio_min_term} -
                        {lendioDto.lendio_max_term} Months
                      </p>
                    </div>
                    <div className="lendio-subtitle-and-amount-wrapper ">
                      <p className="lendio-subtitles">
                        Product Types
                      </p>
                      <p className="lendio-subtitle-amounts">
                        Line of credit, Term loan, Working capital
                      </p>
                    </div>
                  </div>
                </div>
                <p className="disclosure-text">
                  Advertising Disclosure: Lendio may provide
                  compensation to the entity who referred you for
                  financing products and services listed on our site.
                  This compensation may impact how and where certain
                  products and services are offered to you. We may not
                  list all financing products and services available
                  to you. California loans made pursuant to the
                  California Financing Law, Division 9 (commencing
                  with Section 22000) of the Finance Code. All such
                  loans are made through Lendio Partners, LLC, a
                  wholly-owned subsidiary of Lendio, Inc.
                </p>
              </div>
            </>
          )}

        {showLendioButton && (
          <>
            <div className="loan-button-dashboard-container">
              <PayroButton
                buttonSize="large"
                onClick={() => launchLoanApplication()}
              >
                {!accountInfo.completed_lendio_application
                  ? 'Apply for a Lendio Loan'
                  : 'View Lendio Application '}
              </PayroButton>
            </div>
          </>
        )}
      </div>

      {/* {accountStatus == GetAccountDtoPayroFinanceStatusEnum.New && (
        <div className="schedule-container">
          <p className="under-review-additional-text">
            Feel free to schedule a call with an underwriter if you
            have any questions
          </p>
          <p className="phone-number">
            <img
              className="phone-icon-under-review"
              src={PhoneIcon}
              alt="image"
              width={20}
              height={20}
            />
            1-833-271-4499
          </p>
          <button className="timebutton"><img src='./Clndr.svg' alt=""/>
            <DatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              className='calandr'
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="MMMM d, yyyy h:mm aa"
              timeCaption="Time"
              placeholderText="Select a date and time"
            />
            Select a time
          </button>
          <button className="timecanclebutton"><img src='' alt=""/>Cancel Application</button>
        </div>
      )} */}

      {accountStatus ==
        GetAccountDtoPayroFinanceStatusEnum.Approved && (
        <div className="credit-amount-wrapper">
          <p className="credit-number-label">Line of Credit</p>
          <p className="credit-number">
            {accountInfo.approved_credit_amount
              ? formatNumberAsDollars(
                  accountInfo.approved_credit_amount,
                )
              : ''}
          </p>
          <PayroButton onClick={() => history.push('')}>
            Request Funding
          </PayroButton>
        </div>
      )}
    </>
  );
};

export default AccountStatusPage;
