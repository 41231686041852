import { useEffect, useState, useContext } from 'react';
import { getJwt, signUp } from '../utils/auth-utils';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './SignUp.css';
import ConfirmEmail from '../ConfirmEmail';

import PayroButton from '../../widgets/PayroButton';
import { SHOW_EMAIL_CONFIRMATION_FORM } from '../utils/constants';
import PayroInput from '../../widgets/PayroInput';
import TitleSection from '../../Header/title-section';

import { MessageContext } from '../../context';
import InfoIcon from '../../common-icons/info-icon.svg';
import passwordNoIcon from './password-no-icon.svg';
import passwordYesIcon from './password-yes-icon.png';
import { getClient } from '../../api-utils/general-utils';
import { CheckForSalesforceAccountDTO } from '../../api-utils/generated-client';
import { useRecoilState } from 'recoil';
import { Link } from 'react-router-dom';

import { useHistory, useLocation } from 'react-router-dom';
import {
  payrollPartnerState,
  showSignUpDesignRecoil,
} from '../../recoil-state/general-states';
import axios from 'axios';
import Loader from '../../widgets/Loader';
import { PayrollPartner } from './payrollCompanies';
import EnrollNowDetails from './EnrollNowDetails';

enum PageOptions {
  SIGN_UP,
  EMAIL_CONFIRMATION,
}

const noValidations = {
  firstName: false,
  lastName: false,
  phone: false,
  email: false,
  companyName: false,
  password: false,
  confirmedPassword: false,
  payrollAmount: false,
};

const REFERRAL_SOURCE = 'rs';

export default function SignUpForm() {
  interface SignUpFields {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    companyName: string;
    password: string;
    confirmedPassword: string;
    payrollCompany: string;
    payrollAmount: number;
  }

  const [signUpFields, setSignUpFields] = useState<SignUpFields>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    companyName: '',
    password: '',
    confirmedPassword: '',
    payrollCompany: '',
    payrollAmount: 0,
  });

  const [pageToShow, setPageToShow] = useState<PageOptions>(
    PageOptions.SIGN_UP,
  );
  const [payrollCompanies, setPayrollCompanies] =
    useState<PayrollPartner[]>();
  const [payrollCompanyDetails, setPayrollCompanyDetails] =
    useRecoilState(payrollPartnerState);
  const [validationsToShow, setValidationsToShow] =
    useState<any>(noValidations);
  const [hideNonPasswordFields, setHideNonPasswordFields] =
    useState(false);
  const [checked, setCheckBox] = useState(false);
  const [agreeToTermsConditions, setAgreeToTermsConditions] =
    useState(false);
  const [buttonDisabled, setButtonDisabled] =
    useState<boolean>(false);
  const [showPasswordIcons, setShowPasswordIcons] =
    useState<boolean>(false);
  const [checkedPhone, setCheckedPhone] = useState<boolean>(false);
  const [checkedPhoneClassname, setCheckedPhoneClassname] =
    useState<string>('');
  const [checkedTermsAndConditions, setCheckedTermsAndConditions] =
    useState<boolean>(false);
  const [
    checkedTermsAndConditionsClassname,
    setCheckedTermsAndConditionsClassname,
  ] = useState<string>('');
  const [showSignUpDesign, setShowSignUpDesign] = useRecoilState(
    showSignUpDesignRecoil,
  );

  const messageContext = useContext(MessageContext);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/accounts/iso-links`)
      .then((res) => {
        setPayrollCompanies(res.data);
      });
  }, []);

  useEffect(() => {
    if (localStorage.getItem(SHOW_EMAIL_CONFIRMATION_FORM)) {
      setPageToShow(PageOptions.EMAIL_CONFIRMATION);
    }
    if (pageToShow == PageOptions.SIGN_UP) {
      setShowSignUpDesign(true);
    } else {
      setShowSignUpDesign(false);
    }
    const searchString = location.search;

    if (searchString) {
      let searchParams = new URLSearchParams(searchString);
      const rs = searchParams.get('partner');

      if (rs) {
        console.log('rs', rs);
        if (!payrollCompanies) {
          return;
        }
        const selectedCompany = payrollCompanies.find((el) => {
          return el.partner_name === rs.toLowerCase();
        });
        if (!selectedCompany) {
          return;
        }

        localStorage.setItem(
          REFERRAL_SOURCE,
          selectedCompany.partner_account_uuid,
        );
        setPayrollCompanyDetails(selectedCompany);
      }
    }
  });

  useEffect(() => {
    const initializeInfoFromWebToken = async () => {
      if (pageToShow == PageOptions.SIGN_UP) {
        const urlParams = new URLSearchParams(window.location.search);
        const webToken = urlParams.get('webtoken');
        console.log('webtoken', webToken);
        if (!webToken) {
          return;
        }
        const client = await getClient(true);
        if (!client) {
          return;
        }
        let leadRes;
      }
    };
    initializeInfoFromWebToken().then((res) => console.log('done'));
  }, []);

  const onFormSubmit = async () => {
    setButtonDisabled(true);
    const {
      firstName,
      lastName,
      email,
      password,
      phone,
      companyName,
      payrollCompany,
      payrollAmount,
    } = signUpFields;
    messageContext.clearMessages();

    const contactinfo: CheckForSalesforceAccountDTO = {
      phone: phone,
      email: email,
    };
    const client = await getClient(true);
    if (!client) {
      return;
    }

    const checkIfContactInfoMatchesExistingSalesforceAccounts =
      async (contactinfo: CheckForSalesforceAccountDTO) => {
        // sa: this endpoint will return false if a cognito account already exists with this email
        const res: any =
          await client.accountsControllerCheckIfNewSignUpAlreadyHasSalesforceAccountsOrHasMultipleOrHasCognito(
            contactinfo,
          );

        if (res.data == 'call') {
          setButtonDisabled(false);
          messageContext.addMessage({
            message:
              'Your account needs some TLC. Please call us at 1-833-271-4499',
            level: 'error',
          });
          throw 'multiple accounts detected. Please call us at 1-833-271-4499';
        } else if (res.data) {
          window.location.href = `/activateportalaccount?contactEmail=${email}`;
          setButtonDisabled(false);
        }
        return res;
      };
    const connectedAlreadyRes =
      await checkIfContactInfoMatchesExistingSalesforceAccounts(
        contactinfo,
      );
    if (!connectedAlreadyRes.data) {
      signUp(
        firstName,
        lastName,
        email,
        password,
        phone,
        companyName,
        payrollCompany,
        payrollAmount,
        localStorage.getItem(REFERRAL_SOURCE),
      )
        .then(({ user, userSub }) => {
          localStorage.setItem(SHOW_EMAIL_CONFIRMATION_FORM, 'yes');
          localStorage.setItem('email', email);
          localStorage.setItem('password', password);
          localStorage.setItem('firstName', firstName);
          localStorage.setItem('lastName', lastName);
          localStorage.setItem('phone', phone);
          localStorage.setItem('companyName', companyName);
          localStorage.setItem('payrollCompany', payrollCompany);
          localStorage.setItem(
            'payrollAmount',
            payrollAmount.toString(),
          );
          client
            .accountsControllerCreateFromIdToken({
              username: userSub,
              firstName,
              lastName,
              email,
              isValid: false,
              phoneNumber: `+1${phone}`,
              companyName,
              payrollCompany,
            })
            .then(() => {
              setPageToShow(PageOptions.EMAIL_CONFIRMATION);
            })
            .catch((err: any) => {
              console.log(err);
            });
        })
        .catch((err) => {
          setButtonDisabled(false);
          let errorMessage = (err.message ?? '') as string;
          if (
            errorMessage
              .toLowerCase()
              .includes('email already exists')
          ) {
            errorMessage =
              errorMessage +
              ' Try just logging in on the top right of your screen.';
          }
          messageContext.addMessage({
            message: errorMessage,
            level: 'error',
          });
        });
    }
  };

  if (!payrollCompanies) {
    return <Loader />;
  }

  switch (pageToShow) {
    case PageOptions.EMAIL_CONFIRMATION:
      return <ConfirmEmail />;
    case PageOptions.SIGN_UP:
      const urlParams = new URLSearchParams(window.location.search);

      const hasUpperCase = /[A-Z]/;
      const hasLowerCase = /[a-z]/;
      const hasNumbers = /\d/;
      const isTenNumbers = /^[0-9]{10}$/;
      const hasNonalphas = /\W/;

      const {
        firstName,
        lastName,
        email,
        password,
        phone,
        companyName,
        confirmedPassword,
        payrollCompany,
      } = signUpFields;

      const validationFunctions: { [key: string]: boolean } = {
        firstName: firstName.length < 1,
        lastName: lastName.length < 1,
        phone: !isTenNumbers.test(phone),
        email:
          email.length < 5 ||
          email.indexOf('@') < 1 ||
          email.lastIndexOf('.') < 3,
        companyName: companyName.length < 1,

        password:
          password.length < 10 ||
          !hasUpperCase.test(password) ||
          !hasLowerCase.test(password) ||
          !hasNumbers.test(password) ||
          !hasNonalphas.test(password),
        confirmedPassword:
          confirmedPassword.length < 10 ||
          password !== confirmedPassword,
        checkedPhone: checkedPhone == false,
        checkedTermsAndConditions: checkedTermsAndConditions == false,
      };

      let allValid =
        Object.keys(validationFunctions).every(
          (fieldName) => !validationFunctions[fieldName],
        ) &&
        checkedPhone &&
        checkedTermsAndConditions;

      const validateOnClick = () => {
        if (!allValid) {
          setValidationsToShow({
            ...validationsToShow,
            firstName: !validationFunctions[firstName] ? true : '',
            lastName: !validationFunctions[lastName] ? true : '',
            email: !validationFunctions[email] ? true : '',
            phone: !validationFunctions[phone] ? true : '',
            companyName: !validationFunctions[companyName]
              ? true
              : '',
            password: !validationFunctions[password] ? true : '',
            confirmedPassword: !validationFunctions[confirmedPassword]
              ? true
              : '',
            checkedPhone: checkedPhone
              ? setCheckedPhoneClassname('')
              : setCheckedPhoneClassname('unchecked'),
            checkedTermsAndConditions: checkedTermsAndConditions
              ? setCheckedTermsAndConditionsClassname('')
              : setCheckedTermsAndConditionsClassname('unchecked'),
          });
        }
      };
      return (
        <HelmetProvider>
          <div id="sign-up-outer-wrapper">
            <Helmet>
              <link
                rel="canonical"
                href="https://portal.payrofinance.com/sign-up"
              />
            </Helmet>
            <div id="pay-on-time-section">
              <EnrollNowDetails />
            </div>

            <div className="main-body " id="signup-section-wrapper">
              {/* <TitleSection
                title="Get Started with Payroll Funding"
                centered
                subtitle="Apply now. Approval takes 48 hours."
              /> */}

              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  onFormSubmit();
                }}
              >
                <div>
                  {!hideNonPasswordFields && (
                    <>
                      <div className="name-inputs">
                        <PayroInput
                          onFocus={() =>
                            setValidationsToShow({
                              ...validationsToShow,
                              firstName: false,
                            })
                          }
                          onBlurFunction={() =>
                            setValidationsToShow({
                              ...validationsToShow,
                              firstName: true,
                            })
                          }
                          error={
                            validationsToShow.firstName &&
                            validationFunctions.firstName
                          }
                          onChange={(e: any) =>
                            setSignUpFields({
                              ...signUpFields,
                              firstName: e,
                            })
                          }
                          required
                          id="first-name"
                          label="First name"
                          value={signUpFields.firstName}
                          variant="signUp"
                        />
                        <PayroInput
                          onFocus={() =>
                            setValidationsToShow({
                              ...validationsToShow,
                              lastName: false,
                            })
                          }
                          onBlurFunction={() =>
                            setValidationsToShow({
                              ...validationsToShow,
                              lastName: true,
                            })
                          }
                          error={
                            validationsToShow.lastName &&
                            validationFunctions.lastName
                          }
                          onChange={(e: any) =>
                            setSignUpFields({
                              ...signUpFields,
                              lastName: e,
                            })
                          }
                          required
                          id="last-name"
                          label="Last name"
                          value={signUpFields.lastName}
                          variant="signUp"
                        />
                      </div>
                      <div>
                        <PayroInput
                          onFocus={() =>
                            setValidationsToShow({
                              ...validationsToShow,
                              email: false,
                            })
                          }
                          onBlurFunction={() =>
                            setValidationsToShow({
                              ...validationsToShow,
                              email: true,
                            })
                          }
                          error={
                            validationsToShow.email &&
                            validationFunctions.email
                          }
                          onChange={(e: any) =>
                            setSignUpFields({
                              ...signUpFields,
                              email: e,
                            })
                          }
                          required
                          id="email"
                          label="Email"
                          value={signUpFields.email}
                          variant="signUp"
                        />

                        <div className="input-info-message">
                          <img
                            src={InfoIcon}
                            width={12}
                            height={12}
                          ></img>
                          <p className="email-text">
                            A verification code will be sent to this
                            email
                          </p>
                        </div>
                      </div>
                      <div>
                        <PayroInput
                          onFocus={() =>
                            setValidationsToShow({
                              ...validationsToShow,
                              phone: false,
                            })
                          }
                          onBlurFunction={() =>
                            setValidationsToShow({
                              ...validationsToShow,
                              phone: true,
                            })
                          }
                          error={
                            validationsToShow.phone &&
                            validationFunctions.phone
                          }
                          onChange={(e: any) =>
                            setSignUpFields({
                              ...signUpFields,
                              phone: e.toString(),
                            })
                          }
                          required
                          id="phone"
                          label="Phone"
                          isPhone={true}
                          helperText={
                            !validationsToShow.phone
                              ? ''
                              : validationFunctions.phone
                              ? 'We require a valid 10 digit number'
                              : ''
                          }
                          value={signUpFields.phone}
                          variant="signUp"
                        />
                      </div>

                      <div className="input-info-message">
                        <input
                          type="checkbox"
                          onChange={() => {
                            setCheckedPhone(!checkedPhone);
                            setCheckedPhoneClassname('');
                          }}
                          className="checkbox-design"
                          onError={
                            validationsToShow.checkedPhone &&
                            validationFunctions.checkedPhone
                          }
                        />
                        <p
                          id="conditions-message"
                          className={checkedPhoneClassname}
                        >
                          This phone number can receive text for
                          2-factor authorization
                        </p>
                      </div>

                      <div>
                        <PayroInput
                          onFocus={() =>
                            setValidationsToShow({
                              ...validationsToShow,
                              companyName: false,
                            })
                          }
                          onBlurFunction={() =>
                            setValidationsToShow({
                              ...validationsToShow,
                              companyName: true,
                            })
                          }
                          error={
                            validationsToShow.companyName &&
                            validationFunctions.companyName
                          }
                          onChange={(e: any) =>
                            setSignUpFields({
                              ...signUpFields,
                              companyName: e,
                            })
                          }
                          required
                          id="company-name"
                          label="Company name"
                          value={signUpFields.companyName}
                          variant="signUp"
                        />
                      </div>
                    </>
                  )}
                  <div className="add-spacing-container"></div>
                  <div>
                    <PayroInput
                      onFocus={() => {
                        setShowPasswordIcons(true);
                        setValidationsToShow({
                          ...validationsToShow,
                          password: false,
                        });
                      }}
                      onBlurFunction={() => {
                        setShowPasswordIcons(false);
                        setValidationsToShow({
                          ...validationsToShow,
                          password: true,
                        });
                      }}
                      error={
                        validationsToShow.password &&
                        validationFunctions.password
                      }
                      onChange={(e: any) =>
                        setSignUpFields({
                          ...signUpFields,
                          password: e,
                        })
                      }
                      required
                      id="the-password"
                      label="Create password"
                      type="password"
                      autoComplete="current-password"
                      variant="signUp"
                      onCopy={(e: any) => {
                        e.preventDefault();
                        return false;
                      }}
                      onPaste={(e: any) => {
                        e.preventDefault();
                        return false;
                      }}
                    />
                  </div>
                  {showPasswordIcons ? (
                    <>
                      <div className="password-info-message">
                        <span className="password-error-text">
                          <img
                            className="password-icon"
                            src={
                              password.length < 10
                                ? passwordNoIcon
                                : passwordYesIcon
                            }
                            height={password.length < 10 ? '' : 13}
                            width={password.length < 10 ? '' : 13}
                          ></img>
                          10 characters{' '}
                        </span>
                        <span className="password-error-text">
                          <img
                            className="password-icon"
                            src={
                              !hasUpperCase.test(password)
                                ? passwordNoIcon
                                : passwordYesIcon
                            }
                            height={
                              !hasUpperCase.test(password) ? '' : 13
                            }
                            width={
                              !hasUpperCase.test(password) ? '' : 13
                            }
                          ></img>
                          Uppercase{' '}
                        </span>
                      </div>
                      <div className="password-info-message-two">
                        <span className="password-error-text">
                          <img
                            className="password-icon"
                            src={
                              !hasLowerCase.test(password)
                                ? passwordNoIcon
                                : passwordYesIcon
                            }
                            height={
                              !hasLowerCase.test(password) ? '' : 13
                            }
                            width={
                              !hasLowerCase.test(password) ? '' : 13
                            }
                          ></img>
                          Lowercase
                        </span>
                        <span className="password-error-text">
                          <img
                            className="password-icon"
                            src={
                              !hasNumbers.test(password)
                                ? passwordNoIcon
                                : passwordYesIcon
                            }
                            height={
                              !hasNumbers.test(password) ? '' : 13
                            }
                            width={
                              !hasNumbers.test(password) ? '' : 13
                            }
                          ></img>
                          Number
                        </span>
                      </div>
                      <div className="password-info-message-three">
                        <span className="password-error-text">
                          <img
                            className="password-icon"
                            src={
                              !hasNonalphas.test(password)
                                ? passwordNoIcon
                                : passwordYesIcon
                            }
                            height={
                              !hasNonalphas.test(password) ? '' : 13
                            }
                            width={
                              !hasNonalphas.test(password) ? '' : 13
                            }
                          ></img>
                          Special Character
                        </span>
                      </div>
                    </>
                  ) : (
                    <div className="add-spacing-container"></div>
                  )}

                  <div>
                    <PayroInput
                      onFocus={() =>
                        setValidationsToShow({
                          ...validationsToShow,
                          confirmedPassword: false,
                        })
                      }
                      onBlurFunction={() =>
                        setValidationsToShow({
                          ...validationsToShow,
                          confirmedPassword: true,
                        })
                      }
                      error={
                        validationsToShow.confirmedPassword &&
                        validationFunctions.confirmedPassword
                      }
                      onChange={(e: any) =>
                        setSignUpFields({
                          ...signUpFields,
                          confirmedPassword: e,
                        })
                      }
                      required
                      id="confirmed-password"
                      label="Confirm password"
                      type="password"
                      autoComplete="current-password"
                      variant="signUp"
                      helperText={
                        !validationsToShow.confirmedPassword
                          ? ''
                          : validationFunctions.confirmedPassword &&
                            confirmedPassword.length > 0
                          ? 'Passwords Do Not Match'
                          : ''
                      }
                      onCopy={(e: any) => {
                        e.preventDefault();
                        return false;
                      }}
                      onPaste={(e: any) => {
                        e.preventDefault();
                        return false;
                      }}
                    />
                  </div>

                  <div className="input-info-message">
                    <input
                      type="checkbox"
                      onChange={() => {
                        setCheckedTermsAndConditions(
                          !checkedTermsAndConditions,
                        );
                        setCheckedTermsAndConditionsClassname('');
                      }}
                      className="checkbox-design"
                    />

                    <p
                      className={checkedTermsAndConditionsClassname}
                      id="conditions-message"
                    >
                      I accept Payro's{' '}
                      <Link
                        to="/terms-and-conditions"
                        target="_blank"
                        className={'terms-design'}
                      >
                        <span className="underline">
                          Terms and Conditions
                        </span>
                      </Link>{' '}
                      and{' '}
                      <Link
                        to="/privacy-policy"
                        target="_blank"
                        className={'terms-design'}
                      >
                        <span className="underline">
                          Privacy Policy
                        </span>
                      </Link>
                    </p>
                  </div>
                </div>
                <div></div>
                <PayroButton
                  buttonSize="large"
                  isFormSubmit={allValid ? true : false}
                  centered
                  disabled={buttonDisabled}
                  onClick={() => validateOnClick()}
                  className={'accent-background-color'}
                  variant="green-enroll"
                >
                  Enroll now
                </PayroButton>
                <div className="free-to-enroll-wrapper ">
                  <p className="free-to-enroll-style">
                    Free to enroll{' '}
                    <span className="divider-style">|</span>
                    Takes under 5 minutes
                  </p>
                </div>
              </form>
            </div>
          </div>
        </HelmetProvider>
      );
  }
}
