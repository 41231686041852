/* tslint:disable */
/* eslint-disable */
/**
 * Payro API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AdvanceStandardForm
 */
export interface AdvanceStandardForm {
    /**
     * 
     * @type {string}
     * @memberof AdvanceStandardForm
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdvanceStandardForm
     */
    'account': string;
    /**
     * 
     * @type {number}
     * @memberof AdvanceStandardForm
     */
    'advance_amount': number;
    /**
     * 
     * @type {number}
     * @memberof AdvanceStandardForm
     */
    'expected_additional_markup_amount': number;
    /**
     * 
     * @type {number}
     * @memberof AdvanceStandardForm
     */
    'expected_total_payback_amount': number;
    /**
     * 
     * @type {number}
     * @memberof AdvanceStandardForm
     */
    'gross_paid_amount': number;
    /**
     * 
     * @type {number}
     * @memberof AdvanceStandardForm
     */
    'markup_paid_amount': number;
    /**
     * 
     * @type {string}
     * @memberof AdvanceStandardForm
     */
    'payment_due_date': string;
    /**
     * 
     * @type {number}
     * @memberof AdvanceStandardForm
     */
    'principal_paid_amount': number;
    /**
     * 
     * @type {number}
     * @memberof AdvanceStandardForm
     */
    'remaining_advance_amount': number;
    /**
     * 
     * @type {string}
     * @memberof AdvanceStandardForm
     */
    'stage': string;
    /**
     * 
     * @type {number}
     * @memberof AdvanceStandardForm
     */
    'total_entered_payments': number;
    /**
     * 
     * @type {string}
     * @memberof AdvanceStandardForm
     */
    'withdrawal_bank_account': string;
    /**
     * 
     * @type {string}
     * @memberof AdvanceStandardForm
     */
    'deposit_bank_account': string;
    /**
     * 
     * @type {number}
     * @memberof AdvanceStandardForm
     */
    'selected_num_of_weeks_in_portal': number;
    /**
     * 
     * @type {string}
     * @memberof AdvanceStandardForm
     */
    'PlanType': string;
    /**
     * 
     * @type {number}
     * @memberof AdvanceStandardForm
     */
    'NumberofWeeks': number;
    /**
     * 
     * @type {number}
     * @memberof AdvanceStandardForm
     */
    'FactorRate': number;
    /**
     * 
     * @type {number}
     * @memberof AdvanceStandardForm
     */
    'InterestPerTerm'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdvanceStandardForm
     */
    'PercentOfRevenue'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdvanceStandardForm
     */
    'TotalAmountPayback': number;
    /**
     * 
     * @type {number}
     * @memberof AdvanceStandardForm
     */
    'TotalAdvanceCost': number;
    /**
     * 
     * @type {number}
     * @memberof AdvanceStandardForm
     */
    'WeeklyPaymentAmount': number;
    /**
     * 
     * @type {string}
     * @memberof AdvanceStandardForm
     */
    'EstFinalPaymentDate': string;
    /**
     * 
     * @type {string}
     * @memberof AdvanceStandardForm
     */
    'FinalPaymentDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdvanceStandardForm
     */
    'ContractSigned': boolean;
}
/**
 * 
 * @export
 * @interface Announcement
 */
export interface Announcement {
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'body': string;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'start_date': string;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'show_through': string;
}
/**
 * 
 * @export
 * @interface BankAccount
 */
export interface BankAccount {
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    'uuid': string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    'finicity_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    'account_last_four'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    'bank_account_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    'bank_routing_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    'bank_account_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    'bank_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    'account_salesforce_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof BankAccount
     */
    'finicity_account_number_last_four'?: number;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    'plaid_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    'institution_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    'logo'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BankAccount
     */
    'is_real_bank_numbers'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BankAccount
     */
    'hide_on_portal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BankAccount
     */
    'ach_auth_signed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    'street'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    'zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    'country'?: string;
}
/**
 * 
 * @export
 * @interface CashAdvanceAgreement
 */
export interface CashAdvanceAgreement {
    /**
     * 
     * @type {number}
     * @memberof CashAdvanceAgreement
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CashAdvanceAgreement
     */
    'accountUuid': string;
    /**
     * 
     * @type {string}
     * @memberof CashAdvanceAgreement
     */
    'agreementId': string;
    /**
     * 
     * @type {string}
     * @memberof CashAdvanceAgreement
     */
    'agreementFileName': string;
    /**
     * 
     * @type {string}
     * @memberof CashAdvanceAgreement
     */
    'agreementFilePath': string;
}
/**
 * 
 * @export
 * @interface CheckForSalesforceAccountDTO
 */
export interface CheckForSalesforceAccountDTO {
    /**
     * 
     * @type {string}
     * @memberof CheckForSalesforceAccountDTO
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CheckForSalesforceAccountDTO
     */
    'phone': string;
}
/**
 * 
 * @export
 * @interface CommissionFileDTO
 */
export interface CommissionFileDTO {
    /**
     * 
     * @type {string}
     * @memberof CommissionFileDTO
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof CommissionFileDTO
     */
    'clientSecret': string;
    /**
     * 
     * @type {string}
     * @memberof CommissionFileDTO
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof CommissionFileDTO
     */
    'commissionId': string;
    /**
     * 
     * @type {string}
     * @memberof CommissionFileDTO
     */
    'isoName': string;
    /**
     * 
     * @type {string}
     * @memberof CommissionFileDTO
     */
    'month': string;
    /**
     * 
     * @type {string}
     * @memberof CommissionFileDTO
     */
    'year': string;
    /**
     * 
     * @type {string}
     * @memberof CommissionFileDTO
     */
    'userFirstName': string;
    /**
     * 
     * @type {string}
     * @memberof CommissionFileDTO
     */
    'toEmail'?: string;
}
/**
 * 
 * @export
 * @interface CreateAccountResponseDTO
 */
export interface CreateAccountResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateAccountResponseDTO
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface CreateAdvanceDto
 */
export interface CreateAdvanceDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAdvanceDto
     */
    'payment_due_date'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateAdvanceDto
     */
    'advance_amount'?: number;
}
/**
 * 
 * @export
 * @interface CreateBankAccountDto
 */
export interface CreateBankAccountDto {
    /**
     * 
     * @type {string}
     * @memberof CreateBankAccountDto
     */
    'uuid': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBankAccountDto
     */
    'account_last_four'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBankAccountDto
     */
    'bank_account_number': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBankAccountDto
     */
    'bank_routing_number': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBankAccountDto
     */
    'bank_account_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBankAccountDto
     */
    'bank__wire_routing_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBankAccountDto
     */
    'bank_name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBankAccountDto
     */
    'finicity_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBankAccountDto
     */
    'plaid_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateBankAccountDto
     */
    'finicity_account_number_last_four'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateBankAccountDto
     */
    'logo'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBankAccountDto
     */
    'institution_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBankAccountDto
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBankAccountDto
     */
    'street'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBankAccountDto
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBankAccountDto
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBankAccountDto
     */
    'zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBankAccountDto
     */
    'country'?: string;
}
/**
 * 
 * @export
 * @interface CreateCognitoUserForExistingSalesforceAccountByContactEmailDto
 */
export interface CreateCognitoUserForExistingSalesforceAccountByContactEmailDto {
    /**
     * 
     * @type {string}
     * @memberof CreateCognitoUserForExistingSalesforceAccountByContactEmailDto
     */
    'contactEmail': string;
}
/**
 * 
 * @export
 * @interface CreateContactDto
 */
export interface CreateContactDto {
    /**
     * 
     * @type {string}
     * @memberof CreateContactDto
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateContactDto
     */
    'accountid'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateContactDto
     */
    'annual_income'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateContactDto
     */
    'assistantname'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateContactDto
     */
    'assistantphone'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateContactDto
     */
    'birthdate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateContactDto
     */
    'drivers_license'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateContactDto
     */
    'email'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateContactDto
     */
    'is_applicant'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateContactDto
     */
    'mailing_address'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateContactDto
     */
    'mailing_address_city'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateContactDto
     */
    'mailing_address_state'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateContactDto
     */
    'mailing_address_zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateContactDto
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateContactDto
     */
    'last_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateContactDto
     */
    'percent_of_ownership'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateContactDto
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateContactDto
     */
    'social_security_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateContactDto
     */
    'title'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateContactDto
     */
    'sendMessage'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateContactDto
     */
    'sendEmail'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateDealDto
 */
export interface CreateDealDto {
    /**
     * 
     * @type {string}
     * @memberof CreateDealDto
     */
    'payment_due_date'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateDealDto
     */
    'funding_amount'?: number;
}
/**
 * 
 * @export
 * @interface CreateUserWithSalesforceDto
 */
export interface CreateUserWithSalesforceDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUserWithSalesforceDto
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserWithSalesforceDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserWithSalesforceDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserWithSalesforceDto
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserWithSalesforceDto
     */
    'isValid': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUserWithSalesforceDto
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserWithSalesforceDto
     */
    'accountUuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserWithSalesforceDto
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserWithSalesforceDto
     */
    'payrollCompany'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserWithSalesforceDto
     */
    'payrollAmount'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserWithSalesforceDto
     */
    'referralSource'?: string;
}
/**
 * 
 * @export
 * @interface DeleteCognitUseroDto
 */
export interface DeleteCognitUseroDto {
    /**
     * 
     * @type {string}
     * @memberof DeleteCognitUseroDto
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof DeleteCognitUseroDto
     */
    'clientSecret': string;
    /**
     * 
     * @type {string}
     * @memberof DeleteCognitUseroDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface DeleteFileDTO
 */
export interface DeleteFileDTO {
    /**
     * bank-statement or payroll-statement or underwriting-payroll-statement or additional-bank-statement
     * @type {string}
     * @memberof DeleteFileDTO
     */
    'documentType': string;
    /**
     * 
     * @type {string}
     * @memberof DeleteFileDTO
     */
    'fileName': string;
}
/**
 * 
 * @export
 * @interface DownloadFileDTO
 */
export interface DownloadFileDTO {
    /**
     * 
     * @type {string}
     * @memberof DownloadFileDTO
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof DownloadFileDTO
     */
    'clientSecret': string;
    /**
     * 
     * @type {string}
     * @memberof DownloadFileDTO
     */
    'fileName': string;
}
/**
 * 
 * @export
 * @interface DraftDeal
 */
export interface DraftDeal {
    /**
     * 
     * @type {string}
     * @memberof DraftDeal
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DraftDeal
     */
    'account': string;
    /**
     * 
     * @type {number}
     * @memberof DraftDeal
     */
    'funding_amount': number;
    /**
     * 
     * @type {number}
     * @memberof DraftDeal
     */
    'expected_total_payback_amount': number;
    /**
     * 
     * @type {string}
     * @memberof DraftDeal
     */
    'withdrawal_bank_account': string;
    /**
     * 
     * @type {string}
     * @memberof DraftDeal
     */
    'deposit_bank_account': string;
    /**
     * 
     * @type {number}
     * @memberof DraftDeal
     */
    'selected_num_of_weeks_in_portal': number;
    /**
     * 
     * @type {string}
     * @memberof DraftDeal
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof DraftDeal
     */
    'PlanType': string;
    /**
     * 
     * @type {number}
     * @memberof DraftDeal
     */
    'NumberofWeeks': number;
    /**
     * 
     * @type {number}
     * @memberof DraftDeal
     */
    'FactorRate': number;
    /**
     * 
     * @type {number}
     * @memberof DraftDeal
     */
    'InterestPerTerm'?: number;
    /**
     * 
     * @type {number}
     * @memberof DraftDeal
     */
    'PercentOfRevenue'?: number;
    /**
     * 
     * @type {number}
     * @memberof DraftDeal
     */
    'Revenue'?: number;
    /**
     * 
     * @type {number}
     * @memberof DraftDeal
     */
    'TotalAmountPayback': number;
    /**
     * 
     * @type {number}
     * @memberof DraftDeal
     */
    'TotalAdvanceCost': number;
    /**
     * 
     * @type {number}
     * @memberof DraftDeal
     */
    'WeeklyPaymentAmount': number;
    /**
     * 
     * @type {string}
     * @memberof DraftDeal
     */
    'EstFinalPaymentDate': string;
    /**
     * 
     * @type {string}
     * @memberof DraftDeal
     */
    'FinalPaymentDate': string;
    /**
     * 
     * @type {number}
     * @memberof DraftDeal
     */
    'actual_payroll_amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof DraftDeal
     */
    'payroll_due_date'?: string;
}
/**
 * 
 * @export
 * @interface EmailUpdateDto
 */
export interface EmailUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof EmailUpdateDto
     */
    'toEmail': string;
    /**
     * 
     * @type {string}
     * @memberof EmailUpdateDto
     */
    'clientSecret': string;
    /**
     * 
     * @type {string}
     * @memberof EmailUpdateDto
     */
    'clientId': string;
    /**
     * 
     * @type {number}
     * @memberof EmailUpdateDto
     */
    'approvedAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmailUpdateDto
     */
    'fundingAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmailUpdateDto
     */
    'declinedReason'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailUpdateDto
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailUpdateDto
     */
    'fundingDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailUpdateDto
     */
    'ISO_First_Name': string;
    /**
     * 
     * @type {string}
     * @memberof EmailUpdateDto
     */
    'Merchant': string;
    /**
     * 
     * @type {number}
     * @memberof EmailUpdateDto
     */
    'Amount': number;
    /**
     * 
     * @type {object}
     * @memberof EmailUpdateDto
     */
    'notificationType': object;
}
/**
 * 
 * @export
 * @interface ExchangeTokenRequest
 */
export interface ExchangeTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof ExchangeTokenRequest
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface FileUploadUrlDTO
 */
export interface FileUploadUrlDTO {
    /**
     * 
     * @type {string}
     * @memberof FileUploadUrlDTO
     */
    'documentType': string;
    /**
     * 
     * @type {string}
     * @memberof FileUploadUrlDTO
     */
    'fileName': string;
}
/**
 * 
 * @export
 * @interface FinchIntrospection
 */
export interface FinchIntrospection {
    /**
     * 
     * @type {string}
     * @memberof FinchIntrospection
     */
    'payroll_provider_id': string;
    /**
     * 
     * @type {string}
     * @memberof FinchIntrospection
     */
    'userame': string;
}
/**
 * 
 * @export
 * @interface GenerateDocsResponse
 */
export interface GenerateDocsResponse {
    /**
     * 
     * @type {string}
     * @memberof GenerateDocsResponse
     */
    'documentVersion': string;
    /**
     * 
     * @type {Array<object>}
     * @memberof GenerateDocsResponse
     */
    'contractInfo': Array<object>;
}
/**
 * 
 * @export
 * @interface GetAccountDto
 */
export interface GetAccountDto {
    /**
     * 
     * @type {string}
     * @memberof GetAccountDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAccountDto
     */
    'legal_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAccountDto
     */
    'federal_tax_id_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAccountDto
     */
    'legal_address'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAccountDto
     */
    'legal_address_city'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAccountDto
     */
    'legal_address_state'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAccountDto
     */
    'legal_address_zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAccountDto
     */
    'industry'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAccountDto
     */
    'website'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAccountDto
     */
    'annualrevenue'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAccountDto
     */
    'monthlyrevenue'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetAccountDto
     */
    'mca_account'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetAccountDto
     */
    'factor_rate_discount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetAccountDto
     */
    'completed_lendio_application'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetAccountDto
     */
    'years_in_business'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAccountDto
     */
    'credit_amount_available'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAccountDto
     */
    'approved_credit_amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAccountDto
     */
    'interest_override_amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAccountDto
     */
    'document_signature_hash'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAccountDto
     */
    'deposit_bank_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAccountDto
     */
    'withdrawal_bank_uuid'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAccountDto
     */
    'total_outstanding_amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAccountDto
     */
    'total_outstanding_pending_amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAccountDto
     */
    'payro_finance_status'?: GetAccountDtoPayroFinanceStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetAccountDto
     */
    'funding_status'?: GetAccountDtoFundingStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetAccountDto
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAccountDto
     */
    'payroll_company'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetAccountDto
     */
    'contract_signed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetAccountDto
     */
    'block_portal_access'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetAccountDto
     */
    'total_outstanding_principal'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetAccountDto
     */
    'opp_review_no_statements_checkbox'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetAccountDto
     */
    'opp_review_pending_decision_checkbox'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetAccountDto
     */
    'status_reason'?: string;
}

export const GetAccountDtoPayroFinanceStatusEnum = {
    New: 'New',
    Approved: 'Approved',
    OnHold: 'On Hold',
    Declined: 'Declined'
} as const;

export type GetAccountDtoPayroFinanceStatusEnum = typeof GetAccountDtoPayroFinanceStatusEnum[keyof typeof GetAccountDtoPayroFinanceStatusEnum];
export const GetAccountDtoFundingStatusEnum = {
    Yes: 'Yes',
    No: 'No'
} as const;

export type GetAccountDtoFundingStatusEnum = typeof GetAccountDtoFundingStatusEnum[keyof typeof GetAccountDtoFundingStatusEnum];

/**
 * 
 * @export
 * @interface GetAccountStatusDto
 */
export interface GetAccountStatusDto {
    /**
     * 
     * @type {string}
     * @memberof GetAccountStatusDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof GetAccountStatusDto
     */
    'credit_amount_available': number;
    /**
     * 
     * @type {number}
     * @memberof GetAccountStatusDto
     */
    'approved_credit_amount': number;
    /**
     * 
     * @type {number}
     * @memberof GetAccountStatusDto
     */
    'minimum_loan_amount': number;
    /**
     * 
     * @type {string}
     * @memberof GetAccountStatusDto
     */
    'finance_status': GetAccountStatusDtoFinanceStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetAccountStatusDto
     */
    'funding_status': GetAccountStatusDtoFundingStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof GetAccountStatusDto
     */
    'total_outstanding_principal': number;
}

export const GetAccountStatusDtoFinanceStatusEnum = {
    New: 'New',
    Approved: 'Approved',
    OnHold: 'On Hold',
    Declined: 'Declined'
} as const;

export type GetAccountStatusDtoFinanceStatusEnum = typeof GetAccountStatusDtoFinanceStatusEnum[keyof typeof GetAccountStatusDtoFinanceStatusEnum];
export const GetAccountStatusDtoFundingStatusEnum = {
    Yes: 'Yes',
    No: 'No'
} as const;

export type GetAccountStatusDtoFundingStatusEnum = typeof GetAccountStatusDtoFundingStatusEnum[keyof typeof GetAccountStatusDtoFundingStatusEnum];

/**
 * 
 * @export
 * @interface GetAgreementDto
 */
export interface GetAgreementDto {
    /**
     * 
     * @type {Array<CashAdvanceAgreement>}
     * @memberof GetAgreementDto
     */
    'agreements': Array<CashAdvanceAgreement>;
}
/**
 * 
 * @export
 * @interface GetAnnouncementDto
 */
export interface GetAnnouncementDto {
    /**
     * 
     * @type {Array<Announcement>}
     * @memberof GetAnnouncementDto
     */
    'announcements': Array<Announcement>;
}
/**
 * 
 * @export
 * @interface GetCreditScoreDto
 */
export interface GetCreditScoreDto {
    /**
     * 
     * @type {string}
     * @memberof GetCreditScoreDto
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetCreditScoreDto
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetCreditScoreDto
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof GetCreditScoreDto
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof GetCreditScoreDto
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof GetCreditScoreDto
     */
    'zip': string;
    /**
     * 
     * @type {string}
     * @memberof GetCreditScoreDto
     */
    'ssn'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCreditScoreDto
     */
    'date_of_birth'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCreditScoreDto
     */
    'contact_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCreditScoreDto
     */
    'account_uuid': string;
}
/**
 * 
 * @export
 * @interface GetDeals
 */
export interface GetDeals {
    /**
     * 
     * @type {Array<GetRepaymentDto>}
     * @memberof GetDeals
     */
    'loans': Array<GetRepaymentDto>;
    /**
     * 
     * @type {Array<object>}
     * @memberof GetDeals
     */
    'advances': Array<object>;
}
/**
 * 
 * @export
 * @interface GetFilesUrlForBrokerDto
 */
export interface GetFilesUrlForBrokerDto {
    /**
     * 
     * @type {string}
     * @memberof GetFilesUrlForBrokerDto
     */
    'account_id': string;
    /**
     * 
     * @type {string}
     * @memberof GetFilesUrlForBrokerDto
     */
    'file_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetFilesUrlForBrokerDto
     */
    'file_type': GetFilesUrlForBrokerDtoFileTypeEnum;
}

export const GetFilesUrlForBrokerDtoFileTypeEnum = {
    BankStatements: 'bank_statements',
    Contracts: 'contracts'
} as const;

export type GetFilesUrlForBrokerDtoFileTypeEnum = typeof GetFilesUrlForBrokerDtoFileTypeEnum[keyof typeof GetFilesUrlForBrokerDtoFileTypeEnum];

/**
 * 
 * @export
 * @interface GetFilesnamesDto
 */
export interface GetFilesnamesDto {
    /**
     * 
     * @type {string}
     * @memberof GetFilesnamesDto
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof GetFilesnamesDto
     */
    'clientSecret': string;
    /**
     * 
     * @type {string}
     * @memberof GetFilesnamesDto
     */
    'accountUuid': string;
}
/**
 * 
 * @export
 * @interface GetLendioPrequalDto
 */
export interface GetLendioPrequalDto {
    /**
     * 
     * @type {number}
     * @memberof GetLendioPrequalDto
     */
    'credit_score': number;
    /**
     * 
     * @type {string}
     * @memberof GetLendioPrequalDto
     */
    'business_start_date': string;
    /**
     * 
     * @type {number}
     * @memberof GetLendioPrequalDto
     */
    'average_monthly_sales': number;
    /**
     * 
     * @type {string}
     * @memberof GetLendioPrequalDto
     */
    'account_uuid': string;
}
/**
 * 
 * @export
 * @interface GetLoanPaymentDto
 */
export interface GetLoanPaymentDto {
    /**
     * 
     * @type {string}
     * @memberof GetLoanPaymentDto
     */
    'effective_date': string;
    /**
     * 
     * @type {string}
     * @memberof GetLoanPaymentDto
     */
    'expected_settle_date': string;
    /**
     * 
     * @type {string}
     * @memberof GetLoanPaymentDto
     */
    'loan_id': string;
    /**
     * 
     * @type {number}
     * @memberof GetLoanPaymentDto
     */
    'original_funding_amount': number;
    /**
     * 
     * @type {number}
     * @memberof GetLoanPaymentDto
     */
    'running_balance': number;
    /**
     * 
     * @type {string}
     * @memberof GetLoanPaymentDto
     */
    'settlement_date': string;
    /**
     * 
     * @type {string}
     * @memberof GetLoanPaymentDto
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof GetLoanPaymentDto
     */
    'sub_type': string;
    /**
     * 
     * @type {number}
     * @memberof GetLoanPaymentDto
     */
    'transaction_amount': number;
}
/**
 * 
 * @export
 * @interface GetLoanResponseBrokerVersion
 */
export interface GetLoanResponseBrokerVersion {
    /**
     * 
     * @type {string}
     * @memberof GetLoanResponseBrokerVersion
     */
    'funded_date': string;
    /**
     * 
     * @type {number}
     * @memberof GetLoanResponseBrokerVersion
     */
    'funding_amount': number;
    /**
     * 
     * @type {string}
     * @memberof GetLoanResponseBrokerVersion
     */
    'uuid': string;
    /**
     * 
     * @type {string}
     * @memberof GetLoanResponseBrokerVersion
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof GetLoanResponseBrokerVersion
     */
    'total_outstanding_amount': number;
    /**
     * 
     * @type {number}
     * @memberof GetLoanResponseBrokerVersion
     */
    'total_outstanding_interest': number;
    /**
     * 
     * @type {number}
     * @memberof GetLoanResponseBrokerVersion
     */
    'total_outstanding_principal': number;
    /**
     * 
     * @type {string}
     * @memberof GetLoanResponseBrokerVersion
     */
    'payback_date': string;
    /**
     * 
     * @type {number}
     * @memberof GetLoanResponseBrokerVersion
     */
    'fee_balance': number;
    /**
     * 
     * @type {string}
     * @memberof GetLoanResponseBrokerVersion
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface GetOcrolusStatementDTO
 */
export interface GetOcrolusStatementDTO {
    /**
     * 
     * @type {string}
     * @memberof GetOcrolusStatementDTO
     */
    'accountUuid': string;
    /**
     * 
     * @type {string}
     * @memberof GetOcrolusStatementDTO
     */
    'opportunityId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOcrolusStatementDTO
     */
    'accountName': string;
    /**
     * 
     * @type {string}
     * @memberof GetOcrolusStatementDTO
     */
    'pdfFile'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOcrolusStatementDTO
     */
    'fileName'?: string;
}
/**
 * 
 * @export
 * @interface GetRepaymentDto
 */
export interface GetRepaymentDto {
    /**
     * 
     * @type {number}
     * @memberof GetRepaymentDto
     */
    'bank_account_number': number;
    /**
     * 
     * @type {string}
     * @memberof GetRepaymentDto
     */
    'withdrawl_bank_name': string;
    /**
     * 
     * @type {number}
     * @memberof GetRepaymentDto
     */
    'expected_total_payback': number;
    /**
     * 
     * @type {object}
     * @memberof GetRepaymentDto
     */
    'full_remaining_balance_incl_interest': object;
    /**
     * 
     * @type {string}
     * @memberof GetRepaymentDto
     */
    'funded_date': string;
    /**
     * 
     * @type {number}
     * @memberof GetRepaymentDto
     */
    'funding_amount': number;
    /**
     * 
     * @type {string}
     * @memberof GetRepaymentDto
     */
    'next_hit_date': string;
    /**
     * 
     * @type {number}
     * @memberof GetRepaymentDto
     */
    'next_interest_amount': number;
    /**
     * 
     * @type {string}
     * @memberof GetRepaymentDto
     */
    'next_interest_date': string;
    /**
     * 
     * @type {string}
     * @memberof GetRepaymentDto
     */
    'payoff': string;
    /**
     * 
     * @type {string}
     * @memberof GetRepaymentDto
     */
    'payroll_record': string;
    /**
     * 
     * @type {object}
     * @memberof GetRepaymentDto
     */
    'payroll_record__r': object;
    /**
     * 
     * @type {string}
     * @memberof GetRepaymentDto
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof GetRepaymentDto
     */
    'salesforce_id': string;
    /**
     * 
     * @type {number}
     * @memberof GetRepaymentDto
     */
    'total_amount_settled': number;
    /**
     * 
     * @type {number}
     * @memberof GetRepaymentDto
     */
    'total_outstanding_amount': number;
    /**
     * 
     * @type {number}
     * @memberof GetRepaymentDto
     */
    'total_outstanding_interest': number;
    /**
     * 
     * @type {number}
     * @memberof GetRepaymentDto
     */
    'total_outstanding_principal': number;
    /**
     * 
     * @type {string}
     * @memberof GetRepaymentDto
     */
    'number_of_pay_periods': string;
    /**
     * 
     * @type {number}
     * @memberof GetRepaymentDto
     */
    'total_credits_settled': number;
    /**
     * 
     * @type {string}
     * @memberof GetRepaymentDto
     */
    'merchant_payroll_company': string;
    /**
     * 
     * @type {string}
     * @memberof GetRepaymentDto
     */
    'uuid': string;
    /**
     * 
     * @type {string}
     * @memberof GetRepaymentDto
     */
    'FinalPaymentDate': string;
    /**
     * 
     * @type {string}
     * @memberof GetRepaymentDto
     */
    'PlanType': string;
    /**
     * 
     * @type {number}
     * @memberof GetRepaymentDto
     */
    'NumberofWeeks': number;
    /**
     * 
     * @type {number}
     * @memberof GetRepaymentDto
     */
    'FactorRate': number;
    /**
     * 
     * @type {number}
     * @memberof GetRepaymentDto
     */
    'InterestPerTerm': number;
    /**
     * 
     * @type {number}
     * @memberof GetRepaymentDto
     */
    'TotalAmountPayback': number;
    /**
     * 
     * @type {number}
     * @memberof GetRepaymentDto
     */
    'TotalAdvanceCost': number;
    /**
     * 
     * @type {number}
     * @memberof GetRepaymentDto
     */
    'WeeklyPaymentAmount': number;
    /**
     * 
     * @type {string}
     * @memberof GetRepaymentDto
     */
    'EstFinalPaymentDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetRepaymentDto
     */
    'Contract_Signed': boolean;
    /**
     * 
     * @type {number}
     * @memberof GetRepaymentDto
     */
    'pending_amount': number;
    /**
     * 
     * @type {string}
     * @memberof GetRepaymentDto
     */
    'repayment_name': string;
    /**
     * 
     * @type {number}
     * @memberof GetRepaymentDto
     */
    'actual_payroll_amount': number;
    /**
     * 
     * @type {string}
     * @memberof GetRepaymentDto
     */
    'payroll_due_date': string;
    /**
     * 
     * @type {string}
     * @memberof GetRepaymentDto
     */
    'recordtypeid': string;
}
/**
 * 
 * @export
 * @interface LoanPayment
 */
export interface LoanPayment {
    /**
     * 
     * @type {string}
     * @memberof LoanPayment
     */
    'repayment': string;
    /**
     * 
     * @type {string}
     * @memberof LoanPayment
     */
    'repayment_r.uuid__c': string;
    /**
     * 
     * @type {string}
     * @memberof LoanPayment
     */
    'repayment_account_name': string;
    /**
     * 
     * @type {string}
     * @memberof LoanPayment
     */
    'bank_name_from_repayment': string;
    /**
     * 
     * @type {string}
     * @memberof LoanPayment
     */
    'bank_account_from_repayment': string;
    /**
     * 
     * @type {string}
     * @memberof LoanPayment
     */
    'repayment_bank_account_number': string;
    /**
     * 
     * @type {string}
     * @memberof LoanPayment
     */
    'repayment_bank_name': string;
    /**
     * 
     * @type {number}
     * @memberof LoanPayment
     */
    'repayment_funding_amount': number;
    /**
     * 
     * @type {number}
     * @memberof LoanPayment
     */
    'running_balance': number;
    /**
     * 
     * @type {number}
     * @memberof LoanPayment
     */
    'running_fee_balance': number;
    /**
     * 
     * @type {number}
     * @memberof LoanPayment
     */
    'running_principal': number;
    /**
     * 
     * @type {string}
     * @memberof LoanPayment
     */
    'settlement_date': string;
    /**
     * 
     * @type {string}
     * @memberof LoanPayment
     */
    'effective_date': string;
    /**
     * 
     * @type {string}
     * @memberof LoanPayment
     */
    'expected_settle_date': string;
    /**
     * 
     * @type {string}
     * @memberof LoanPayment
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof LoanPayment
     */
    'status_amount': string;
    /**
     * 
     * @type {string}
     * @memberof LoanPayment
     */
    'sub_type': string;
    /**
     * 
     * @type {number}
     * @memberof LoanPayment
     */
    'transaction_amount': number;
    /**
     * 
     * @type {string}
     * @memberof LoanPayment
     */
    'transaction_type': string;
    /**
     * 
     * @type {string}
     * @memberof LoanPayment
     */
    'uuid': string;
}
/**
 * 
 * @export
 * @interface NewAccountFromPayrollCompany
 */
export interface NewAccountFromPayrollCompany {
    /**
     * 
     * @type {string}
     * @memberof NewAccountFromPayrollCompany
     */
    'external_id': string;
    /**
     * 
     * @type {NewAccountFromPayrollCompanyAccountInfo}
     * @memberof NewAccountFromPayrollCompany
     */
    'account_info': NewAccountFromPayrollCompanyAccountInfo;
    /**
     * 
     * @type {NewAccountFromPayrollCompanyContactInfo}
     * @memberof NewAccountFromPayrollCompany
     */
    'contact_info': NewAccountFromPayrollCompanyContactInfo;
    /**
     * 
     * @type {PayrollOverview}
     * @memberof NewAccountFromPayrollCompany
     */
    'payroll_info'?: PayrollOverview;
}
/**
 * 
 * @export
 * @interface NewAccountFromPayrollCompanyAccountInfo
 */
export interface NewAccountFromPayrollCompanyAccountInfo {
    /**
     * must be in format xx-xxxxxxx for example 12-3456789
     * @type {string}
     * @memberof NewAccountFromPayrollCompanyAccountInfo
     */
    'federal_tax_id_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAccountFromPayrollCompanyAccountInfo
     */
    'legal_name': string;
    /**
     * 
     * @type {string}
     * @memberof NewAccountFromPayrollCompanyAccountInfo
     */
    'legal_address'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAccountFromPayrollCompanyAccountInfo
     */
    'legal_address_city'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAccountFromPayrollCompanyAccountInfo
     */
    'legal_address_state'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAccountFromPayrollCompanyAccountInfo
     */
    'legal_address_zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAccountFromPayrollCompanyAccountInfo
     */
    'industry'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAccountFromPayrollCompanyAccountInfo
     */
    'website'?: string;
}
/**
 * 
 * @export
 * @interface NewAccountFromPayrollCompanyContactInfo
 */
export interface NewAccountFromPayrollCompanyContactInfo {
    /**
     * 
     * @type {string}
     * @memberof NewAccountFromPayrollCompanyContactInfo
     */
    'mobile_phone': string;
    /**
     * 
     * @type {string}
     * @memberof NewAccountFromPayrollCompanyContactInfo
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof NewAccountFromPayrollCompanyContactInfo
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof NewAccountFromPayrollCompanyContactInfo
     */
    'last_name': string;
}
/**
 * 
 * @export
 * @interface NewBrokerLoanDto
 */
export interface NewBrokerLoanDto {
    /**
     * 
     * @type {string}
     * @memberof NewBrokerLoanDto
     */
    'payback_date': string;
    /**
     * 
     * @type {number}
     * @memberof NewBrokerLoanDto
     */
    'funding_amount': number;
    /**
     * 
     * @type {string}
     * @memberof NewBrokerLoanDto
     */
    'account_external_id': string;
}
/**
 * 
 * @export
 * @interface NewBrokerLoanPaymentDto
 */
export interface NewBrokerLoanPaymentDto {
    /**
     * 
     * @type {number}
     * @memberof NewBrokerLoanPaymentDto
     */
    'payment_amount': number;
    /**
     * 
     * @type {string}
     * @memberof NewBrokerLoanPaymentDto
     */
    'loan_id': string;
    /**
     * 
     * @type {string}
     * @memberof NewBrokerLoanPaymentDto
     */
    'account_id': string;
}
/**
 * 
 * @export
 * @interface NewLoanDebitPayment
 */
export interface NewLoanDebitPayment {
    /**
     * 
     * @type {number}
     * @memberof NewLoanDebitPayment
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof NewLoanDebitPayment
     */
    'effectiveDate': string;
    /**
     * 
     * @type {string}
     * @memberof NewLoanDebitPayment
     */
    'repaymentId': string;
}
/**
 * 
 * @export
 * @interface NextPayroll
 */
export interface NextPayroll {
    /**
     * 
     * @type {string}
     * @memberof NextPayroll
     */
    'payroll_date': string;
    /**
     * 
     * @type {number}
     * @memberof NextPayroll
     */
    'payroll_amount': number;
    /**
     * 
     * @type {string}
     * @memberof NextPayroll
     */
    'number_of_days_untill_payroll': string;
}
/**
 * 
 * @export
 * @interface NextScheduledPayment
 */
export interface NextScheduledPayment {
    /**
     * 
     * @type {string}
     * @memberof NextScheduledPayment
     */
    'bank_account_number': string;
    /**
     * 
     * @type {string}
     * @memberof NextScheduledPayment
     */
    'bank_account_name': string;
    /**
     * 
     * @type {string}
     * @memberof NextScheduledPayment
     */
    'payback_date': string;
    /**
     * 
     * @type {number}
     * @memberof NextScheduledPayment
     */
    'transaction_amount': number;
}
/**
 * 
 * @export
 * @interface OcrolusOpportunityDto
 */
export interface OcrolusOpportunityDto {
    /**
     * 
     * @type {string}
     * @memberof OcrolusOpportunityDto
     */
    'account_id': string;
    /**
     * 
     * @type {string}
     * @memberof OcrolusOpportunityDto
     */
    'opportunity_id'?: string;
}
/**
 * 
 * @export
 * @interface Opportunity
 */
export interface Opportunity {
    /**
     * 
     * @type {string}
     * @memberof Opportunity
     */
    'uuid': string;
    /**
     * 
     * @type {string}
     * @memberof Opportunity
     */
    'account_uuid': string;
    /**
     * 
     * @type {string}
     * @memberof Opportunity
     */
    'average_payroll_amount': string;
    /**
     * 
     * @type {string}
     * @memberof Opportunity
     */
    'average_monthly_sales_range'?: string;
    /**
     * 
     * @type {string}
     * @memberof Opportunity
     */
    'number_of_employees_range': string;
    /**
     * 
     * @type {string}
     * @memberof Opportunity
     */
    'how_often_do_you_run_payroll': string;
    /**
     * 
     * @type {string}
     * @memberof Opportunity
     */
    'stage': OpportunityStageEnum;
    /**
     * 
     * @type {object}
     * @memberof Opportunity
     */
    'created_date'?: object;
    /**
     * 
     * @type {string}
     * @memberof Opportunity
     */
    'is_borrower_qualified'?: string;
    /**
     * 
     * @type {number}
     * @memberof Opportunity
     */
    'lendio_max_approval'?: number;
    /**
     * 
     * @type {number}
     * @memberof Opportunity
     */
    'lendio_max_factor_rate'?: number;
    /**
     * 
     * @type {number}
     * @memberof Opportunity
     */
    'lendio_max_term'?: number;
    /**
     * 
     * @type {number}
     * @memberof Opportunity
     */
    'lendio_min_approval'?: number;
    /**
     * 
     * @type {number}
     * @memberof Opportunity
     */
    'lendio_min_factor_rate'?: number;
    /**
     * 
     * @type {number}
     * @memberof Opportunity
     */
    'lendio_min_term'?: number;
}

export const OpportunityStageEnum = {
    PendingDecision: 'Pending Decision',
    Approved: 'Approved',
    Declined: 'Declined'
} as const;

export type OpportunityStageEnum = typeof OpportunityStageEnum[keyof typeof OpportunityStageEnum];

/**
 * 
 * @export
 * @interface PaybackDate
 */
export interface PaybackDate {
    /**
     * 
     * @type {string}
     * @memberof PaybackDate
     */
    'payback_date': string;
    /**
     * 
     * @type {boolean}
     * @memberof PaybackDate
     */
    'available': boolean;
    /**
     * 
     * @type {string}
     * @memberof PaybackDate
     */
    'unavailable_reason'?: string;
}
/**
 * 
 * @export
 * @interface PaymentDate
 */
export interface PaymentDate {
    /**
     * 
     * @type {string}
     * @memberof PaymentDate
     */
    'payment_date': string;
}
/**
 * 
 * @export
 * @interface PayrollConnectedStatus
 */
export interface PayrollConnectedStatus {
    /**
     * 
     * @type {boolean}
     * @memberof PayrollConnectedStatus
     */
    'connected': boolean;
}
/**
 * 
 * @export
 * @interface PayrollInfo
 */
export interface PayrollInfo {
    /**
     * 
     * @type {FinchIntrospection}
     * @memberof PayrollInfo
     */
    'introspection': FinchIntrospection;
    /**
     * 
     * @type {object}
     * @memberof PayrollInfo
     */
    'payment': object;
}
/**
 * 
 * @export
 * @interface PayrollOverview
 */
export interface PayrollOverview {
    /**
     * 
     * @type {number}
     * @memberof PayrollOverview
     */
    'average_payroll_amount': number;
    /**
     * 
     * @type {number}
     * @memberof PayrollOverview
     */
    'average_monthly_sales_range': number;
    /**
     * 
     * @type {number}
     * @memberof PayrollOverview
     */
    'number_of_employees': number;
    /**
     * 
     * @type {string}
     * @memberof PayrollOverview
     */
    'how_often_do_you_run_payroll': PayrollOverviewHowOftenDoYouRunPayrollEnum;
}

export const PayrollOverviewHowOftenDoYouRunPayrollEnum = {
    Weekly: 'Weekly',
    BiWeekly: 'Bi Weekly',
    Monthly: 'Monthly',
    BiMonthly: 'Bi Monthly'
} as const;

export type PayrollOverviewHowOftenDoYouRunPayrollEnum = typeof PayrollOverviewHowOftenDoYouRunPayrollEnum[keyof typeof PayrollOverviewHowOftenDoYouRunPayrollEnum];

/**
 * 
 * @export
 * @interface PdfFileDTO
 */
export interface PdfFileDTO {
    /**
     * 
     * @type {string}
     * @memberof PdfFileDTO
     */
    'clientId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PdfFileDTO
     */
    'clientSecret'?: string;
    /**
     * 
     * @type {string}
     * @memberof PdfFileDTO
     */
    'repaymentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PdfFileDTO
     */
    'accountId'?: string;
}
/**
 * 
 * @export
 * @interface SalesforceLog
 */
export interface SalesforceLog {
    /**
     * 
     * @type {string}
     * @memberof SalesforceLog
     */
    'dataToLog': string;
    /**
     * 
     * @type {object}
     * @memberof SalesforceLog
     */
    'level'?: object;
}
/**
 * 
 * @export
 * @interface SelectBankAccountDto
 */
export interface SelectBankAccountDto {
    /**
     * 
     * @type {string}
     * @memberof SelectBankAccountDto
     */
    'uuid': string;
}
/**
 * 
 * @export
 * @interface SetAccessTokenDTO
 */
export interface SetAccessTokenDTO {
    /**
     * 
     * @type {string}
     * @memberof SetAccessTokenDTO
     */
    'access_token': string;
}
/**
 * 
 * @export
 * @interface SignDocumentsDTO
 */
export interface SignDocumentsDTO {
    /**
     * 
     * @type {string}
     * @memberof SignDocumentsDTO
     */
    'signature': string;
    /**
     * 
     * @type {string}
     * @memberof SignDocumentsDTO
     */
    'documentVersion': string;
    /**
     * 
     * @type {boolean}
     * @memberof SignDocumentsDTO
     */
    'isCashAdvance'?: boolean;
}
/**
 * 
 * @export
 * @interface TransactionAvailability
 */
export interface TransactionAvailability {
    /**
     * 
     * @type {string}
     * @memberof TransactionAvailability
     */
    'fundingDate': string;
    /**
     * 
     * @type {Array<PaybackDate>}
     * @memberof TransactionAvailability
     */
    'paybackDates': Array<PaybackDate>;
    /**
     * 
     * @type {object}
     * @memberof TransactionAvailability
     */
    'bankHolidays': object;
    /**
     * 
     * @type {object}
     * @memberof TransactionAvailability
     */
    'StandardFunding'?: object;
    /**
     * 
     * @type {object}
     * @memberof TransactionAvailability
     */
    'AcceleratedFunding'?: object;
}
/**
 * 
 * @export
 * @interface UpdateAccountDto
 */
export interface UpdateAccountDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountDto
     */
    'legal_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountDto
     */
    'federal_tax_id_no'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountDto
     */
    'legal_address'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountDto
     */
    'legal_address_city'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountDto
     */
    'legal_address_state'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountDto
     */
    'legal_address_zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountDto
     */
    'industry'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountDto
     */
    'website'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountDto
     */
    'annualrevenue'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountDto
     */
    'monthlyrevenue'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountDto
     */
    'years_in_business'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountDto
     */
    'default_bank_account_uuid_for_deposit'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountDto
     */
    'default_bank_account_uuid_for_withdrawal'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountDto
     */
    'main_contact'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountDto
     */
    'payroll_company'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAccountDto
     */
    'connected_to_plaid'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAccountDto
     */
    'opp_review_no_statements_checkbox'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAccountDto
     */
    'opp_review_pending_decision_checkbox'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAccountDto
     */
    'completed_lendio_application'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateAccountDto
     */
    'otp'?: number;
}
/**
 * 
 * @export
 * @interface UpdateAdvanceDto
 */
export interface UpdateAdvanceDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAdvanceDto
     */
    'deposit_bank_account'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdvanceDto
     */
    'withdrawal_bank_account'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateAdvanceDto
     */
    'selected_num_of_weeks_in_portal'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAdvanceDto
     */
    'advance_amount'?: number;
}
/**
 * 
 * @export
 * @interface UpdateBankAccountDto
 */
export interface UpdateBankAccountDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateBankAccountDto
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBankAccountDto
     */
    'finicity_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBankAccountDto
     */
    'account_last_four'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBankAccountDto
     */
    'bank_account_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBankAccountDto
     */
    'bank_routing_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBankAccountDto
     */
    'bank_account_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBankAccountDto
     */
    'bank_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBankAccountDto
     */
    'account_salesforce_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateBankAccountDto
     */
    'finicity_account_number_last_four'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateBankAccountDto
     */
    'plaid_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBankAccountDto
     */
    'institution_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBankAccountDto
     */
    'logo'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateBankAccountDto
     */
    'is_real_bank_numbers'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateBankAccountDto
     */
    'hide_on_portal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateBankAccountDto
     */
    'ach_auth_signed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateBankAccountDto
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBankAccountDto
     */
    'street'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBankAccountDto
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBankAccountDto
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBankAccountDto
     */
    'zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBankAccountDto
     */
    'country'?: string;
}
/**
 * 
 * @export
 * @interface UpdateContactDto
 */
export interface UpdateContactDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateContactDto
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateContactDto
     */
    'accountid'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateContactDto
     */
    'annual_income'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateContactDto
     */
    'assistantname'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateContactDto
     */
    'assistantphone'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateContactDto
     */
    'birthdate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateContactDto
     */
    'drivers_license'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateContactDto
     */
    'email'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateContactDto
     */
    'is_applicant'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateContactDto
     */
    'mailing_address'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateContactDto
     */
    'mailing_address_city'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateContactDto
     */
    'mailing_address_state'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateContactDto
     */
    'mailing_address_zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateContactDto
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateContactDto
     */
    'last_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateContactDto
     */
    'percent_of_ownership'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateContactDto
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateContactDto
     */
    'social_security_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateContactDto
     */
    'title'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateContactDto
     */
    'sendMessage'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateContactDto
     */
    'sendEmail'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateDealDto
 */
export interface UpdateDealDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateDealDto
     */
    'deposit_bank_account'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDealDto
     */
    'withdrawal_bank_account'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateDealDto
     */
    'selected_num_of_weeks_in_portal'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDealDto
     */
    'funding_amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateDealDto
     */
    'FinalPaymentDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDealDto
     */
    'PlanType'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateDealDto
     */
    'NumberofWeeks'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDealDto
     */
    'NumberofWeeksLeft'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDealDto
     */
    'FactorRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDealDto
     */
    'InterestPerTerm'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDealDto
     */
    'TotalAmountPayback'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDealDto
     */
    'TotalAdvanceCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDealDto
     */
    'WeeklyPaymentAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateDealDto
     */
    'EstFinalPaymentDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDealDto
     */
    'isCashAdvance'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateDealDto
     */
    'actual_payroll_amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateDealDto
     */
    'payroll_due_date'?: string;
}
/**
 * 
 * @export
 * @interface UpdateOpportunityDto
 */
export interface UpdateOpportunityDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateOpportunityDto
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOpportunityDto
     */
    'account_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOpportunityDto
     */
    'average_payroll_amount'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOpportunityDto
     */
    'average_monthly_sales_range'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOpportunityDto
     */
    'number_of_employees_range'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOpportunityDto
     */
    'how_often_do_you_run_payroll'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOpportunityDto
     */
    'is_borrower_qualified'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateOpportunityDto
     */
    'lendio_max_approval'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateOpportunityDto
     */
    'lendio_max_factor_rate'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateOpportunityDto
     */
    'lendio_max_term'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateOpportunityDto
     */
    'lendio_min_approval'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateOpportunityDto
     */
    'lendio_min_factor_rate'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateOpportunityDto
     */
    'lendio_min_term'?: number;
}
/**
 * 
 * @export
 * @interface UpdateUserInfoDto
 */
export interface UpdateUserInfoDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserInfoDto
     */
    'current_screen'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserInfoDto
     */
    'application_stage'?: string;
}
/**
 * 
 * @export
 * @interface UpsertAllContactInfo
 */
export interface UpsertAllContactInfo {
    /**
     * 
     * @type {Array<UpdateContactDto>}
     * @memberof UpsertAllContactInfo
     */
    'contacts': Array<UpdateContactDto>;
}
/**
 * 
 * @export
 * @interface UserNotification
 */
export interface UserNotification {
    /**
     * 
     * @type {string}
     * @memberof UserNotification
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof UserNotification
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserNotification
     */
    'referenceId': string;
}
/**
 * 
 * @export
 * @interface UserObject
 */
export interface UserObject {
    /**
     * 
     * @type {string}
     * @memberof UserObject
     */
    'cognito_username': string;
    /**
     * 
     * @type {string}
     * @memberof UserObject
     */
    'created_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserObject
     */
    'account_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserObject
     */
    'account_salesforce_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserObject
     */
    'contact_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserObject
     */
    'current_screen'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserObject
     */
    'application_stage'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserObject
     */
    'furthest_screen'?: string;
    /**
     * 
     * @type {object}
     * @memberof UserObject
     */
    'contract_type'?: object;
    /**
     * 
     * @type {string}
     * @memberof UserObject
     */
    'document_signature_hash'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserObject
     */
    'take_to_plaid'?: boolean;
}

/**
 * BrokersApi - axios parameter creator
 * @export
 */
export const BrokersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This is to apply for a line of credit, ie before a customer has been approved for funding.       <br>This API is not for requesting funding for a specific loan.     <br>To request funding, use the /brokers/new-loan API
         * @summary Create a new application for a line-of-credit
         * @param {NewAccountFromPayrollCompany} newAccountFromPayrollCompany 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokersControllerCreateNewApplication: async (newAccountFromPayrollCompany: NewAccountFromPayrollCompany, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newAccountFromPayrollCompany' is not null or undefined
            assertParamExists('brokersControllerCreateNewApplication', 'newAccountFromPayrollCompany', newAccountFromPayrollCompany)
            const localVarPath = `/brokers/new-application`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newAccountFromPayrollCompany, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * See sample response and make sure you see the possible values in the schema.     <br> The <b>finance_status</b> field is essentially whether the account is approved or not from an underwriting perspective.     <br> The <b>funding_status</b> field is whether they are totally able to be funded.  This means that they are approved, and that we signed contracts and banking info.     <br> <u>Please note, we will not be able to fund any account unless the <b>funding_status</b> field has a value of <b>Yes</b></u>.     <br> For getting the specific loans, use the API /brokers/loans-for-account/{account_external_id}     <br> For getting payment details about a specific loan use /brokers/payments-for-loan/{account_external_id}/{loan_id}     
         * @summary Get high level info have the account
         * @param {string} externalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokersControllerGetAccountStatus: async (externalId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalId' is not null or undefined
            assertParamExists('brokersControllerGetAccountStatus', 'externalId', externalId)
            const localVarPath = `/brokers/account-status/{external_id}`
                .replace(`{${"external_id"}}`, encodeURIComponent(String(externalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all the payments made for a specific loan
         * @param {string} accountExternalId 
         * @param {string} loanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokersControllerGetLoanPayments: async (accountExternalId: string, loanId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountExternalId' is not null or undefined
            assertParamExists('brokersControllerGetLoanPayments', 'accountExternalId', accountExternalId)
            // verify required parameter 'loanId' is not null or undefined
            assertParamExists('brokersControllerGetLoanPayments', 'loanId', loanId)
            const localVarPath = `/brokers/payments-for-loan/{account_external_id}/{loan_id}`
                .replace(`{${"account_external_id"}}`, encodeURIComponent(String(accountExternalId)))
                .replace(`{${"loan_id"}}`, encodeURIComponent(String(loanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This gets all the high level details about the loan, including summary of payments in different statuses.      <br>This does not include though, the specific loan payment details.      <br>For all the payments for a specific loan, use /brokers/payments-for-loan/{account_external_id}/{loan_id}
         * @summary Get all the loans for an account
         * @param {string} accountExternalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokersControllerGetLoansForAccount: async (accountExternalId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountExternalId' is not null or undefined
            assertParamExists('brokersControllerGetLoansForAccount', 'accountExternalId', accountExternalId)
            const localVarPath = `/brokers/loans-for-account/{account_external_id}`
                .replace(`{${"account_external_id"}}`, encodeURIComponent(String(accountExternalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *      Make sure you find out the next available funding date and possible payback dates are by using /deals/transaction-availablility     Note the payback date has to be in ISO for, for example, 2023-01-20
         * @summary Create a new loan
         * @param {NewBrokerLoanDto} newBrokerLoanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokersControllerNewLoan: async (newBrokerLoanDto: NewBrokerLoanDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newBrokerLoanDto' is not null or undefined
            assertParamExists('brokersControllerNewLoan', 'newBrokerLoanDto', newBrokerLoanDto)
            const localVarPath = `/brokers/new-loan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newBrokerLoanDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This is only necessary when scheduling a payment to be effective before the payback date.     <br> . By default when using the new loan api, a payment in full will be deducted on the payback date.     <br>  Before scheduling a payment, be sure to use the /payments/payment-availablility API to determine next available date we can process the payment.     
         * @summary PLACEHOLDER FOR FUTURE IDEA - Create a new payment for an exisitng loan
         * @param {NewBrokerLoanPaymentDto} newBrokerLoanPaymentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokersControllerNewLoanPayment: async (newBrokerLoanPaymentDto: NewBrokerLoanPaymentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newBrokerLoanPaymentDto' is not null or undefined
            assertParamExists('brokersControllerNewLoanPayment', 'newBrokerLoanPaymentDto', newBrokerLoanPaymentDto)
            const localVarPath = `/brokers/new-loan-payment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newBrokerLoanPaymentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * See possible values in the schema.
         * @summary Get a presigned url to upload either bank_statements or contracts to our s3
         * @param {GetFilesUrlForBrokerDto} getFilesUrlForBrokerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokersControllerUploadDocs: async (getFilesUrlForBrokerDto: GetFilesUrlForBrokerDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getFilesUrlForBrokerDto' is not null or undefined
            assertParamExists('brokersControllerUploadDocs', 'getFilesUrlForBrokerDto', getFilesUrlForBrokerDto)
            const localVarPath = `/brokers/get-doc-upload-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getFilesUrlForBrokerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BrokersApi - functional programming interface
 * @export
 */
export const BrokersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BrokersApiAxiosParamCreator(configuration)
    return {
        /**
         * This is to apply for a line of credit, ie before a customer has been approved for funding.       <br>This API is not for requesting funding for a specific loan.     <br>To request funding, use the /brokers/new-loan API
         * @summary Create a new application for a line-of-credit
         * @param {NewAccountFromPayrollCompany} newAccountFromPayrollCompany 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brokersControllerCreateNewApplication(newAccountFromPayrollCompany: NewAccountFromPayrollCompany, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brokersControllerCreateNewApplication(newAccountFromPayrollCompany, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * See sample response and make sure you see the possible values in the schema.     <br> The <b>finance_status</b> field is essentially whether the account is approved or not from an underwriting perspective.     <br> The <b>funding_status</b> field is whether they are totally able to be funded.  This means that they are approved, and that we signed contracts and banking info.     <br> <u>Please note, we will not be able to fund any account unless the <b>funding_status</b> field has a value of <b>Yes</b></u>.     <br> For getting the specific loans, use the API /brokers/loans-for-account/{account_external_id}     <br> For getting payment details about a specific loan use /brokers/payments-for-loan/{account_external_id}/{loan_id}     
         * @summary Get high level info have the account
         * @param {string} externalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brokersControllerGetAccountStatus(externalId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAccountStatusDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brokersControllerGetAccountStatus(externalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all the payments made for a specific loan
         * @param {string} accountExternalId 
         * @param {string} loanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brokersControllerGetLoanPayments(accountExternalId: string, loanId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetLoanPaymentDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brokersControllerGetLoanPayments(accountExternalId, loanId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This gets all the high level details about the loan, including summary of payments in different statuses.      <br>This does not include though, the specific loan payment details.      <br>For all the payments for a specific loan, use /brokers/payments-for-loan/{account_external_id}/{loan_id}
         * @summary Get all the loans for an account
         * @param {string} accountExternalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brokersControllerGetLoansForAccount(accountExternalId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetLoanResponseBrokerVersion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brokersControllerGetLoansForAccount(accountExternalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *      Make sure you find out the next available funding date and possible payback dates are by using /deals/transaction-availablility     Note the payback date has to be in ISO for, for example, 2023-01-20
         * @summary Create a new loan
         * @param {NewBrokerLoanDto} newBrokerLoanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brokersControllerNewLoan(newBrokerLoanDto: NewBrokerLoanDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brokersControllerNewLoan(newBrokerLoanDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This is only necessary when scheduling a payment to be effective before the payback date.     <br> . By default when using the new loan api, a payment in full will be deducted on the payback date.     <br>  Before scheduling a payment, be sure to use the /payments/payment-availablility API to determine next available date we can process the payment.     
         * @summary PLACEHOLDER FOR FUTURE IDEA - Create a new payment for an exisitng loan
         * @param {NewBrokerLoanPaymentDto} newBrokerLoanPaymentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brokersControllerNewLoanPayment(newBrokerLoanPaymentDto: NewBrokerLoanPaymentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brokersControllerNewLoanPayment(newBrokerLoanPaymentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * See possible values in the schema.
         * @summary Get a presigned url to upload either bank_statements or contracts to our s3
         * @param {GetFilesUrlForBrokerDto} getFilesUrlForBrokerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brokersControllerUploadDocs(getFilesUrlForBrokerDto: GetFilesUrlForBrokerDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brokersControllerUploadDocs(getFilesUrlForBrokerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BrokersApi - factory interface
 * @export
 */
export const BrokersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BrokersApiFp(configuration)
    return {
        /**
         * This is to apply for a line of credit, ie before a customer has been approved for funding.       <br>This API is not for requesting funding for a specific loan.     <br>To request funding, use the /brokers/new-loan API
         * @summary Create a new application for a line-of-credit
         * @param {NewAccountFromPayrollCompany} newAccountFromPayrollCompany 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokersControllerCreateNewApplication(newAccountFromPayrollCompany: NewAccountFromPayrollCompany, options?: any): AxiosPromise<object> {
            return localVarFp.brokersControllerCreateNewApplication(newAccountFromPayrollCompany, options).then((request) => request(axios, basePath));
        },
        /**
         * See sample response and make sure you see the possible values in the schema.     <br> The <b>finance_status</b> field is essentially whether the account is approved or not from an underwriting perspective.     <br> The <b>funding_status</b> field is whether they are totally able to be funded.  This means that they are approved, and that we signed contracts and banking info.     <br> <u>Please note, we will not be able to fund any account unless the <b>funding_status</b> field has a value of <b>Yes</b></u>.     <br> For getting the specific loans, use the API /brokers/loans-for-account/{account_external_id}     <br> For getting payment details about a specific loan use /brokers/payments-for-loan/{account_external_id}/{loan_id}     
         * @summary Get high level info have the account
         * @param {string} externalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokersControllerGetAccountStatus(externalId: string, options?: any): AxiosPromise<GetAccountStatusDto> {
            return localVarFp.brokersControllerGetAccountStatus(externalId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all the payments made for a specific loan
         * @param {string} accountExternalId 
         * @param {string} loanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokersControllerGetLoanPayments(accountExternalId: string, loanId: string, options?: any): AxiosPromise<Array<GetLoanPaymentDto>> {
            return localVarFp.brokersControllerGetLoanPayments(accountExternalId, loanId, options).then((request) => request(axios, basePath));
        },
        /**
         * This gets all the high level details about the loan, including summary of payments in different statuses.      <br>This does not include though, the specific loan payment details.      <br>For all the payments for a specific loan, use /brokers/payments-for-loan/{account_external_id}/{loan_id}
         * @summary Get all the loans for an account
         * @param {string} accountExternalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokersControllerGetLoansForAccount(accountExternalId: string, options?: any): AxiosPromise<Array<GetLoanResponseBrokerVersion>> {
            return localVarFp.brokersControllerGetLoansForAccount(accountExternalId, options).then((request) => request(axios, basePath));
        },
        /**
         *      Make sure you find out the next available funding date and possible payback dates are by using /deals/transaction-availablility     Note the payback date has to be in ISO for, for example, 2023-01-20
         * @summary Create a new loan
         * @param {NewBrokerLoanDto} newBrokerLoanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokersControllerNewLoan(newBrokerLoanDto: NewBrokerLoanDto, options?: any): AxiosPromise<string> {
            return localVarFp.brokersControllerNewLoan(newBrokerLoanDto, options).then((request) => request(axios, basePath));
        },
        /**
         * This is only necessary when scheduling a payment to be effective before the payback date.     <br> . By default when using the new loan api, a payment in full will be deducted on the payback date.     <br>  Before scheduling a payment, be sure to use the /payments/payment-availablility API to determine next available date we can process the payment.     
         * @summary PLACEHOLDER FOR FUTURE IDEA - Create a new payment for an exisitng loan
         * @param {NewBrokerLoanPaymentDto} newBrokerLoanPaymentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokersControllerNewLoanPayment(newBrokerLoanPaymentDto: NewBrokerLoanPaymentDto, options?: any): AxiosPromise<string> {
            return localVarFp.brokersControllerNewLoanPayment(newBrokerLoanPaymentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * See possible values in the schema.
         * @summary Get a presigned url to upload either bank_statements or contracts to our s3
         * @param {GetFilesUrlForBrokerDto} getFilesUrlForBrokerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokersControllerUploadDocs(getFilesUrlForBrokerDto: GetFilesUrlForBrokerDto, options?: any): AxiosPromise<string> {
            return localVarFp.brokersControllerUploadDocs(getFilesUrlForBrokerDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BrokersApi - object-oriented interface
 * @export
 * @class BrokersApi
 * @extends {BaseAPI}
 */
export class BrokersApi extends BaseAPI {
    /**
     * This is to apply for a line of credit, ie before a customer has been approved for funding.       <br>This API is not for requesting funding for a specific loan.     <br>To request funding, use the /brokers/new-loan API
     * @summary Create a new application for a line-of-credit
     * @param {NewAccountFromPayrollCompany} newAccountFromPayrollCompany 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrokersApi
     */
    public brokersControllerCreateNewApplication(newAccountFromPayrollCompany: NewAccountFromPayrollCompany, options?: AxiosRequestConfig) {
        return BrokersApiFp(this.configuration).brokersControllerCreateNewApplication(newAccountFromPayrollCompany, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * See sample response and make sure you see the possible values in the schema.     <br> The <b>finance_status</b> field is essentially whether the account is approved or not from an underwriting perspective.     <br> The <b>funding_status</b> field is whether they are totally able to be funded.  This means that they are approved, and that we signed contracts and banking info.     <br> <u>Please note, we will not be able to fund any account unless the <b>funding_status</b> field has a value of <b>Yes</b></u>.     <br> For getting the specific loans, use the API /brokers/loans-for-account/{account_external_id}     <br> For getting payment details about a specific loan use /brokers/payments-for-loan/{account_external_id}/{loan_id}     
     * @summary Get high level info have the account
     * @param {string} externalId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrokersApi
     */
    public brokersControllerGetAccountStatus(externalId: string, options?: AxiosRequestConfig) {
        return BrokersApiFp(this.configuration).brokersControllerGetAccountStatus(externalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all the payments made for a specific loan
     * @param {string} accountExternalId 
     * @param {string} loanId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrokersApi
     */
    public brokersControllerGetLoanPayments(accountExternalId: string, loanId: string, options?: AxiosRequestConfig) {
        return BrokersApiFp(this.configuration).brokersControllerGetLoanPayments(accountExternalId, loanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This gets all the high level details about the loan, including summary of payments in different statuses.      <br>This does not include though, the specific loan payment details.      <br>For all the payments for a specific loan, use /brokers/payments-for-loan/{account_external_id}/{loan_id}
     * @summary Get all the loans for an account
     * @param {string} accountExternalId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrokersApi
     */
    public brokersControllerGetLoansForAccount(accountExternalId: string, options?: AxiosRequestConfig) {
        return BrokersApiFp(this.configuration).brokersControllerGetLoansForAccount(accountExternalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *      Make sure you find out the next available funding date and possible payback dates are by using /deals/transaction-availablility     Note the payback date has to be in ISO for, for example, 2023-01-20
     * @summary Create a new loan
     * @param {NewBrokerLoanDto} newBrokerLoanDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrokersApi
     */
    public brokersControllerNewLoan(newBrokerLoanDto: NewBrokerLoanDto, options?: AxiosRequestConfig) {
        return BrokersApiFp(this.configuration).brokersControllerNewLoan(newBrokerLoanDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This is only necessary when scheduling a payment to be effective before the payback date.     <br> . By default when using the new loan api, a payment in full will be deducted on the payback date.     <br>  Before scheduling a payment, be sure to use the /payments/payment-availablility API to determine next available date we can process the payment.     
     * @summary PLACEHOLDER FOR FUTURE IDEA - Create a new payment for an exisitng loan
     * @param {NewBrokerLoanPaymentDto} newBrokerLoanPaymentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrokersApi
     */
    public brokersControllerNewLoanPayment(newBrokerLoanPaymentDto: NewBrokerLoanPaymentDto, options?: AxiosRequestConfig) {
        return BrokersApiFp(this.configuration).brokersControllerNewLoanPayment(newBrokerLoanPaymentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * See possible values in the schema.
     * @summary Get a presigned url to upload either bank_statements or contracts to our s3
     * @param {GetFilesUrlForBrokerDto} getFilesUrlForBrokerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrokersApi
     */
    public brokersControllerUploadDocs(getFilesUrlForBrokerDto: GetFilesUrlForBrokerDto, options?: AxiosRequestConfig) {
        return BrokersApiFp(this.configuration).brokersControllerUploadDocs(getFilesUrlForBrokerDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CheckForSalesforceAccountDTO} checkForSalesforceAccountDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerCheckIfNewSignUpAlreadyHasSalesforceAccountsOrHasMultipleOrHasCognito: async (checkForSalesforceAccountDTO: CheckForSalesforceAccountDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checkForSalesforceAccountDTO' is not null or undefined
            assertParamExists('accountsControllerCheckIfNewSignUpAlreadyHasSalesforceAccountsOrHasMultipleOrHasCognito', 'checkForSalesforceAccountDTO', checkForSalesforceAccountDTO)
            const localVarPath = `/accounts/checkifsalesforceneedstobelinked`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkForSalesforceAccountDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateCognitoUserForExistingSalesforceAccountByContactEmailDto} createCognitoUserForExistingSalesforceAccountByContactEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerCreateCognitoUserForExistingSalesforceAccountByContactEmail: async (createCognitoUserForExistingSalesforceAccountByContactEmailDto: CreateCognitoUserForExistingSalesforceAccountByContactEmailDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCognitoUserForExistingSalesforceAccountByContactEmailDto' is not null or undefined
            assertParamExists('accountsControllerCreateCognitoUserForExistingSalesforceAccountByContactEmail', 'createCognitoUserForExistingSalesforceAccountByContactEmailDto', createCognitoUserForExistingSalesforceAccountByContactEmailDto)
            const localVarPath = `/accounts/create-cognito-user-account-for-an-existing-salesforce-account-By-Email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCognitoUserForExistingSalesforceAccountByContactEmailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateUserWithSalesforceDto} createUserWithSalesforceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerCreateFromIdToken: async (createUserWithSalesforceDto: CreateUserWithSalesforceDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserWithSalesforceDto' is not null or undefined
            assertParamExists('accountsControllerCreateFromIdToken', 'createUserWithSalesforceDto', createUserWithSalesforceDto)
            const localVarPath = `/accounts/create-from-id-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserWithSalesforceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerGetBankingInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/banking-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetCreditScoreDto} getCreditScoreDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerGetCreditScore: async (getCreditScoreDto: GetCreditScoreDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getCreditScoreDto' is not null or undefined
            assertParamExists('accountsControllerGetCreditScore', 'getCreditScoreDto', getCreditScoreDto)
            const localVarPath = `/accounts/get-credit-score`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getCreditScoreDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerGetISOLinks: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/iso-links`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetLendioPrequalDto} getLendioPrequalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerGetLendioPrequal: async (getLendioPrequalDto: GetLendioPrequalDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getLendioPrequalDto' is not null or undefined
            assertParamExists('accountsControllerGetLendioPrequal', 'getLendioPrequalDto', getLendioPrequalDto)
            const localVarPath = `/accounts/get-lendio-prequal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getLendioPrequalDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerGetLendioPrequalDataLocal: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/get-lendio-prequal-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerGetMyInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerGetPayrollCompanies: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/payrollCompanies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerGetUserDataDtoForLendio: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/get-user-dto-for-lendio`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateAccountDto} updateAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerUpdate: async (updateAccountDto: UpdateAccountDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateAccountDto' is not null or undefined
            assertParamExists('accountsControllerUpdate', 'updateAccountDto', updateAccountDto)
            const localVarPath = `/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAccountDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancesControllerActivateAdvance: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/advances/activate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateAdvanceDto} createAdvanceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancesControllerCreate: async (createAdvanceDto: CreateAdvanceDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAdvanceDto' is not null or undefined
            assertParamExists('advancesControllerCreate', 'createAdvanceDto', createAdvanceDto)
            const localVarPath = `/advances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAdvanceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancesControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/advances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancesControllerGetDraftAdvance: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/advances/draft-advance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateAdvanceDto} updateAdvanceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancesControllerUpdate: async (updateAdvanceDto: UpdateAdvanceDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateAdvanceDto' is not null or undefined
            assertParamExists('advancesControllerUpdate', 'updateAdvanceDto', updateAdvanceDto)
            const localVarPath = `/advances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAdvanceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/announcements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsControllerFindFutureAnnouncements: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/announcements/future`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHello: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateBankAccountDto} createBankAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankAccountsControllerCreate: async (createBankAccountDto: CreateBankAccountDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBankAccountDto' is not null or undefined
            assertParamExists('bankAccountsControllerCreate', 'createBankAccountDto', createBankAccountDto)
            const localVarPath = `/bank-accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBankAccountDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankAccountsControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bank-accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankAccountsControllerGetSendToPayroInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bank-accounts/send-to-us-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateBankAccountDto} createBankAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankAccountsControllerManualBankAdd: async (createBankAccountDto: CreateBankAccountDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBankAccountDto' is not null or undefined
            assertParamExists('bankAccountsControllerManualBankAdd', 'createBankAccountDto', createBankAccountDto)
            const localVarPath = `/bank-accounts/manualBankAdd`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBankAccountDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankAccountsControllerRemove: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('bankAccountsControllerRemove', 'uuid', uuid)
            const localVarPath = `/bank-accounts/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} repaymentSelection 
         * @param {SelectBankAccountDto} selectBankAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankAccountsControllerSelectForDeposit: async (repaymentSelection: boolean, selectBankAccountDto: SelectBankAccountDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'repaymentSelection' is not null or undefined
            assertParamExists('bankAccountsControllerSelectForDeposit', 'repaymentSelection', repaymentSelection)
            // verify required parameter 'selectBankAccountDto' is not null or undefined
            assertParamExists('bankAccountsControllerSelectForDeposit', 'selectBankAccountDto', selectBankAccountDto)
            const localVarPath = `/bank-accounts/select-for-deposit/{repaymentSelection}`
                .replace(`{${"repaymentSelection"}}`, encodeURIComponent(String(repaymentSelection)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(selectBankAccountDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} repaymentSelection 
         * @param {SelectBankAccountDto} selectBankAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankAccountsControllerSelectForWithdrawal: async (repaymentSelection: boolean, selectBankAccountDto: SelectBankAccountDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'repaymentSelection' is not null or undefined
            assertParamExists('bankAccountsControllerSelectForWithdrawal', 'repaymentSelection', repaymentSelection)
            // verify required parameter 'selectBankAccountDto' is not null or undefined
            assertParamExists('bankAccountsControllerSelectForWithdrawal', 'selectBankAccountDto', selectBankAccountDto)
            const localVarPath = `/bank-accounts/select-for-withdrawal/{repaymentSelection}`
                .replace(`{${"repaymentSelection"}}`, encodeURIComponent(String(repaymentSelection)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(selectBankAccountDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {UpdateBankAccountDto} updateBankAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankAccountsControllerUpdate: async (uuid: string, updateBankAccountDto: UpdateBankAccountDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('bankAccountsControllerUpdate', 'uuid', uuid)
            // verify required parameter 'updateBankAccountDto' is not null or undefined
            assertParamExists('bankAccountsControllerUpdate', 'updateBankAccountDto', updateBankAccountDto)
            const localVarPath = `/bank-accounts/update/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBankAccountDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateBankAccountDto} createBankAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankAccountsControllerUpsert: async (createBankAccountDto: CreateBankAccountDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBankAccountDto' is not null or undefined
            assertParamExists('bankAccountsControllerUpsert', 'createBankAccountDto', createBankAccountDto)
            const localVarPath = `/bank-accounts/upsert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBankAccountDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cashAdvanceAgreementControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cashadvanceagreement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SalesforceLog} salesforceLog 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeDataControllerLogSalesforceData: async (salesforceLog: SalesforceLog, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'salesforceLog' is not null or undefined
            assertParamExists('changeDataControllerLogSalesforceData', 'salesforceLog', salesforceLog)
            const localVarPath = `/change-data/log-salesforce-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(salesforceLog, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EmailUpdateDto} emailUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeDataControllerSendEmail: async (emailUpdateDto: EmailUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailUpdateDto' is not null or undefined
            assertParamExists('changeDataControllerSendEmail', 'emailUpdateDto', emailUpdateDto)
            const localVarPath = `/change-data/send-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeDataControllerSendEmailsBulk: async (requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('changeDataControllerSendEmailsBulk', 'requestBody', requestBody)
            const localVarPath = `/change-data/send-emails-bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateContactDto} createContactDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactsControllerCreate: async (createContactDto: CreateContactDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createContactDto' is not null or undefined
            assertParamExists('contactsControllerCreate', 'createContactDto', createContactDto)
            const localVarPath = `/contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createContactDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactsControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactsControllerGetPayrollCompanyinfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/contacts/get-Payroll-Company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactsControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('contactsControllerRemove', 'id', id)
            const localVarPath = `/contacts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateContactDto} updateContactDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactsControllerUpdate: async (id: string, updateContactDto: UpdateContactDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('contactsControllerUpdate', 'id', id)
            // verify required parameter 'updateContactDto' is not null or undefined
            assertParamExists('contactsControllerUpdate', 'updateContactDto', updateContactDto)
            const localVarPath = `/contacts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateContactDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertAllContactInfo} upsertAllContactInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactsControllerUpsertAll: async (upsertAllContactInfo: UpsertAllContactInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'upsertAllContactInfo' is not null or undefined
            assertParamExists('contactsControllerUpsertAll', 'upsertAllContactInfo', upsertAllContactInfo)
            const localVarPath = `/contacts/upsert-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertAllContactInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealsControllerActivateDeal: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/deals/activate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SignDocumentsDTO} signDocumentsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealsControllerCashAdvanceActivateDeal: async (signDocumentsDTO: SignDocumentsDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signDocumentsDTO' is not null or undefined
            assertParamExists('dealsControllerCashAdvanceActivateDeal', 'signDocumentsDTO', signDocumentsDTO)
            const localVarPath = `/deals/cashAdvanceActivate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signDocumentsDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateDealDto} updateDealDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealsControllerCashAdvanceActivateDealCancel: async (updateDealDto: UpdateDealDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateDealDto' is not null or undefined
            assertParamExists('dealsControllerCashAdvanceActivateDealCancel', 'updateDealDto', updateDealDto)
            const localVarPath = `/deals/cancel-deal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDealDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateDealDto} createDealDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealsControllerCreate: async (createDealDto: CreateDealDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDealDto' is not null or undefined
            assertParamExists('dealsControllerCreate', 'createDealDto', createDealDto)
            const localVarPath = `/deals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDealDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealsControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/deals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealsControllerGetCATransactionAvailability: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/deals/ca-transaction-availablility`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealsControllerGetDraftDeal: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/deals/draft-deal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealsControllerGetTransactionAvailability: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/deals/transaction-availablility`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateDealDto} updateDealDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealsControllerUpdate: async (updateDealDto: UpdateDealDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateDealDto' is not null or undefined
            assertParamExists('dealsControllerUpdate', 'updateDealDto', updateDealDto)
            const localVarPath = `/deals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDealDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SignDocumentsDTO} signDocumentsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerCashAdvanceAgreeement: async (signDocumentsDTO: SignDocumentsDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signDocumentsDTO' is not null or undefined
            assertParamExists('documentsControllerCashAdvanceAgreeement', 'signDocumentsDTO', signDocumentsDTO)
            const localVarPath = `/documents/sign-cash-advance-agrreement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signDocumentsDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteFileDTO} deleteFileDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerDeleteStatement: async (deleteFileDTO: DeleteFileDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteFileDTO' is not null or undefined
            assertParamExists('documentsControllerDeleteStatement', 'deleteFileDTO', deleteFileDTO)
            const localVarPath = `/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteFileDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerGenerateCashAdvanceContract: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/documents/generate-cash-advance-agreement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerGenerateDocuments: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/documents/generate-documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerGetAdditionalBankStatements: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/documents/additional-bank-statement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerGetBankStatements: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/documents/bank-statements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PdfFileDTO} pdfFileDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerGetCashAdvanceAgreement: async (pdfFileDTO: PdfFileDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pdfFileDTO' is not null or undefined
            assertParamExists('documentsControllerGetCashAdvanceAgreement', 'pdfFileDTO', pdfFileDTO)
            const localVarPath = `/documents/get-cash-advance-contract`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pdfFileDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerGetClearInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/documents/get-clear-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CommissionFileDTO} commissionFileDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerGetCommissionUrlStatementForSalesforce: async (commissionFileDTO: CommissionFileDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commissionFileDTO' is not null or undefined
            assertParamExists('documentsControllerGetCommissionUrlStatementForSalesforce', 'commissionFileDTO', commissionFileDTO)
            const localVarPath = `/documents/get-commission-statement-for-salesforce`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commissionFileDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerGetDocumentNames: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/documents/names`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetFilesnamesDto} getFilesnamesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerGetDocumentNamesApiClient: async (getFilesnamesDto: GetFilesnamesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getFilesnamesDto' is not null or undefined
            assertParamExists('documentsControllerGetDocumentNamesApiClient', 'getFilesnamesDto', getFilesnamesDto)
            const localVarPath = `/documents/names-from-client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getFilesnamesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetOcrolusStatementDTO} getOcrolusStatementDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerGetOcrolusDailyBalancesForSalesforce: async (getOcrolusStatementDTO: GetOcrolusStatementDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getOcrolusStatementDTO' is not null or undefined
            assertParamExists('documentsControllerGetOcrolusDailyBalancesForSalesforce', 'getOcrolusStatementDTO', getOcrolusStatementDTO)
            const localVarPath = `/documents/get-ocrolus-info-for-salesforce`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getOcrolusStatementDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetOcrolusStatementDTO} getOcrolusStatementDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerGetOcrolusStatementForSalesforce: async (getOcrolusStatementDTO: GetOcrolusStatementDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getOcrolusStatementDTO' is not null or undefined
            assertParamExists('documentsControllerGetOcrolusStatementForSalesforce', 'getOcrolusStatementDTO', getOcrolusStatementDTO)
            const localVarPath = `/documents/get-ocrolus-statement-for-salesforce`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getOcrolusStatementDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerGetPayrollStatements: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/documents/payroll-statements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} repaymentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerGetPdfStatement: async (repaymentUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'repaymentUuid' is not null or undefined
            assertParamExists('documentsControllerGetPdfStatement', 'repaymentUuid', repaymentUuid)
            const localVarPath = `/documents/pdf-statements/{repaymentUuid}`
                .replace(`{${"repaymentUuid"}}`, encodeURIComponent(String(repaymentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PdfFileDTO} pdfFileDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerGetPdfUrlStatementForSalesforce: async (pdfFileDTO: PdfFileDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pdfFileDTO' is not null or undefined
            assertParamExists('documentsControllerGetPdfUrlStatementForSalesforce', 'pdfFileDTO', pdfFileDTO)
            const localVarPath = `/documents/get-pdf-statement-for-salesforce`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pdfFileDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DownloadFileDTO} downloadFileDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerGetPresignedDownloadUrl: async (downloadFileDTO: DownloadFileDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'downloadFileDTO' is not null or undefined
            assertParamExists('documentsControllerGetPresignedDownloadUrl', 'downloadFileDTO', downloadFileDTO)
            const localVarPath = `/documents/get-download-url-from-client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(downloadFileDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerGetUnderwritingPayrollStatements: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/documents/underwriting-payroll-statement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CommissionFileDTO} commissionFileDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerSendEmailCommission: async (commissionFileDTO: CommissionFileDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commissionFileDTO' is not null or undefined
            assertParamExists('documentsControllerSendEmailCommission', 'commissionFileDTO', commissionFileDTO)
            const localVarPath = `/documents/send-email-commission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commissionFileDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SignDocumentsDTO} signDocumentsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerSignDocuments: async (signDocumentsDTO: SignDocumentsDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signDocumentsDTO' is not null or undefined
            assertParamExists('documentsControllerSignDocuments', 'signDocumentsDTO', signDocumentsDTO)
            const localVarPath = `/documents/sign-docs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signDocumentsDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerSubmitBankStatements: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/documents/submit-statements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetOcrolusStatementDTO} getOcrolusStatementDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerUploadStatementToOcrolus: async (getOcrolusStatementDTO: GetOcrolusStatementDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getOcrolusStatementDTO' is not null or undefined
            assertParamExists('documentsControllerUploadStatementToOcrolus', 'getOcrolusStatementDTO', getOcrolusStatementDTO)
            const localVarPath = `/documents/upload-statement-to-ocrolus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getOcrolusStatementDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<Array<any>>} files Attachments
         * @param {string} documentType bank-statement or payroll-statement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerUploadStatements: async (files: Array<Array<any>>, documentType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'files' is not null or undefined
            assertParamExists('documentsControllerUploadStatements', 'files', files)
            // verify required parameter 'documentType' is not null or undefined
            assertParamExists('documentsControllerUploadStatements', 'documentType', documentType)
            const localVarPath = `/documents/uploadem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
            if (documentType !== undefined) { 
                localVarFormParams.append('documentType', documentType as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FileUploadUrlDTO} fileUploadUrlDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerUploadStatementsUrl: async (fileUploadUrlDTO: FileUploadUrlDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileUploadUrlDTO' is not null or undefined
            assertParamExists('documentsControllerUploadStatementsUrl', 'fileUploadUrlDTO', fileUploadUrlDTO)
            const localVarPath = `/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileUploadUrlDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteFileDTO} deleteFileDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerViewStatement: async (deleteFileDTO: DeleteFileDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteFileDTO' is not null or undefined
            assertParamExists('documentsControllerViewStatement', 'deleteFileDTO', deleteFileDTO)
            const localVarPath = `/documents/viewStatement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteFileDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opportunitiesControllerCreateNewOpportunity: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/opportunities/create-new-opportunity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opportunitiesControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/opportunities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opportunitiesControllerFindCreditIncreaseOpportunity: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/opportunities/findCreditIncreaseOpportunity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OcrolusOpportunityDto} ocrolusOpportunityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opportunitiesControllerGetOcrolusData: async (ocrolusOpportunityDto: OcrolusOpportunityDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ocrolusOpportunityDto' is not null or undefined
            assertParamExists('opportunitiesControllerGetOcrolusData', 'ocrolusOpportunityDto', ocrolusOpportunityDto)
            const localVarPath = `/opportunities/get-ocrolus-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ocrolusOpportunityDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateOpportunityDto} updateOpportunityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opportunitiesControllerUpdate: async (updateOpportunityDto: UpdateOpportunityDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateOpportunityDto' is not null or undefined
            assertParamExists('opportunitiesControllerUpdate', 'updateOpportunityDto', updateOpportunityDto)
            const localVarPath = `/opportunities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOpportunityDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewLoanDebitPayment} newLoanDebitPayment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerCreateLoanDebitPayment: async (newLoanDebitPayment: NewLoanDebitPayment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newLoanDebitPayment' is not null or undefined
            assertParamExists('paymentsControllerCreateLoanDebitPayment', 'newLoanDebitPayment', newLoanDebitPayment)
            const localVarPath = `/payments/createloandebitpayment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newLoanDebitPayment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/payments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerGetNextScheduledPayment: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/payments/next-scheduled-payment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} repaymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerGetNextScheduledPaymentForRepayment: async (repaymentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'repaymentId' is not null or undefined
            assertParamExists('paymentsControllerGetNextScheduledPaymentForRepayment', 'repaymentId', repaymentId)
            const localVarPath = `/payments/next-scheduled-payment-for-repayment/{repayment_id}`
                .replace(`{${"repayment_id"}}`, encodeURIComponent(String(repaymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} repaymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerGetPaymentAvailability: async (repaymentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'repaymentId' is not null or undefined
            assertParamExists('paymentsControllerGetPaymentAvailability', 'repaymentId', repaymentId)
            const localVarPath = `/payments/payment-availablility/{repayment_id}`
                .replace(`{${"repayment_id"}}`, encodeURIComponent(String(repaymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} repaymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerGetPaymentsForRepayment: async (repaymentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'repaymentId' is not null or undefined
            assertParamExists('paymentsControllerGetPaymentsForRepayment', 'repaymentId', repaymentId)
            const localVarPath = `/payments/get-payments-for-repayment/{repayment_id}`
                .replace(`{${"repayment_id"}}`, encodeURIComponent(String(repaymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerSFUploadNachaFiles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/payments/uploadNachaFiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerSFUploadReturnFiles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/payments/uploadReturnFiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payrollControllerCheckIfConnectedToFinch: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/payroll/check-if-payroll-connected`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payrollControllerDisconnect: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/payroll/disconnect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExchangeTokenRequest} exchangeTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payrollControllerExchange: async (exchangeTokenRequest: ExchangeTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exchangeTokenRequest' is not null or undefined
            assertParamExists('payrollControllerExchange', 'exchangeTokenRequest', exchangeTokenRequest)
            const localVarPath = `/payroll/exchange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exchangeTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} associatedPhase 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payrollControllerGetCompanyInfo: async (associatedPhase: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'associatedPhase' is not null or undefined
            assertParamExists('payrollControllerGetCompanyInfo', 'associatedPhase', associatedPhase)
            const localVarPath = `/payroll/finch-get-company-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (associatedPhase !== undefined) {
                localVarQueryParameter['associatedPhase'] = associatedPhase;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payrollControllerGetNextPayroll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/payroll/next-payroll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} associatedPhase 
         * @param {string} refresh 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payrollControllerGetPayrollInfo: async (associatedPhase: string, refresh: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'associatedPhase' is not null or undefined
            assertParamExists('payrollControllerGetPayrollInfo', 'associatedPhase', associatedPhase)
            // verify required parameter 'refresh' is not null or undefined
            assertParamExists('payrollControllerGetPayrollInfo', 'refresh', refresh)
            const localVarPath = `/payroll/finch-get-payroll-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (associatedPhase !== undefined) {
                localVarQueryParameter['associatedPhase'] = associatedPhase;
            }

            if (refresh !== undefined) {
                localVarQueryParameter['refresh'] = refresh;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plaidControllerAuth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/plaid/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plaidControllerCheckIfConnected: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/plaid/check_if_connected`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plaidControllerCreateLinkToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/plaid/create_link_token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plaidControllerCreateLinkTokenForPlaidLinkUpdate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/plaid/create_link_token_for_plaid_link_update_mode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} accountUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plaidControllerDeleteUserById: async (accountUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountUuid' is not null or undefined
            assertParamExists('plaidControllerDeleteUserById', 'accountUuid', accountUuid)
            const localVarPath = `/plaid/deleteplaidtoken/{account_uuid}`
                .replace(`{${"account_uuid"}}`, encodeURIComponent(String(accountUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plaidControllerGetAccounts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/plaid/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plaidControllerGetAssetReport: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/plaid/get-asset-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plaidControllerGetBalance: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/plaid/balance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plaidControllerGetBalanceForSalesforce: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/plaid/balance-for-salesforce`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plaidControllerGetIdentity: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/plaid/identity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plaidControllerGetInvestmentTransactions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/plaid/investment_transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plaidControllerGetItem: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/plaid/item`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plaidControllerGetUserList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/plaid/checkAccess`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SetAccessTokenDTO} setAccessTokenDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plaidControllerRemoveAccessToken: async (setAccessTokenDTO: SetAccessTokenDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'setAccessTokenDTO' is not null or undefined
            assertParamExists('plaidControllerRemoveAccessToken', 'setAccessTokenDTO', setAccessTokenDTO)
            const localVarPath = `/plaid/remove_access_token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setAccessTokenDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} accountUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plaidControllerSFgetTransactionsToS3: async (accountUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountUuid' is not null or undefined
            assertParamExists('plaidControllerSFgetTransactionsToS3', 'accountUuid', accountUuid)
            const localVarPath = `/plaid/transactions/{account_uuid}`
                .replace(`{${"account_uuid"}}`, encodeURIComponent(String(accountUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SetAccessTokenDTO} setAccessTokenDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plaidControllerSetAccessToken: async (setAccessTokenDTO: SetAccessTokenDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'setAccessTokenDTO' is not null or undefined
            assertParamExists('plaidControllerSetAccessToken', 'setAccessTokenDTO', setAccessTokenDTO)
            const localVarPath = `/plaid/set_access_token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setAccessTokenDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} accountUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plaidControllerTransactionsForAnalysis: async (accountUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountUuid' is not null or undefined
            assertParamExists('plaidControllerTransactionsForAnalysis', 'accountUuid', accountUuid)
            const localVarPath = `/plaid/transactionsforanalysis/{account_uuid}`
                .replace(`{${"account_uuid"}}`, encodeURIComponent(String(accountUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalAccessControllerGetIsPortalAccessible: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/isPortalAccessible`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateContactDto} updateContactDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInfoControllerAdminUpdateUserAttributes: async (updateContactDto: UpdateContactDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateContactDto' is not null or undefined
            assertParamExists('userInfoControllerAdminUpdateUserAttributes', 'updateContactDto', updateContactDto)
            const localVarPath = `/user-info/update-user-attributes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateContactDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteCognitUseroDto} deleteCognitUseroDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInfoControllerDeleteCognitoUserFromSalesforce: async (deleteCognitUseroDto: DeleteCognitUseroDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteCognitUseroDto' is not null or undefined
            assertParamExists('userInfoControllerDeleteCognitoUserFromSalesforce', 'deleteCognitUseroDto', deleteCognitUseroDto)
            const localVarPath = `/user-info/delete-cognito-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteCognitUseroDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteCognitUseroDto} deleteCognitUseroDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInfoControllerDisableUserInCognitoFromSalesforce: async (deleteCognitUseroDto: DeleteCognitUseroDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteCognitUseroDto' is not null or undefined
            assertParamExists('userInfoControllerDisableUserInCognitoFromSalesforce', 'deleteCognitUseroDto', deleteCognitUseroDto)
            const localVarPath = `/user-info/disable-cognito-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteCognitUseroDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteCognitUseroDto} deleteCognitUseroDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInfoControllerEnableUserInCognitoFromSalesforce: async (deleteCognitUseroDto: DeleteCognitUseroDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteCognitUseroDto' is not null or undefined
            assertParamExists('userInfoControllerEnableUserInCognitoFromSalesforce', 'deleteCognitUseroDto', deleteCognitUseroDto)
            const localVarPath = `/user-info/enable-cognito-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteCognitUseroDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteCognitUseroDto} deleteCognitUseroDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInfoControllerGetCognitoUserStatusForSalesforce: async (deleteCognitUseroDto: DeleteCognitUseroDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteCognitUseroDto' is not null or undefined
            assertParamExists('userInfoControllerGetCognitoUserStatusForSalesforce', 'deleteCognitUseroDto', deleteCognitUseroDto)
            const localVarPath = `/user-info/get-cognito-user-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteCognitUseroDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInfoControllerGetUserInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserNotification} userNotification 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInfoControllerSendOTP: async (userNotification: UserNotification, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userNotification' is not null or undefined
            assertParamExists('userInfoControllerSendOTP', 'userNotification', userNotification)
            const localVarPath = `/user-info/sendOTP`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userNotification, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserNotification} userNotification 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInfoControllerSendStatementEmail: async (userNotification: UserNotification, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userNotification' is not null or undefined
            assertParamExists('userInfoControllerSendStatementEmail', 'userNotification', userNotification)
            const localVarPath = `/user-info/sendStatementEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userNotification, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserNotification} userNotification 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInfoControllerSendVerificationEmail: async (userNotification: UserNotification, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userNotification' is not null or undefined
            assertParamExists('userInfoControllerSendVerificationEmail', 'userNotification', userNotification)
            const localVarPath = `/user-info/sendVerificationEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userNotification, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserNotification} userNotification 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInfoControllerUpdatePhone: async (userNotification: UserNotification, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userNotification' is not null or undefined
            assertParamExists('userInfoControllerUpdatePhone', 'userNotification', userNotification)
            const localVarPath = `/user-info/updatePhone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userNotification, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateUserInfoDto} updateUserInfoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInfoControllerUpdateUserInfo: async (updateUserInfoDto: UpdateUserInfoDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateUserInfoDto' is not null or undefined
            assertParamExists('userInfoControllerUpdateUserInfo', 'updateUserInfoDto', updateUserInfoDto)
            const localVarPath = `/user-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserInfoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CheckForSalesforceAccountDTO} checkForSalesforceAccountDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerCheckIfNewSignUpAlreadyHasSalesforceAccountsOrHasMultipleOrHasCognito(checkForSalesforceAccountDTO: CheckForSalesforceAccountDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerCheckIfNewSignUpAlreadyHasSalesforceAccountsOrHasMultipleOrHasCognito(checkForSalesforceAccountDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateCognitoUserForExistingSalesforceAccountByContactEmailDto} createCognitoUserForExistingSalesforceAccountByContactEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerCreateCognitoUserForExistingSalesforceAccountByContactEmail(createCognitoUserForExistingSalesforceAccountByContactEmailDto: CreateCognitoUserForExistingSalesforceAccountByContactEmailDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerCreateCognitoUserForExistingSalesforceAccountByContactEmail(createCognitoUserForExistingSalesforceAccountByContactEmailDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateUserWithSalesforceDto} createUserWithSalesforceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerCreateFromIdToken(createUserWithSalesforceDto: CreateUserWithSalesforceDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateAccountResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerCreateFromIdToken(createUserWithSalesforceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerGetBankingInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerGetBankingInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetCreditScoreDto} getCreditScoreDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerGetCreditScore(getCreditScoreDto: GetCreditScoreDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerGetCreditScore(getCreditScoreDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerGetISOLinks(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerGetISOLinks(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetLendioPrequalDto} getLendioPrequalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerGetLendioPrequal(getLendioPrequalDto: GetLendioPrequalDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerGetLendioPrequal(getLendioPrequalDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerGetLendioPrequalDataLocal(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerGetLendioPrequalDataLocal(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerGetMyInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAccountDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerGetMyInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerGetPayrollCompanies(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerGetPayrollCompanies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerGetUserDataDtoForLendio(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerGetUserDataDtoForLendio(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateAccountDto} updateAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerUpdate(updateAccountDto: UpdateAccountDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerUpdate(updateAccountDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async advancesControllerActivateAdvance(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.advancesControllerActivateAdvance(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateAdvanceDto} createAdvanceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async advancesControllerCreate(createAdvanceDto: CreateAdvanceDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.advancesControllerCreate(createAdvanceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async advancesControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdvanceStandardForm>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.advancesControllerFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async advancesControllerGetDraftAdvance(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DraftDeal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.advancesControllerGetDraftAdvance(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateAdvanceDto} updateAdvanceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async advancesControllerUpdate(updateAdvanceDto: UpdateAdvanceDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.advancesControllerUpdate(updateAdvanceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async announcementsControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAnnouncementDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.announcementsControllerFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async announcementsControllerFindFutureAnnouncements(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAnnouncementDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.announcementsControllerFindFutureAnnouncements(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appControllerGetHello(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appControllerGetHello(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateBankAccountDto} createBankAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankAccountsControllerCreate(createBankAccountDto: CreateBankAccountDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankAccountsControllerCreate(createBankAccountDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankAccountsControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BankAccount>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankAccountsControllerFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankAccountsControllerGetSendToPayroInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankAccountsControllerGetSendToPayroInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateBankAccountDto} createBankAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankAccountsControllerManualBankAdd(createBankAccountDto: CreateBankAccountDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankAccountsControllerManualBankAdd(createBankAccountDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankAccountsControllerRemove(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankAccountsControllerRemove(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} repaymentSelection 
         * @param {SelectBankAccountDto} selectBankAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankAccountsControllerSelectForDeposit(repaymentSelection: boolean, selectBankAccountDto: SelectBankAccountDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankAccountsControllerSelectForDeposit(repaymentSelection, selectBankAccountDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} repaymentSelection 
         * @param {SelectBankAccountDto} selectBankAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankAccountsControllerSelectForWithdrawal(repaymentSelection: boolean, selectBankAccountDto: SelectBankAccountDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankAccountsControllerSelectForWithdrawal(repaymentSelection, selectBankAccountDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {UpdateBankAccountDto} updateBankAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankAccountsControllerUpdate(uuid: string, updateBankAccountDto: UpdateBankAccountDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankAccountsControllerUpdate(uuid, updateBankAccountDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateBankAccountDto} createBankAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankAccountsControllerUpsert(createBankAccountDto: CreateBankAccountDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankAccountsControllerUpsert(createBankAccountDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cashAdvanceAgreementControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAgreementDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cashAdvanceAgreementControllerFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SalesforceLog} salesforceLog 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeDataControllerLogSalesforceData(salesforceLog: SalesforceLog, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeDataControllerLogSalesforceData(salesforceLog, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EmailUpdateDto} emailUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeDataControllerSendEmail(emailUpdateDto: EmailUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeDataControllerSendEmail(emailUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeDataControllerSendEmailsBulk(requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeDataControllerSendEmailsBulk(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateContactDto} createContactDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contactsControllerCreate(createContactDto: CreateContactDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contactsControllerCreate(createContactDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contactsControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contactsControllerFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contactsControllerGetPayrollCompanyinfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contactsControllerGetPayrollCompanyinfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contactsControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contactsControllerRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateContactDto} updateContactDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contactsControllerUpdate(id: string, updateContactDto: UpdateContactDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contactsControllerUpdate(id, updateContactDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertAllContactInfo} upsertAllContactInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contactsControllerUpsertAll(upsertAllContactInfo: UpsertAllContactInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contactsControllerUpsertAll(upsertAllContactInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealsControllerActivateDeal(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealsControllerActivateDeal(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SignDocumentsDTO} signDocumentsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealsControllerCashAdvanceActivateDeal(signDocumentsDTO: SignDocumentsDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealsControllerCashAdvanceActivateDeal(signDocumentsDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateDealDto} updateDealDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealsControllerCashAdvanceActivateDealCancel(updateDealDto: UpdateDealDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealsControllerCashAdvanceActivateDealCancel(updateDealDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateDealDto} createDealDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealsControllerCreate(createDealDto: CreateDealDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealsControllerCreate(createDealDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealsControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDeals>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealsControllerFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealsControllerGetCATransactionAvailability(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionAvailability>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealsControllerGetCATransactionAvailability(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealsControllerGetDraftDeal(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DraftDeal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealsControllerGetDraftDeal(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealsControllerGetTransactionAvailability(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionAvailability>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealsControllerGetTransactionAvailability(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateDealDto} updateDealDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dealsControllerUpdate(updateDealDto: UpdateDealDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dealsControllerUpdate(updateDealDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SignDocumentsDTO} signDocumentsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsControllerCashAdvanceAgreeement(signDocumentsDTO: SignDocumentsDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsControllerCashAdvanceAgreeement(signDocumentsDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeleteFileDTO} deleteFileDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsControllerDeleteStatement(deleteFileDTO: DeleteFileDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsControllerDeleteStatement(deleteFileDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsControllerGenerateCashAdvanceContract(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateDocsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsControllerGenerateCashAdvanceContract(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsControllerGenerateDocuments(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateDocsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsControllerGenerateDocuments(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsControllerGetAdditionalBankStatements(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsControllerGetAdditionalBankStatements(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsControllerGetBankStatements(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsControllerGetBankStatements(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PdfFileDTO} pdfFileDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsControllerGetCashAdvanceAgreement(pdfFileDTO: PdfFileDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsControllerGetCashAdvanceAgreement(pdfFileDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsControllerGetClearInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsControllerGetClearInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CommissionFileDTO} commissionFileDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsControllerGetCommissionUrlStatementForSalesforce(commissionFileDTO: CommissionFileDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsControllerGetCommissionUrlStatementForSalesforce(commissionFileDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsControllerGetDocumentNames(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsControllerGetDocumentNames(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetFilesnamesDto} getFilesnamesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsControllerGetDocumentNamesApiClient(getFilesnamesDto: GetFilesnamesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsControllerGetDocumentNamesApiClient(getFilesnamesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetOcrolusStatementDTO} getOcrolusStatementDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsControllerGetOcrolusDailyBalancesForSalesforce(getOcrolusStatementDTO: GetOcrolusStatementDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsControllerGetOcrolusDailyBalancesForSalesforce(getOcrolusStatementDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetOcrolusStatementDTO} getOcrolusStatementDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsControllerGetOcrolusStatementForSalesforce(getOcrolusStatementDTO: GetOcrolusStatementDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsControllerGetOcrolusStatementForSalesforce(getOcrolusStatementDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsControllerGetPayrollStatements(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsControllerGetPayrollStatements(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} repaymentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsControllerGetPdfStatement(repaymentUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsControllerGetPdfStatement(repaymentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PdfFileDTO} pdfFileDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsControllerGetPdfUrlStatementForSalesforce(pdfFileDTO: PdfFileDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsControllerGetPdfUrlStatementForSalesforce(pdfFileDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DownloadFileDTO} downloadFileDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsControllerGetPresignedDownloadUrl(downloadFileDTO: DownloadFileDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsControllerGetPresignedDownloadUrl(downloadFileDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsControllerGetUnderwritingPayrollStatements(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsControllerGetUnderwritingPayrollStatements(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CommissionFileDTO} commissionFileDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsControllerSendEmailCommission(commissionFileDTO: CommissionFileDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsControllerSendEmailCommission(commissionFileDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SignDocumentsDTO} signDocumentsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsControllerSignDocuments(signDocumentsDTO: SignDocumentsDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsControllerSignDocuments(signDocumentsDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsControllerSubmitBankStatements(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsControllerSubmitBankStatements(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetOcrolusStatementDTO} getOcrolusStatementDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsControllerUploadStatementToOcrolus(getOcrolusStatementDTO: GetOcrolusStatementDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsControllerUploadStatementToOcrolus(getOcrolusStatementDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<Array<any>>} files Attachments
         * @param {string} documentType bank-statement or payroll-statement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsControllerUploadStatements(files: Array<Array<any>>, documentType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsControllerUploadStatements(files, documentType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FileUploadUrlDTO} fileUploadUrlDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsControllerUploadStatementsUrl(fileUploadUrlDTO: FileUploadUrlDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsControllerUploadStatementsUrl(fileUploadUrlDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeleteFileDTO} deleteFileDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsControllerViewStatement(deleteFileDTO: DeleteFileDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsControllerViewStatement(deleteFileDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async opportunitiesControllerCreateNewOpportunity(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.opportunitiesControllerCreateNewOpportunity(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async opportunitiesControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Opportunity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.opportunitiesControllerFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async opportunitiesControllerFindCreditIncreaseOpportunity(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Opportunity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.opportunitiesControllerFindCreditIncreaseOpportunity(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OcrolusOpportunityDto} ocrolusOpportunityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async opportunitiesControllerGetOcrolusData(ocrolusOpportunityDto: OcrolusOpportunityDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.opportunitiesControllerGetOcrolusData(ocrolusOpportunityDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateOpportunityDto} updateOpportunityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async opportunitiesControllerUpdate(updateOpportunityDto: UpdateOpportunityDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.opportunitiesControllerUpdate(updateOpportunityDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewLoanDebitPayment} newLoanDebitPayment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerCreateLoanDebitPayment(newLoanDebitPayment: NewLoanDebitPayment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerCreateLoanDebitPayment(newLoanDebitPayment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LoanPayment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerGetNextScheduledPayment(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NextScheduledPayment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerGetNextScheduledPayment(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} repaymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerGetNextScheduledPaymentForRepayment(repaymentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NextScheduledPayment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerGetNextScheduledPaymentForRepayment(repaymentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} repaymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerGetPaymentAvailability(repaymentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentDate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerGetPaymentAvailability(repaymentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} repaymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerGetPaymentsForRepayment(repaymentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LoanPayment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerGetPaymentsForRepayment(repaymentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerSFUploadNachaFiles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerSFUploadNachaFiles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerSFUploadReturnFiles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerSFUploadReturnFiles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async payrollControllerCheckIfConnectedToFinch(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PayrollConnectedStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.payrollControllerCheckIfConnectedToFinch(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async payrollControllerDisconnect(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.payrollControllerDisconnect(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ExchangeTokenRequest} exchangeTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async payrollControllerExchange(exchangeTokenRequest: ExchangeTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.payrollControllerExchange(exchangeTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} associatedPhase 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async payrollControllerGetCompanyInfo(associatedPhase: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.payrollControllerGetCompanyInfo(associatedPhase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async payrollControllerGetNextPayroll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NextPayroll>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.payrollControllerGetNextPayroll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} associatedPhase 
         * @param {string} refresh 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async payrollControllerGetPayrollInfo(associatedPhase: string, refresh: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PayrollInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.payrollControllerGetPayrollInfo(associatedPhase, refresh, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plaidControllerAuth(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plaidControllerAuth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plaidControllerCheckIfConnected(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plaidControllerCheckIfConnected(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plaidControllerCreateLinkToken(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plaidControllerCreateLinkToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plaidControllerCreateLinkTokenForPlaidLinkUpdate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plaidControllerCreateLinkTokenForPlaidLinkUpdate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} accountUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plaidControllerDeleteUserById(accountUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plaidControllerDeleteUserById(accountUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plaidControllerGetAccounts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plaidControllerGetAccounts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plaidControllerGetAssetReport(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plaidControllerGetAssetReport(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plaidControllerGetBalance(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plaidControllerGetBalance(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plaidControllerGetBalanceForSalesforce(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plaidControllerGetBalanceForSalesforce(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plaidControllerGetIdentity(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plaidControllerGetIdentity(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plaidControllerGetInvestmentTransactions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plaidControllerGetInvestmentTransactions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plaidControllerGetItem(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plaidControllerGetItem(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plaidControllerGetUserList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plaidControllerGetUserList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SetAccessTokenDTO} setAccessTokenDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plaidControllerRemoveAccessToken(setAccessTokenDTO: SetAccessTokenDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plaidControllerRemoveAccessToken(setAccessTokenDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} accountUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plaidControllerSFgetTransactionsToS3(accountUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plaidControllerSFgetTransactionsToS3(accountUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SetAccessTokenDTO} setAccessTokenDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plaidControllerSetAccessToken(setAccessTokenDTO: SetAccessTokenDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plaidControllerSetAccessToken(setAccessTokenDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} accountUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plaidControllerTransactionsForAnalysis(accountUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plaidControllerTransactionsForAnalysis(accountUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portalAccessControllerGetIsPortalAccessible(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portalAccessControllerGetIsPortalAccessible(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateContactDto} updateContactDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userInfoControllerAdminUpdateUserAttributes(updateContactDto: UpdateContactDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userInfoControllerAdminUpdateUserAttributes(updateContactDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeleteCognitUseroDto} deleteCognitUseroDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userInfoControllerDeleteCognitoUserFromSalesforce(deleteCognitUseroDto: DeleteCognitUseroDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userInfoControllerDeleteCognitoUserFromSalesforce(deleteCognitUseroDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeleteCognitUseroDto} deleteCognitUseroDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userInfoControllerDisableUserInCognitoFromSalesforce(deleteCognitUseroDto: DeleteCognitUseroDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userInfoControllerDisableUserInCognitoFromSalesforce(deleteCognitUseroDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeleteCognitUseroDto} deleteCognitUseroDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userInfoControllerEnableUserInCognitoFromSalesforce(deleteCognitUseroDto: DeleteCognitUseroDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userInfoControllerEnableUserInCognitoFromSalesforce(deleteCognitUseroDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeleteCognitUseroDto} deleteCognitUseroDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userInfoControllerGetCognitoUserStatusForSalesforce(deleteCognitUseroDto: DeleteCognitUseroDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userInfoControllerGetCognitoUserStatusForSalesforce(deleteCognitUseroDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userInfoControllerGetUserInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userInfoControllerGetUserInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserNotification} userNotification 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userInfoControllerSendOTP(userNotification: UserNotification, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userInfoControllerSendOTP(userNotification, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserNotification} userNotification 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userInfoControllerSendStatementEmail(userNotification: UserNotification, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userInfoControllerSendStatementEmail(userNotification, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserNotification} userNotification 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userInfoControllerSendVerificationEmail(userNotification: UserNotification, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userInfoControllerSendVerificationEmail(userNotification, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserNotification} userNotification 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userInfoControllerUpdatePhone(userNotification: UserNotification, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userInfoControllerUpdatePhone(userNotification, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateUserInfoDto} updateUserInfoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userInfoControllerUpdateUserInfo(updateUserInfoDto: UpdateUserInfoDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userInfoControllerUpdateUserInfo(updateUserInfoDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {CheckForSalesforceAccountDTO} checkForSalesforceAccountDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerCheckIfNewSignUpAlreadyHasSalesforceAccountsOrHasMultipleOrHasCognito(checkForSalesforceAccountDTO: CheckForSalesforceAccountDTO, options?: any): AxiosPromise<object> {
            return localVarFp.accountsControllerCheckIfNewSignUpAlreadyHasSalesforceAccountsOrHasMultipleOrHasCognito(checkForSalesforceAccountDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateCognitoUserForExistingSalesforceAccountByContactEmailDto} createCognitoUserForExistingSalesforceAccountByContactEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerCreateCognitoUserForExistingSalesforceAccountByContactEmail(createCognitoUserForExistingSalesforceAccountByContactEmailDto: CreateCognitoUserForExistingSalesforceAccountByContactEmailDto, options?: any): AxiosPromise<string> {
            return localVarFp.accountsControllerCreateCognitoUserForExistingSalesforceAccountByContactEmail(createCognitoUserForExistingSalesforceAccountByContactEmailDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateUserWithSalesforceDto} createUserWithSalesforceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerCreateFromIdToken(createUserWithSalesforceDto: CreateUserWithSalesforceDto, options?: any): AxiosPromise<CreateAccountResponseDTO> {
            return localVarFp.accountsControllerCreateFromIdToken(createUserWithSalesforceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerGetBankingInfo(options?: any): AxiosPromise<object> {
            return localVarFp.accountsControllerGetBankingInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetCreditScoreDto} getCreditScoreDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerGetCreditScore(getCreditScoreDto: GetCreditScoreDto, options?: any): AxiosPromise<object> {
            return localVarFp.accountsControllerGetCreditScore(getCreditScoreDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerGetISOLinks(options?: any): AxiosPromise<object> {
            return localVarFp.accountsControllerGetISOLinks(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetLendioPrequalDto} getLendioPrequalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerGetLendioPrequal(getLendioPrequalDto: GetLendioPrequalDto, options?: any): AxiosPromise<object> {
            return localVarFp.accountsControllerGetLendioPrequal(getLendioPrequalDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerGetLendioPrequalDataLocal(options?: any): AxiosPromise<object> {
            return localVarFp.accountsControllerGetLendioPrequalDataLocal(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerGetMyInfo(options?: any): AxiosPromise<GetAccountDto> {
            return localVarFp.accountsControllerGetMyInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerGetPayrollCompanies(options?: any): AxiosPromise<object> {
            return localVarFp.accountsControllerGetPayrollCompanies(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerGetUserDataDtoForLendio(options?: any): AxiosPromise<object> {
            return localVarFp.accountsControllerGetUserDataDtoForLendio(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateAccountDto} updateAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerUpdate(updateAccountDto: UpdateAccountDto, options?: any): AxiosPromise<object> {
            return localVarFp.accountsControllerUpdate(updateAccountDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancesControllerActivateAdvance(options?: any): AxiosPromise<object> {
            return localVarFp.advancesControllerActivateAdvance(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateAdvanceDto} createAdvanceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancesControllerCreate(createAdvanceDto: CreateAdvanceDto, options?: any): AxiosPromise<void> {
            return localVarFp.advancesControllerCreate(createAdvanceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancesControllerFindAll(options?: any): AxiosPromise<Array<AdvanceStandardForm>> {
            return localVarFp.advancesControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancesControllerGetDraftAdvance(options?: any): AxiosPromise<DraftDeal> {
            return localVarFp.advancesControllerGetDraftAdvance(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateAdvanceDto} updateAdvanceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancesControllerUpdate(updateAdvanceDto: UpdateAdvanceDto, options?: any): AxiosPromise<object> {
            return localVarFp.advancesControllerUpdate(updateAdvanceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsControllerFindAll(options?: any): AxiosPromise<GetAnnouncementDto> {
            return localVarFp.announcementsControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsControllerFindFutureAnnouncements(options?: any): AxiosPromise<GetAnnouncementDto> {
            return localVarFp.announcementsControllerFindFutureAnnouncements(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHello(options?: any): AxiosPromise<string> {
            return localVarFp.appControllerGetHello(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateBankAccountDto} createBankAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankAccountsControllerCreate(createBankAccountDto: CreateBankAccountDto, options?: any): AxiosPromise<object> {
            return localVarFp.bankAccountsControllerCreate(createBankAccountDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankAccountsControllerFindAll(options?: any): AxiosPromise<Array<BankAccount>> {
            return localVarFp.bankAccountsControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankAccountsControllerGetSendToPayroInfo(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.bankAccountsControllerGetSendToPayroInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateBankAccountDto} createBankAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankAccountsControllerManualBankAdd(createBankAccountDto: CreateBankAccountDto, options?: any): AxiosPromise<object> {
            return localVarFp.bankAccountsControllerManualBankAdd(createBankAccountDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankAccountsControllerRemove(uuid: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.bankAccountsControllerRemove(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} repaymentSelection 
         * @param {SelectBankAccountDto} selectBankAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankAccountsControllerSelectForDeposit(repaymentSelection: boolean, selectBankAccountDto: SelectBankAccountDto, options?: any): AxiosPromise<string> {
            return localVarFp.bankAccountsControllerSelectForDeposit(repaymentSelection, selectBankAccountDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} repaymentSelection 
         * @param {SelectBankAccountDto} selectBankAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankAccountsControllerSelectForWithdrawal(repaymentSelection: boolean, selectBankAccountDto: SelectBankAccountDto, options?: any): AxiosPromise<string> {
            return localVarFp.bankAccountsControllerSelectForWithdrawal(repaymentSelection, selectBankAccountDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid 
         * @param {UpdateBankAccountDto} updateBankAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankAccountsControllerUpdate(uuid: string, updateBankAccountDto: UpdateBankAccountDto, options?: any): AxiosPromise<object> {
            return localVarFp.bankAccountsControllerUpdate(uuid, updateBankAccountDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateBankAccountDto} createBankAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankAccountsControllerUpsert(createBankAccountDto: CreateBankAccountDto, options?: any): AxiosPromise<string> {
            return localVarFp.bankAccountsControllerUpsert(createBankAccountDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cashAdvanceAgreementControllerFindAll(options?: any): AxiosPromise<GetAgreementDto> {
            return localVarFp.cashAdvanceAgreementControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SalesforceLog} salesforceLog 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeDataControllerLogSalesforceData(salesforceLog: SalesforceLog, options?: any): AxiosPromise<void> {
            return localVarFp.changeDataControllerLogSalesforceData(salesforceLog, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EmailUpdateDto} emailUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeDataControllerSendEmail(emailUpdateDto: EmailUpdateDto, options?: any): AxiosPromise<void> {
            return localVarFp.changeDataControllerSendEmail(emailUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeDataControllerSendEmailsBulk(requestBody: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.changeDataControllerSendEmailsBulk(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateContactDto} createContactDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactsControllerCreate(createContactDto: CreateContactDto, options?: any): AxiosPromise<object> {
            return localVarFp.contactsControllerCreate(createContactDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactsControllerFindAll(options?: any): AxiosPromise<Array<object>> {
            return localVarFp.contactsControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactsControllerGetPayrollCompanyinfo(options?: any): AxiosPromise<Array<object>> {
            return localVarFp.contactsControllerGetPayrollCompanyinfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactsControllerRemove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.contactsControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateContactDto} updateContactDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactsControllerUpdate(id: string, updateContactDto: UpdateContactDto, options?: any): AxiosPromise<void> {
            return localVarFp.contactsControllerUpdate(id, updateContactDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertAllContactInfo} upsertAllContactInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactsControllerUpsertAll(upsertAllContactInfo: UpsertAllContactInfo, options?: any): AxiosPromise<void> {
            return localVarFp.contactsControllerUpsertAll(upsertAllContactInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealsControllerActivateDeal(options?: any): AxiosPromise<object> {
            return localVarFp.dealsControllerActivateDeal(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SignDocumentsDTO} signDocumentsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealsControllerCashAdvanceActivateDeal(signDocumentsDTO: SignDocumentsDTO, options?: any): AxiosPromise<object> {
            return localVarFp.dealsControllerCashAdvanceActivateDeal(signDocumentsDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateDealDto} updateDealDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealsControllerCashAdvanceActivateDealCancel(updateDealDto: UpdateDealDto, options?: any): AxiosPromise<string> {
            return localVarFp.dealsControllerCashAdvanceActivateDealCancel(updateDealDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateDealDto} createDealDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealsControllerCreate(createDealDto: CreateDealDto, options?: any): AxiosPromise<void> {
            return localVarFp.dealsControllerCreate(createDealDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealsControllerFindAll(options?: any): AxiosPromise<GetDeals> {
            return localVarFp.dealsControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealsControllerGetCATransactionAvailability(options?: any): AxiosPromise<TransactionAvailability> {
            return localVarFp.dealsControllerGetCATransactionAvailability(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealsControllerGetDraftDeal(options?: any): AxiosPromise<DraftDeal> {
            return localVarFp.dealsControllerGetDraftDeal(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealsControllerGetTransactionAvailability(options?: any): AxiosPromise<TransactionAvailability> {
            return localVarFp.dealsControllerGetTransactionAvailability(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateDealDto} updateDealDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dealsControllerUpdate(updateDealDto: UpdateDealDto, options?: any): AxiosPromise<object> {
            return localVarFp.dealsControllerUpdate(updateDealDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SignDocumentsDTO} signDocumentsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerCashAdvanceAgreeement(signDocumentsDTO: SignDocumentsDTO, options?: any): AxiosPromise<string> {
            return localVarFp.documentsControllerCashAdvanceAgreeement(signDocumentsDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteFileDTO} deleteFileDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerDeleteStatement(deleteFileDTO: DeleteFileDTO, options?: any): AxiosPromise<object> {
            return localVarFp.documentsControllerDeleteStatement(deleteFileDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerGenerateCashAdvanceContract(options?: any): AxiosPromise<GenerateDocsResponse> {
            return localVarFp.documentsControllerGenerateCashAdvanceContract(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerGenerateDocuments(options?: any): AxiosPromise<GenerateDocsResponse> {
            return localVarFp.documentsControllerGenerateDocuments(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerGetAdditionalBankStatements(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.documentsControllerGetAdditionalBankStatements(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerGetBankStatements(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.documentsControllerGetBankStatements(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PdfFileDTO} pdfFileDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerGetCashAdvanceAgreement(pdfFileDTO: PdfFileDTO, options?: any): AxiosPromise<string> {
            return localVarFp.documentsControllerGetCashAdvanceAgreement(pdfFileDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerGetClearInfo(options?: any): AxiosPromise<void> {
            return localVarFp.documentsControllerGetClearInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CommissionFileDTO} commissionFileDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerGetCommissionUrlStatementForSalesforce(commissionFileDTO: CommissionFileDTO, options?: any): AxiosPromise<string> {
            return localVarFp.documentsControllerGetCommissionUrlStatementForSalesforce(commissionFileDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerGetDocumentNames(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.documentsControllerGetDocumentNames(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetFilesnamesDto} getFilesnamesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerGetDocumentNamesApiClient(getFilesnamesDto: GetFilesnamesDto, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.documentsControllerGetDocumentNamesApiClient(getFilesnamesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetOcrolusStatementDTO} getOcrolusStatementDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerGetOcrolusDailyBalancesForSalesforce(getOcrolusStatementDTO: GetOcrolusStatementDTO, options?: any): AxiosPromise<object> {
            return localVarFp.documentsControllerGetOcrolusDailyBalancesForSalesforce(getOcrolusStatementDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetOcrolusStatementDTO} getOcrolusStatementDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerGetOcrolusStatementForSalesforce(getOcrolusStatementDTO: GetOcrolusStatementDTO, options?: any): AxiosPromise<object> {
            return localVarFp.documentsControllerGetOcrolusStatementForSalesforce(getOcrolusStatementDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerGetPayrollStatements(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.documentsControllerGetPayrollStatements(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} repaymentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerGetPdfStatement(repaymentUuid: string, options?: any): AxiosPromise<string> {
            return localVarFp.documentsControllerGetPdfStatement(repaymentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PdfFileDTO} pdfFileDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerGetPdfUrlStatementForSalesforce(pdfFileDTO: PdfFileDTO, options?: any): AxiosPromise<string> {
            return localVarFp.documentsControllerGetPdfUrlStatementForSalesforce(pdfFileDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DownloadFileDTO} downloadFileDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerGetPresignedDownloadUrl(downloadFileDTO: DownloadFileDTO, options?: any): AxiosPromise<string> {
            return localVarFp.documentsControllerGetPresignedDownloadUrl(downloadFileDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerGetUnderwritingPayrollStatements(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.documentsControllerGetUnderwritingPayrollStatements(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CommissionFileDTO} commissionFileDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerSendEmailCommission(commissionFileDTO: CommissionFileDTO, options?: any): AxiosPromise<string> {
            return localVarFp.documentsControllerSendEmailCommission(commissionFileDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SignDocumentsDTO} signDocumentsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerSignDocuments(signDocumentsDTO: SignDocumentsDTO, options?: any): AxiosPromise<string> {
            return localVarFp.documentsControllerSignDocuments(signDocumentsDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerSubmitBankStatements(options?: any): AxiosPromise<void> {
            return localVarFp.documentsControllerSubmitBankStatements(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetOcrolusStatementDTO} getOcrolusStatementDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerUploadStatementToOcrolus(getOcrolusStatementDTO: GetOcrolusStatementDTO, options?: any): AxiosPromise<string> {
            return localVarFp.documentsControllerUploadStatementToOcrolus(getOcrolusStatementDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<Array<any>>} files Attachments
         * @param {string} documentType bank-statement or payroll-statement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerUploadStatements(files: Array<Array<any>>, documentType: string, options?: any): AxiosPromise<string> {
            return localVarFp.documentsControllerUploadStatements(files, documentType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FileUploadUrlDTO} fileUploadUrlDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerUploadStatementsUrl(fileUploadUrlDTO: FileUploadUrlDTO, options?: any): AxiosPromise<string> {
            return localVarFp.documentsControllerUploadStatementsUrl(fileUploadUrlDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteFileDTO} deleteFileDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerViewStatement(deleteFileDTO: DeleteFileDTO, options?: any): AxiosPromise<string> {
            return localVarFp.documentsControllerViewStatement(deleteFileDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opportunitiesControllerCreateNewOpportunity(options?: any): AxiosPromise<object> {
            return localVarFp.opportunitiesControllerCreateNewOpportunity(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opportunitiesControllerFindAll(options?: any): AxiosPromise<Array<Opportunity>> {
            return localVarFp.opportunitiesControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opportunitiesControllerFindCreditIncreaseOpportunity(options?: any): AxiosPromise<Array<Opportunity>> {
            return localVarFp.opportunitiesControllerFindCreditIncreaseOpportunity(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OcrolusOpportunityDto} ocrolusOpportunityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opportunitiesControllerGetOcrolusData(ocrolusOpportunityDto: OcrolusOpportunityDto, options?: any): AxiosPromise<object> {
            return localVarFp.opportunitiesControllerGetOcrolusData(ocrolusOpportunityDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateOpportunityDto} updateOpportunityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opportunitiesControllerUpdate(updateOpportunityDto: UpdateOpportunityDto, options?: any): AxiosPromise<void> {
            return localVarFp.opportunitiesControllerUpdate(updateOpportunityDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewLoanDebitPayment} newLoanDebitPayment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerCreateLoanDebitPayment(newLoanDebitPayment: NewLoanDebitPayment, options?: any): AxiosPromise<object> {
            return localVarFp.paymentsControllerCreateLoanDebitPayment(newLoanDebitPayment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerFindAll(options?: any): AxiosPromise<Array<LoanPayment>> {
            return localVarFp.paymentsControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerGetNextScheduledPayment(options?: any): AxiosPromise<Array<NextScheduledPayment>> {
            return localVarFp.paymentsControllerGetNextScheduledPayment(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} repaymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerGetNextScheduledPaymentForRepayment(repaymentId: string, options?: any): AxiosPromise<Array<NextScheduledPayment>> {
            return localVarFp.paymentsControllerGetNextScheduledPaymentForRepayment(repaymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} repaymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerGetPaymentAvailability(repaymentId: string, options?: any): AxiosPromise<PaymentDate> {
            return localVarFp.paymentsControllerGetPaymentAvailability(repaymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} repaymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerGetPaymentsForRepayment(repaymentId: string, options?: any): AxiosPromise<Array<LoanPayment>> {
            return localVarFp.paymentsControllerGetPaymentsForRepayment(repaymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerSFUploadNachaFiles(options?: any): AxiosPromise<string> {
            return localVarFp.paymentsControllerSFUploadNachaFiles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerSFUploadReturnFiles(options?: any): AxiosPromise<string> {
            return localVarFp.paymentsControllerSFUploadReturnFiles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payrollControllerCheckIfConnectedToFinch(options?: any): AxiosPromise<PayrollConnectedStatus> {
            return localVarFp.payrollControllerCheckIfConnectedToFinch(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payrollControllerDisconnect(options?: any): AxiosPromise<void> {
            return localVarFp.payrollControllerDisconnect(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExchangeTokenRequest} exchangeTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payrollControllerExchange(exchangeTokenRequest: ExchangeTokenRequest, options?: any): AxiosPromise<void> {
            return localVarFp.payrollControllerExchange(exchangeTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} associatedPhase 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payrollControllerGetCompanyInfo(associatedPhase: string, options?: any): AxiosPromise<object> {
            return localVarFp.payrollControllerGetCompanyInfo(associatedPhase, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payrollControllerGetNextPayroll(options?: any): AxiosPromise<NextPayroll> {
            return localVarFp.payrollControllerGetNextPayroll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} associatedPhase 
         * @param {string} refresh 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payrollControllerGetPayrollInfo(associatedPhase: string, refresh: string, options?: any): AxiosPromise<PayrollInfo> {
            return localVarFp.payrollControllerGetPayrollInfo(associatedPhase, refresh, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plaidControllerAuth(options?: any): AxiosPromise<object> {
            return localVarFp.plaidControllerAuth(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plaidControllerCheckIfConnected(options?: any): AxiosPromise<boolean> {
            return localVarFp.plaidControllerCheckIfConnected(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plaidControllerCreateLinkToken(options?: any): AxiosPromise<object> {
            return localVarFp.plaidControllerCreateLinkToken(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plaidControllerCreateLinkTokenForPlaidLinkUpdate(options?: any): AxiosPromise<object> {
            return localVarFp.plaidControllerCreateLinkTokenForPlaidLinkUpdate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} accountUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plaidControllerDeleteUserById(accountUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.plaidControllerDeleteUserById(accountUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plaidControllerGetAccounts(options?: any): AxiosPromise<object> {
            return localVarFp.plaidControllerGetAccounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plaidControllerGetAssetReport(options?: any): AxiosPromise<string> {
            return localVarFp.plaidControllerGetAssetReport(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plaidControllerGetBalance(options?: any): AxiosPromise<object> {
            return localVarFp.plaidControllerGetBalance(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plaidControllerGetBalanceForSalesforce(options?: any): AxiosPromise<object> {
            return localVarFp.plaidControllerGetBalanceForSalesforce(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plaidControllerGetIdentity(options?: any): AxiosPromise<void> {
            return localVarFp.plaidControllerGetIdentity(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plaidControllerGetInvestmentTransactions(options?: any): AxiosPromise<void> {
            return localVarFp.plaidControllerGetInvestmentTransactions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plaidControllerGetItem(options?: any): AxiosPromise<object> {
            return localVarFp.plaidControllerGetItem(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plaidControllerGetUserList(options?: any): AxiosPromise<object> {
            return localVarFp.plaidControllerGetUserList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SetAccessTokenDTO} setAccessTokenDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plaidControllerRemoveAccessToken(setAccessTokenDTO: SetAccessTokenDTO, options?: any): AxiosPromise<object> {
            return localVarFp.plaidControllerRemoveAccessToken(setAccessTokenDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} accountUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plaidControllerSFgetTransactionsToS3(accountUuid: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.plaidControllerSFgetTransactionsToS3(accountUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SetAccessTokenDTO} setAccessTokenDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plaidControllerSetAccessToken(setAccessTokenDTO: SetAccessTokenDTO, options?: any): AxiosPromise<object> {
            return localVarFp.plaidControllerSetAccessToken(setAccessTokenDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} accountUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plaidControllerTransactionsForAnalysis(accountUuid: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.plaidControllerTransactionsForAnalysis(accountUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalAccessControllerGetIsPortalAccessible(options?: any): AxiosPromise<void> {
            return localVarFp.portalAccessControllerGetIsPortalAccessible(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateContactDto} updateContactDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInfoControllerAdminUpdateUserAttributes(updateContactDto: UpdateContactDto, options?: any): AxiosPromise<object> {
            return localVarFp.userInfoControllerAdminUpdateUserAttributes(updateContactDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteCognitUseroDto} deleteCognitUseroDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInfoControllerDeleteCognitoUserFromSalesforce(deleteCognitUseroDto: DeleteCognitUseroDto, options?: any): AxiosPromise<void> {
            return localVarFp.userInfoControllerDeleteCognitoUserFromSalesforce(deleteCognitUseroDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteCognitUseroDto} deleteCognitUseroDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInfoControllerDisableUserInCognitoFromSalesforce(deleteCognitUseroDto: DeleteCognitUseroDto, options?: any): AxiosPromise<void> {
            return localVarFp.userInfoControllerDisableUserInCognitoFromSalesforce(deleteCognitUseroDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteCognitUseroDto} deleteCognitUseroDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInfoControllerEnableUserInCognitoFromSalesforce(deleteCognitUseroDto: DeleteCognitUseroDto, options?: any): AxiosPromise<void> {
            return localVarFp.userInfoControllerEnableUserInCognitoFromSalesforce(deleteCognitUseroDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteCognitUseroDto} deleteCognitUseroDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInfoControllerGetCognitoUserStatusForSalesforce(deleteCognitUseroDto: DeleteCognitUseroDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.userInfoControllerGetCognitoUserStatusForSalesforce(deleteCognitUseroDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInfoControllerGetUserInfo(options?: any): AxiosPromise<UserObject> {
            return localVarFp.userInfoControllerGetUserInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserNotification} userNotification 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInfoControllerSendOTP(userNotification: UserNotification, options?: any): AxiosPromise<boolean> {
            return localVarFp.userInfoControllerSendOTP(userNotification, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserNotification} userNotification 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInfoControllerSendStatementEmail(userNotification: UserNotification, options?: any): AxiosPromise<boolean> {
            return localVarFp.userInfoControllerSendStatementEmail(userNotification, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserNotification} userNotification 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInfoControllerSendVerificationEmail(userNotification: UserNotification, options?: any): AxiosPromise<boolean> {
            return localVarFp.userInfoControllerSendVerificationEmail(userNotification, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserNotification} userNotification 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInfoControllerUpdatePhone(userNotification: UserNotification, options?: any): AxiosPromise<boolean> {
            return localVarFp.userInfoControllerUpdatePhone(userNotification, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateUserInfoDto} updateUserInfoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInfoControllerUpdateUserInfo(updateUserInfoDto: UpdateUserInfoDto, options?: any): AxiosPromise<UserObject> {
            return localVarFp.userInfoControllerUpdateUserInfo(updateUserInfoDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {CheckForSalesforceAccountDTO} checkForSalesforceAccountDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public accountsControllerCheckIfNewSignUpAlreadyHasSalesforceAccountsOrHasMultipleOrHasCognito(checkForSalesforceAccountDTO: CheckForSalesforceAccountDTO, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).accountsControllerCheckIfNewSignUpAlreadyHasSalesforceAccountsOrHasMultipleOrHasCognito(checkForSalesforceAccountDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateCognitoUserForExistingSalesforceAccountByContactEmailDto} createCognitoUserForExistingSalesforceAccountByContactEmailDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public accountsControllerCreateCognitoUserForExistingSalesforceAccountByContactEmail(createCognitoUserForExistingSalesforceAccountByContactEmailDto: CreateCognitoUserForExistingSalesforceAccountByContactEmailDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).accountsControllerCreateCognitoUserForExistingSalesforceAccountByContactEmail(createCognitoUserForExistingSalesforceAccountByContactEmailDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateUserWithSalesforceDto} createUserWithSalesforceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public accountsControllerCreateFromIdToken(createUserWithSalesforceDto: CreateUserWithSalesforceDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).accountsControllerCreateFromIdToken(createUserWithSalesforceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public accountsControllerGetBankingInfo(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).accountsControllerGetBankingInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetCreditScoreDto} getCreditScoreDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public accountsControllerGetCreditScore(getCreditScoreDto: GetCreditScoreDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).accountsControllerGetCreditScore(getCreditScoreDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public accountsControllerGetISOLinks(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).accountsControllerGetISOLinks(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetLendioPrequalDto} getLendioPrequalDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public accountsControllerGetLendioPrequal(getLendioPrequalDto: GetLendioPrequalDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).accountsControllerGetLendioPrequal(getLendioPrequalDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public accountsControllerGetLendioPrequalDataLocal(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).accountsControllerGetLendioPrequalDataLocal(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public accountsControllerGetMyInfo(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).accountsControllerGetMyInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public accountsControllerGetPayrollCompanies(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).accountsControllerGetPayrollCompanies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public accountsControllerGetUserDataDtoForLendio(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).accountsControllerGetUserDataDtoForLendio(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateAccountDto} updateAccountDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public accountsControllerUpdate(updateAccountDto: UpdateAccountDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).accountsControllerUpdate(updateAccountDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public advancesControllerActivateAdvance(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).advancesControllerActivateAdvance(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateAdvanceDto} createAdvanceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public advancesControllerCreate(createAdvanceDto: CreateAdvanceDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).advancesControllerCreate(createAdvanceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public advancesControllerFindAll(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).advancesControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public advancesControllerGetDraftAdvance(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).advancesControllerGetDraftAdvance(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateAdvanceDto} updateAdvanceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public advancesControllerUpdate(updateAdvanceDto: UpdateAdvanceDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).advancesControllerUpdate(updateAdvanceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public announcementsControllerFindAll(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).announcementsControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public announcementsControllerFindFutureAnnouncements(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).announcementsControllerFindFutureAnnouncements(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public appControllerGetHello(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).appControllerGetHello(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateBankAccountDto} createBankAccountDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public bankAccountsControllerCreate(createBankAccountDto: CreateBankAccountDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).bankAccountsControllerCreate(createBankAccountDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public bankAccountsControllerFindAll(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).bankAccountsControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public bankAccountsControllerGetSendToPayroInfo(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).bankAccountsControllerGetSendToPayroInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateBankAccountDto} createBankAccountDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public bankAccountsControllerManualBankAdd(createBankAccountDto: CreateBankAccountDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).bankAccountsControllerManualBankAdd(createBankAccountDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public bankAccountsControllerRemove(uuid: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).bankAccountsControllerRemove(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} repaymentSelection 
     * @param {SelectBankAccountDto} selectBankAccountDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public bankAccountsControllerSelectForDeposit(repaymentSelection: boolean, selectBankAccountDto: SelectBankAccountDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).bankAccountsControllerSelectForDeposit(repaymentSelection, selectBankAccountDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} repaymentSelection 
     * @param {SelectBankAccountDto} selectBankAccountDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public bankAccountsControllerSelectForWithdrawal(repaymentSelection: boolean, selectBankAccountDto: SelectBankAccountDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).bankAccountsControllerSelectForWithdrawal(repaymentSelection, selectBankAccountDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid 
     * @param {UpdateBankAccountDto} updateBankAccountDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public bankAccountsControllerUpdate(uuid: string, updateBankAccountDto: UpdateBankAccountDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).bankAccountsControllerUpdate(uuid, updateBankAccountDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateBankAccountDto} createBankAccountDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public bankAccountsControllerUpsert(createBankAccountDto: CreateBankAccountDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).bankAccountsControllerUpsert(createBankAccountDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public cashAdvanceAgreementControllerFindAll(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).cashAdvanceAgreementControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SalesforceLog} salesforceLog 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public changeDataControllerLogSalesforceData(salesforceLog: SalesforceLog, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).changeDataControllerLogSalesforceData(salesforceLog, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EmailUpdateDto} emailUpdateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public changeDataControllerSendEmail(emailUpdateDto: EmailUpdateDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).changeDataControllerSendEmail(emailUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public changeDataControllerSendEmailsBulk(requestBody: Array<string>, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).changeDataControllerSendEmailsBulk(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateContactDto} createContactDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public contactsControllerCreate(createContactDto: CreateContactDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).contactsControllerCreate(createContactDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public contactsControllerFindAll(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).contactsControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public contactsControllerGetPayrollCompanyinfo(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).contactsControllerGetPayrollCompanyinfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public contactsControllerRemove(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).contactsControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateContactDto} updateContactDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public contactsControllerUpdate(id: string, updateContactDto: UpdateContactDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).contactsControllerUpdate(id, updateContactDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertAllContactInfo} upsertAllContactInfo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public contactsControllerUpsertAll(upsertAllContactInfo: UpsertAllContactInfo, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).contactsControllerUpsertAll(upsertAllContactInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public dealsControllerActivateDeal(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).dealsControllerActivateDeal(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SignDocumentsDTO} signDocumentsDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public dealsControllerCashAdvanceActivateDeal(signDocumentsDTO: SignDocumentsDTO, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).dealsControllerCashAdvanceActivateDeal(signDocumentsDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateDealDto} updateDealDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public dealsControllerCashAdvanceActivateDealCancel(updateDealDto: UpdateDealDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).dealsControllerCashAdvanceActivateDealCancel(updateDealDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateDealDto} createDealDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public dealsControllerCreate(createDealDto: CreateDealDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).dealsControllerCreate(createDealDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public dealsControllerFindAll(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).dealsControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public dealsControllerGetCATransactionAvailability(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).dealsControllerGetCATransactionAvailability(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public dealsControllerGetDraftDeal(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).dealsControllerGetDraftDeal(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public dealsControllerGetTransactionAvailability(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).dealsControllerGetTransactionAvailability(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateDealDto} updateDealDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public dealsControllerUpdate(updateDealDto: UpdateDealDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).dealsControllerUpdate(updateDealDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SignDocumentsDTO} signDocumentsDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public documentsControllerCashAdvanceAgreeement(signDocumentsDTO: SignDocumentsDTO, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).documentsControllerCashAdvanceAgreeement(signDocumentsDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteFileDTO} deleteFileDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public documentsControllerDeleteStatement(deleteFileDTO: DeleteFileDTO, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).documentsControllerDeleteStatement(deleteFileDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public documentsControllerGenerateCashAdvanceContract(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).documentsControllerGenerateCashAdvanceContract(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public documentsControllerGenerateDocuments(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).documentsControllerGenerateDocuments(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public documentsControllerGetAdditionalBankStatements(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).documentsControllerGetAdditionalBankStatements(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public documentsControllerGetBankStatements(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).documentsControllerGetBankStatements(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PdfFileDTO} pdfFileDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public documentsControllerGetCashAdvanceAgreement(pdfFileDTO: PdfFileDTO, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).documentsControllerGetCashAdvanceAgreement(pdfFileDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public documentsControllerGetClearInfo(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).documentsControllerGetClearInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CommissionFileDTO} commissionFileDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public documentsControllerGetCommissionUrlStatementForSalesforce(commissionFileDTO: CommissionFileDTO, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).documentsControllerGetCommissionUrlStatementForSalesforce(commissionFileDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public documentsControllerGetDocumentNames(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).documentsControllerGetDocumentNames(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetFilesnamesDto} getFilesnamesDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public documentsControllerGetDocumentNamesApiClient(getFilesnamesDto: GetFilesnamesDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).documentsControllerGetDocumentNamesApiClient(getFilesnamesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetOcrolusStatementDTO} getOcrolusStatementDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public documentsControllerGetOcrolusDailyBalancesForSalesforce(getOcrolusStatementDTO: GetOcrolusStatementDTO, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).documentsControllerGetOcrolusDailyBalancesForSalesforce(getOcrolusStatementDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetOcrolusStatementDTO} getOcrolusStatementDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public documentsControllerGetOcrolusStatementForSalesforce(getOcrolusStatementDTO: GetOcrolusStatementDTO, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).documentsControllerGetOcrolusStatementForSalesforce(getOcrolusStatementDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public documentsControllerGetPayrollStatements(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).documentsControllerGetPayrollStatements(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} repaymentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public documentsControllerGetPdfStatement(repaymentUuid: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).documentsControllerGetPdfStatement(repaymentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PdfFileDTO} pdfFileDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public documentsControllerGetPdfUrlStatementForSalesforce(pdfFileDTO: PdfFileDTO, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).documentsControllerGetPdfUrlStatementForSalesforce(pdfFileDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DownloadFileDTO} downloadFileDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public documentsControllerGetPresignedDownloadUrl(downloadFileDTO: DownloadFileDTO, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).documentsControllerGetPresignedDownloadUrl(downloadFileDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public documentsControllerGetUnderwritingPayrollStatements(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).documentsControllerGetUnderwritingPayrollStatements(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CommissionFileDTO} commissionFileDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public documentsControllerSendEmailCommission(commissionFileDTO: CommissionFileDTO, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).documentsControllerSendEmailCommission(commissionFileDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SignDocumentsDTO} signDocumentsDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public documentsControllerSignDocuments(signDocumentsDTO: SignDocumentsDTO, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).documentsControllerSignDocuments(signDocumentsDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public documentsControllerSubmitBankStatements(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).documentsControllerSubmitBankStatements(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetOcrolusStatementDTO} getOcrolusStatementDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public documentsControllerUploadStatementToOcrolus(getOcrolusStatementDTO: GetOcrolusStatementDTO, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).documentsControllerUploadStatementToOcrolus(getOcrolusStatementDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<Array<any>>} files Attachments
     * @param {string} documentType bank-statement or payroll-statement
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public documentsControllerUploadStatements(files: Array<Array<any>>, documentType: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).documentsControllerUploadStatements(files, documentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FileUploadUrlDTO} fileUploadUrlDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public documentsControllerUploadStatementsUrl(fileUploadUrlDTO: FileUploadUrlDTO, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).documentsControllerUploadStatementsUrl(fileUploadUrlDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteFileDTO} deleteFileDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public documentsControllerViewStatement(deleteFileDTO: DeleteFileDTO, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).documentsControllerViewStatement(deleteFileDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public opportunitiesControllerCreateNewOpportunity(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).opportunitiesControllerCreateNewOpportunity(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public opportunitiesControllerFindAll(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).opportunitiesControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public opportunitiesControllerFindCreditIncreaseOpportunity(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).opportunitiesControllerFindCreditIncreaseOpportunity(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OcrolusOpportunityDto} ocrolusOpportunityDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public opportunitiesControllerGetOcrolusData(ocrolusOpportunityDto: OcrolusOpportunityDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).opportunitiesControllerGetOcrolusData(ocrolusOpportunityDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateOpportunityDto} updateOpportunityDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public opportunitiesControllerUpdate(updateOpportunityDto: UpdateOpportunityDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).opportunitiesControllerUpdate(updateOpportunityDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewLoanDebitPayment} newLoanDebitPayment 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentsControllerCreateLoanDebitPayment(newLoanDebitPayment: NewLoanDebitPayment, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).paymentsControllerCreateLoanDebitPayment(newLoanDebitPayment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentsControllerFindAll(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).paymentsControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentsControllerGetNextScheduledPayment(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).paymentsControllerGetNextScheduledPayment(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} repaymentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentsControllerGetNextScheduledPaymentForRepayment(repaymentId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).paymentsControllerGetNextScheduledPaymentForRepayment(repaymentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} repaymentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentsControllerGetPaymentAvailability(repaymentId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).paymentsControllerGetPaymentAvailability(repaymentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} repaymentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentsControllerGetPaymentsForRepayment(repaymentId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).paymentsControllerGetPaymentsForRepayment(repaymentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentsControllerSFUploadNachaFiles(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).paymentsControllerSFUploadNachaFiles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentsControllerSFUploadReturnFiles(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).paymentsControllerSFUploadReturnFiles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public payrollControllerCheckIfConnectedToFinch(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).payrollControllerCheckIfConnectedToFinch(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public payrollControllerDisconnect(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).payrollControllerDisconnect(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExchangeTokenRequest} exchangeTokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public payrollControllerExchange(exchangeTokenRequest: ExchangeTokenRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).payrollControllerExchange(exchangeTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} associatedPhase 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public payrollControllerGetCompanyInfo(associatedPhase: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).payrollControllerGetCompanyInfo(associatedPhase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public payrollControllerGetNextPayroll(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).payrollControllerGetNextPayroll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} associatedPhase 
     * @param {string} refresh 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public payrollControllerGetPayrollInfo(associatedPhase: string, refresh: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).payrollControllerGetPayrollInfo(associatedPhase, refresh, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public plaidControllerAuth(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).plaidControllerAuth(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public plaidControllerCheckIfConnected(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).plaidControllerCheckIfConnected(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public plaidControllerCreateLinkToken(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).plaidControllerCreateLinkToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public plaidControllerCreateLinkTokenForPlaidLinkUpdate(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).plaidControllerCreateLinkTokenForPlaidLinkUpdate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} accountUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public plaidControllerDeleteUserById(accountUuid: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).plaidControllerDeleteUserById(accountUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public plaidControllerGetAccounts(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).plaidControllerGetAccounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public plaidControllerGetAssetReport(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).plaidControllerGetAssetReport(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public plaidControllerGetBalance(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).plaidControllerGetBalance(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public plaidControllerGetBalanceForSalesforce(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).plaidControllerGetBalanceForSalesforce(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public plaidControllerGetIdentity(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).plaidControllerGetIdentity(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public plaidControllerGetInvestmentTransactions(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).plaidControllerGetInvestmentTransactions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public plaidControllerGetItem(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).plaidControllerGetItem(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public plaidControllerGetUserList(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).plaidControllerGetUserList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SetAccessTokenDTO} setAccessTokenDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public plaidControllerRemoveAccessToken(setAccessTokenDTO: SetAccessTokenDTO, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).plaidControllerRemoveAccessToken(setAccessTokenDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} accountUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public plaidControllerSFgetTransactionsToS3(accountUuid: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).plaidControllerSFgetTransactionsToS3(accountUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SetAccessTokenDTO} setAccessTokenDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public plaidControllerSetAccessToken(setAccessTokenDTO: SetAccessTokenDTO, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).plaidControllerSetAccessToken(setAccessTokenDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} accountUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public plaidControllerTransactionsForAnalysis(accountUuid: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).plaidControllerTransactionsForAnalysis(accountUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public portalAccessControllerGetIsPortalAccessible(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).portalAccessControllerGetIsPortalAccessible(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateContactDto} updateContactDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userInfoControllerAdminUpdateUserAttributes(updateContactDto: UpdateContactDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userInfoControllerAdminUpdateUserAttributes(updateContactDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteCognitUseroDto} deleteCognitUseroDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userInfoControllerDeleteCognitoUserFromSalesforce(deleteCognitUseroDto: DeleteCognitUseroDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userInfoControllerDeleteCognitoUserFromSalesforce(deleteCognitUseroDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteCognitUseroDto} deleteCognitUseroDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userInfoControllerDisableUserInCognitoFromSalesforce(deleteCognitUseroDto: DeleteCognitUseroDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userInfoControllerDisableUserInCognitoFromSalesforce(deleteCognitUseroDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteCognitUseroDto} deleteCognitUseroDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userInfoControllerEnableUserInCognitoFromSalesforce(deleteCognitUseroDto: DeleteCognitUseroDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userInfoControllerEnableUserInCognitoFromSalesforce(deleteCognitUseroDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteCognitUseroDto} deleteCognitUseroDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userInfoControllerGetCognitoUserStatusForSalesforce(deleteCognitUseroDto: DeleteCognitUseroDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userInfoControllerGetCognitoUserStatusForSalesforce(deleteCognitUseroDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userInfoControllerGetUserInfo(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userInfoControllerGetUserInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserNotification} userNotification 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userInfoControllerSendOTP(userNotification: UserNotification, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userInfoControllerSendOTP(userNotification, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserNotification} userNotification 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userInfoControllerSendStatementEmail(userNotification: UserNotification, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userInfoControllerSendStatementEmail(userNotification, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserNotification} userNotification 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userInfoControllerSendVerificationEmail(userNotification: UserNotification, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userInfoControllerSendVerificationEmail(userNotification, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserNotification} userNotification 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userInfoControllerUpdatePhone(userNotification: UserNotification, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userInfoControllerUpdatePhone(userNotification, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateUserInfoDto} updateUserInfoDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userInfoControllerUpdateUserInfo(updateUserInfoDto: UpdateUserInfoDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userInfoControllerUpdateUserInfo(updateUserInfoDto, options).then((request) => request(this.axios, this.basePath));
    }
}


