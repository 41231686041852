import {
  DefaultApi,
  DraftDeal,
} from '../../api-utils/generated-client';

const checkIfValidPDF = (file: any) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const pdfSignature = [37, 80, 68, 70, 45]; // "%PDF-"
        let isPdfFile = true;

        for (let i = 0; i < pdfSignature.length; i++) {
          if (data[i] !== pdfSignature[i]) {
            isPdfFile = false;
            break;
          }
        }

        resolve(isPdfFile);
      } catch (e) {
        console.log(e);
        resolve(false);
      }
    };
    fileReader.onerror = () => {
      resolve(false); // Error reading the file
    };
    fileReader.readAsArrayBuffer(file);
  });
};

export const uploadFiles = async (
  filesToUpload: any[],
  client: DefaultApi,
  isUnderwriting?: boolean,
): Promise<string> => {
  console.log('statements', filesToUpload);
  console.log(isUnderwriting, 'isUnderwriting');

  for (let i = 0; i < filesToUpload.length; i++) {
    let singleFile = filesToUpload[i];

    try {
      const isValidPDF = await checkIfValidPDF(singleFile);
      if (!isValidPDF) {
        return 'fail';
      }
      const urlRes =
        await client.documentsControllerUploadStatementsUrl({
          fileName: singleFile.name,
          documentType: isUnderwriting
            ? 'underwriting-payroll-statement'
            : 'payroll-statement',
        });

      console.log('url', urlRes);
      // if (urlRes.status == 201) {
      const options = {
        method: 'PUT',
        body: singleFile,
        mode: 'cors',
      };
      await fetch(urlRes.data, options as RequestInit);

      return 'success';
    } catch {
      return 'fail';
    }
  }
  return '';
};

export const deleteFile = async (
  fileName: string,
  client: DefaultApi,
  isUnderwriting?: boolean,
) => {
  client.documentsControllerDeleteStatement({
    fileName,
    documentType: isUnderwriting
      ? 'underwriting-payroll-statement'
      : 'payroll-statement',
  });
};

export const getFiles = async (
  client: DefaultApi,
  isUnderwriting?: boolean,
): Promise<string[]> => {
  const statementsRes = isUnderwriting
    ? await client.documentsControllerGetUnderwritingPayrollStatements()
    : await client.documentsControllerGetPayrollStatements();
  return statementsRes.data;
};

export const calculateTotalPayback = (
  originalFundingAmount: number,
  selectedNumberOfWeeks: number,
  interestAmount: number,
  mca_account?: boolean,
) => {
  const interest =
    selectedNumberOfWeeks * interestAmount * originalFundingAmount;
  const processingFee =
    !mca_account &&
    interestAmount === 0.015 &&
    originalFundingAmount > 35000
      ? 0.005 * originalFundingAmount
      : 175;
  return originalFundingAmount + interest + processingFee;
};

export const setTheInterestAmountFunc = (
  fundingAmount: number,
  interestOverrideAmount: number,
) => {
  if (interestOverrideAmount > 0) {
    return interestOverrideAmount / 100;
  } else if (fundingAmount < 25000) {
    return 0.02;
  } else if (fundingAmount >= 25000 && fundingAmount <= 75000) {
    return 0.0175;
  } else if (fundingAmount > 75000) {
    return 0.015;
  }

  return 0.015;
};
