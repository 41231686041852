/* eslint-disable max-len */
import { useContext, useEffect } from 'react';
import { useState } from 'react';
import { getClient } from '../../../api-utils/general-utils';
import {
  BankAccount,
  TransactionAvailability,
  UpdateBankAccountDto,
} from '../../../api-utils/generated-client';
import FooterButtons from '../../../Footer/footer-buttons';
import TitleSection from '../../../Header/title-section';
import Loader from '../../../widgets/Loader';
import './index.scss';
import { formatDate, formatNumberAsDollars } from '../../../utils';
import { DateTime } from 'luxon';
import PayroButton from '../../../widgets/PayroButton';
import RequestFundingTitle from '../request-funding-title';
import {
  calculateTotalPayback,
  setTheInterestAmountFunc,
} from '../utils';
import PayroSelect from '../../../widgets/PayroSelectv2';
import {
  useRecoilValue,
  useSetRecoilState,
  useRecoilState,
} from 'recoil';
import BankFetcher from '../DataFetchers/bank-fetcher';

import {
  bankAccountsState,
  dealDraftState,
  sectionState,
  transactionAvailabilityState,
  needsManualFormState,
  showHideClassNameModalManualFormState,
  bankAccountToUpdateState,
  chaseBankAccountsArrayState,
  chaseBankAccountToUpdateState,
  chaseBankAccountTempState,
  isConfirmNextDisabledState,
  interestAmountState,
} from '../../../recoil-state/request-funding-states';
import ManualBankModal from '../../../widgets/PlaidConnector/ManualBankModal';
import { MessageContext } from '../../../context';
import OpenModalLink from './OpenManualModalLink';
import { isPlaidConnectedState } from '../../../recoil-state/general-states';
import InfoMessage from '../../../widgets/InfoMessage';
import { clientState } from '../../../recoil-state/application-stage-states';

interface myProps {
  confirmButtonClick: Function;
}

interface ConfirmationInfoLine {
  theLabel: string;
  theValue?: any;
}

const ConfirmPage = (props: myProps) => {
  const [chaseBankAccountToUpdate, setChaseBankAccountToUpdate] =
    useRecoilState<UpdateBankAccountDto>(
      chaseBankAccountToUpdateState,
    );
  const [isConfirmNextDisabled, setIsConfirmNextDisabled] =
    useRecoilState<boolean>(isConfirmNextDisabledState);
  const section = useRecoilValue(sectionState);
  const [dealRecord, setDealRecord] = useRecoilState(dealDraftState);
  const [bankAccounts, setBankAccounts] =
    useRecoilState(bankAccountsState);
  const [signedAch, setSignedAch] = useState(false);
  const [showClickedConfirmLoader, setShowClickConfirmedLoader] =
    useState(false);
  const [transactionAvailability, setTransactionAvailability] =
    useRecoilState<TransactionAvailability>(
      transactionAvailabilityState,
    );
  const [legalCompanyName, setLegalCompanyName] = useState<string>();
  const [needsManualForm, setNeedsManualForm] = useRecoilState(
    needsManualFormState,
  );
  const isplaidConnected = useRecoilValue(isPlaidConnectedState);
  const [
    showHideClassNameModalDisplay,
    setShowHideClassNameModalDisplay,
  ] = useRecoilState(showHideClassNameModalManualFormState);
  const [bankAccountToUpdate, setBankAccountToUpdate] =
    useRecoilState<UpdateBankAccountDto>(bankAccountToUpdateState);
  const [showHideClassName1, setShowHideClassName1] = useState(
    'modal display-none',
  );
  const [interestOverrideAmount, setInterestOverrideAmount] =
    useRecoilState<number>(interestAmountState);
  const [timeout, setTimeoutState] = useState(false);
  useEffect(() => {
    const getTransactionAvailability = async () => {
      if (!transactionAvailability.fundingDate) {
        const client = await getClient();
        if (client) {
          const availability =
            await client.dealsControllerGetTransactionAvailability();
          setTransactionAvailability(availability.data);
        }
      }
    };
    getTransactionAvailability().then(() => {});
  }, []);
  useEffect(() => {
    console.log('rerender confirm page');
  }, [bankAccounts, isConfirmNextDisabled]);

  useEffect(() => {
    const getCompanyName = async () => {
      if (!legalCompanyName) {
        const client = await getClient();
        if (client) {
          const account = await client.accountsControllerGetMyInfo();
          setLegalCompanyName(account.data.legal_name);
        }
      }
    };
    getCompanyName();
  }, [bankAccounts, needsManualForm, isConfirmNextDisabled]);

  if (!transactionAvailability.fundingDate) {
    return <Loader />;
  }

  if (
    !dealRecord.funding_amount ||
    !dealRecord.selected_num_of_weeks_in_portal ||
    !bankAccounts
  ) {
    return (
      <div>
        <Loader message="1 second please" />
        <BankFetcher getFromApiEvenIfEmptyArray="yes" />
      </div>
    );
  }

  const depositBank = bankAccounts.find(
    (ba) => ba.uuid === dealRecord.deposit_bank_account,
  );
  const withdrawalBank = bankAccounts.find(
    (ba) => ba.uuid === dealRecord.withdrawal_bank_account,
  );
  const theInterestAmount = setTheInterestAmountFunc(
    dealRecord.funding_amount as number,
    interestOverrideAmount,
  );

  const totalPayback = formatNumberAsDollars(
    calculateTotalPayback(
      dealRecord.funding_amount,
      dealRecord.selected_num_of_weeks_in_portal,
      theInterestAmount,
    ),
  );

  const paybackDate = DateTime.fromISO(
    transactionAvailability.fundingDate,
  )
    .plus({ weeks: dealRecord.selected_num_of_weeks_in_portal })
    .toLocaleString();

  const getAccountLastFour = (b: any) => {
    if (
      b.account_last_four != '' ||
      b.account_last_four != undefined ||
      b.account_last_four != null
    ) {
      return b.account_last_four;
    } else {
      getLastFourCharacters(b.bank_account_number);
    }
  };

  const depositBankSelect = (
    <select
      key={depositBank?.uuid}
      id="mySelect"
      className="selectbank"
      value={depositBank?.uuid}
      onChange={async (e) => {
        console.log('selecet deposit');
        let uuid = e.target.value;
        const bankAccountWithThisUuid = bankAccounts.find(
          (b) => b.uuid === uuid,
        );
        const client = await getClient();
        if (!client) {
          return;
        }
        if (!bankAccountWithThisUuid) {
          return;
        }
        await setDealRecord({
          ...dealRecord,
          deposit_bank_account: uuid,
        });
        await client.bankAccountsControllerSelectForDeposit(true, {
          uuid: uuid,
        });
      }}
    >
      <option value="">Select Bank</option>
      {bankAccounts.map((b) => (
        <option value={b.uuid}>
          {`${b.bank_name ? b.bank_name : ''} ${getAccountLastFour(
            b,
          )}`}
        </option>
      ))}
    </select>
  );

  const withdrawalBankSelect = (
    <select
      id="mySelect"
      className="selectbank"
      value={withdrawalBank?.uuid}
      onChange={async (e) => {
        let uuid = e.target.value;
        const bankAccountWithThisUuid = bankAccounts.find(
          (b) => b.uuid === uuid,
        );
        const client = await getClient();
        if (!client) {
          return;
        }
        if (!bankAccountWithThisUuid) {
          return;
        }

        await setDealRecord({
          ...dealRecord,
          withdrawal_bank_account: uuid,
        });
        setTimeoutState(true); // Set timeout to true immediately on button click

        setTimeout(() => {
          setTimeoutState(false); // Set timeout back to false after 5 seconds
        }, 1000);
        await client.bankAccountsControllerSelectForWithdrawal(true, {
          uuid: uuid,
        });
      }}
    >
      <option value="">Select Bank</option>
      {bankAccounts.map((b) => (
        <option value={b.uuid}>
          {`${b.bank_name ? b.bank_name : ''} ${getAccountLastFour(
            b,
          )}`}
        </option>
      ))}
    </select>
  );

  const confirmationInfo: ConfirmationInfoLine[] = [
    { theLabel: 'Total Payback:', theValue: totalPayback },
    { theLabel: 'Funds sent to Bank:', theValue: depositBankSelect },
    { theLabel: 'Repayment Bank:', theValue: withdrawalBankSelect },
  ];

  const infoDisplay = confirmationInfo.map((el, idx) => {
    return (
      <div key={idx * 10} className="confirmation-info-wrapper">
        <div className="confirmation-info-label">{el.theLabel}</div>
        <div className="confirmation-info-value">{el.theValue}</div>
      </div>
    );
  });
  const Read = () => {
    setShowHideClassName1('modal display-block');
  };
  const setTheINterestPertermOnRepayment = async () => {
    const client = await getClient();
    if (!client) {
      return;
    }
    await client.dealsControllerUpdate({
      InterestPerTerm: theInterestAmount * 100,
    });
    console.log(
      'updating the function the interste amount',
      theInterestAmount,
    );
  };
  return (
    <div id="confirm-outer-wrapper">
      {needsManualForm && (
        <ManualBankModal
          key={bankAccountToUpdate.uuid}
        ></ManualBankModal>
      )}
      <RequestFundingTitle
        centered={section == 'onboarding'}
        section={section}
        fundingStep="confirm"
        title={
          section == 'onboarding'
            ? 'Review and Confirm'
            : 'Review Details'
        }
        subtitle=""
      />

      <BankFetcher getFromApiEvenIfEmptyArray="yes" />

      <div
        id={
          section == 'more-funding'
            ? 'confirm-container-repeat-funding'
            : 'confirm-container'
        }
      >
        <div>
          <div id="confirm-highlights">
            <p
              className={
                section == 'onboarding'
                  ? '  funding-amount-color'
                  : '  repeat-funding'
              }
            >
              Funding Amount:
            </p>
            <p
              className={
                section == 'onboarding'
                  ? 'total-repayment-val-confirm  amount-design'
                  : 'total-repayment-val-confirm amount-design'
              }
            >
              {formatNumberAsDollars(dealRecord.funding_amount)}
            </p>

            <p
              className={
                section == 'more-funding'
                  ? 'your-numbers-label confirm-label-total-loan'
                  : 'your-numbers-label confirm-label-total-loan'
              }
            >
              Payback Date:
              <span className=" confirm-label-total-loan-amount">
                {''} {paybackDate}
              </span>
            </p>
          </div>
        </div>
        <div
          className={
            section == 'more-funding'
              ? 'funding-date-more'
              : 'funding-date'
          }
        >
          <p className="funding-date-message">
            {' '}
            The funds will arrive in your account on {''}
            {DateTime.fromISO(
              transactionAvailability.fundingDate,
            ).toLocaleString()}
          </p>
          <p className="funding-date-explanation">
            The deadline for same-day funding is 2:00 PM EST
          </p>
        </div>

        {new Date(transactionAvailability.fundingDate) >
        new Date() ? (
          <InfoMessage
            theBackgroundColor="yellow"
            messageText={`Funding is unavailable today. Your funding date is 
             ${DateTime.fromISO(
               transactionAvailability.fundingDate,
             ).toLocaleString()}.`}
          ></InfoMessage>
        ) : (
          ''
        )}
        {infoDisplay}
        <div
          className={
            section == 'more-funding'
              ? 'ach-container-more'
              : 'ach-container'
          }
        >
          <p className="ach-header-text">
            <input
              type="checkbox"
              onChange={() => setSignedAch(!signedAch)}
              className="grey-square"
              // className="checkbox-design"
            />

            <span className="ach-span">ACH Debit Authorization:</span>
          </p>

          {withdrawalBank?.ach_auth_signed == false && (
            <>
              <p className="readfullcontainer">
                <span className="readfull" onClick={Read}>
                  Read full
                </span>
                <div id="myModal" className={showHideClassName1}>
                  <div className="modal-content-repay">
                    <span
                      onClick={() => {
                        setShowHideClassName1('modal display-none');
                      }}
                      className="close"
                    >
                      &times;
                    </span>
                    <>
                      <h2>ACH AUTHORIZATION</h2>
                      <div>
                        <div>
                          <h4>
                            <u>Authorization</u>
                          </h4>
                          Business identified authorizes Payro Capital
                          LLC or its affiliate Payro finance LLC and
                          its agents to initiate automated clearing
                          house (“ACH”) electronic credit entries to
                          the following business financial institution
                          account (and, if necessary, electronically
                          debit the financial institution account to
                          correct erroneous ACH credits), as follows:
                        </div>

                        <h4>
                          <u>Business Account for Payments</u>
                        </h4>
                        <p>
                          The Business represents and warrants that
                          the Business Account provided for Payment is
                          used solely for business and commercial
                          purposes and NOT for any personal, family or
                          household purpose.
                        </p>
                        <h4>
                          <u>Account Information</u>
                        </h4>
                        <p>
                          The Business certifies that all information
                          provided with respect to the Business
                          Account MCA Payments is accurate and the
                          Business will undertake to inform Payro
                          Capital, in writing, of any change to the
                          Business Account for MCA Payments
                          information provided in this ACH
                          Authorization prior to the next payment due
                          date.
                        </p>
                        <h4>
                          <u>Financial Institution Verification</u>
                        </h4>
                        <p>
                          The Business acknowledges that its Financial
                          Institution is not required to verify that
                          any payment was issued in accordance with
                          the particulars of this ACH Authorization,
                          including, without limitation, the amount of
                          any payment.
                        </p>
                        <h4>
                          <u>Agreement</u>
                        </h4>
                        <p>
                          By signing below, the Business’ authorized
                          representative acknowledges he/she has read,
                          understands, and agrees to all of the terms
                          of this ACH Authorization, and has received
                          a copy of this ACH Authorization to keep.
                          The Business owns the Business Account for
                          Payments and does not require anyone else’s
                          consent to authorize ACH credits to or
                          debits from the Business Account for
                          Payments. The Business understands and
                          agrees that the ACH transactions authorized
                          under this ACH Authorization must comply
                          with all applicable law and the rules and
                          guidelines issued from time to time by NACHA
                          (formerly known as the National Automated
                          Clearing House Association).
                        </p>
                      </div>
                    </>
                  </div>
                </div>
              </p>{' '}
            </>
          )}
          <p className="ach-text">{`I hereby authorize Payro Finance LLC to debit from ${
            withdrawalBank?.bank_name
          }  ${getLastFourCharacters(
            withdrawalBank?.bank_account_number,
          )} account according to the terms of all signed contracts between ${legalCompanyName} company and Payro, and if necessary, initiate adjustments for any transactions credited or debited in error.`}</p>
        </div>
      </div>

      {showClickedConfirmLoader && (
        <Loader message="Hold on a few seconds while we process your request!" />
      )}
      {!showClickedConfirmLoader && (
        <div
          className={
            section == 'onboarding' ? '' : 'get-funded-button-wrapper'
          }
        >
          <PayroButton
            className={
              section == 'onboarding'
                ? 'get-funded-button-onboarding'
                : 'get-funded-button-repeat-funding'
            }
            centered={true}
            onClick={async () => {
              setTheINterestPertermOnRepayment();

              if (
                depositBank &&
                depositBank.bank_name?.toLowerCase() == 'chase' &&
                depositBank.is_real_bank_numbers == false
              ) {
                setIsConfirmNextDisabled(true);
                setChaseBankAccountToUpdate({
                  ...chaseBankAccountToUpdate,
                  uuid: depositBank?.uuid,
                  bank_name: depositBank?.bank_name,
                  account_last_four: depositBank?.account_last_four,
                  bank_account_number: '',
                  bank_routing_number: '',
                });

                setNeedsManualForm(true);
                await setShowHideClassNameModalDisplay(
                  'modal display-block',
                );
              } else if (
                withdrawalBank &&
                withdrawalBank.bank_name?.toLowerCase() == 'chase' &&
                withdrawalBank.is_real_bank_numbers == false
              ) {
                setIsConfirmNextDisabled(true);
                setChaseBankAccountToUpdate({
                  ...chaseBankAccountToUpdate,
                  uuid: withdrawalBank?.uuid,
                  bank_name: withdrawalBank?.bank_name,
                  account_last_four:
                    withdrawalBank?.account_last_four,
                  bank_account_number: '',
                  bank_routing_number: '',
                });

                setNeedsManualForm(true);
                await setShowHideClassNameModalDisplay(
                  'modal display-block',
                );
              } else {
                setIsConfirmNextDisabled(false);
                setShowClickConfirmedLoader(true);
                props.confirmButtonClick();
              }
            }}
            buttonSize="large"
            disabled={
              signedAch == false ||
              !depositBank ||
              !withdrawalBank ||
              timeout
            }
          >
            Get Funded
          </PayroButton>
        </div>
      )}
    </div>
  );
};

const getLastFourCharacters = (s: string | undefined) => {
  if (!s) {
    return '';
  }

  if (s.length <= 4) {
    return s;
  }

  return s.substr(s.length - 4, 4);
};

export default ConfirmPage;
