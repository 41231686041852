import './index.css';

export default function RateChangeNotice() {
  return (
    <div id="forgot-password-page-wrapper" className="main-body">
      <div>
        <h4 className="header">
          Notice of Change in Standard Transaction Rates
        </h4>
        <h5 className="sub">Effective February 15, 2025</h5>
        <div className="text">
          Please be advised that, effective February 15, 2025, there
          will be a modification to the applicable rates governing
          transactions facilitated through our platform. The revised
          standard transaction rates shall be as follows:
        </div>
        <h5> Rates and Fees</h5>
        <div className="text">
          <p>
            Transactions between $5,000 and $24,999 shall be subject
            to a rate of 2.00% per week, transactions between $25,000
            and $74,999 shall be subject to a rate of 1.75% per week,
            and transactions of $75,000 or more shall be subject to a
            rate of 1.50% per week. The minimum transaction fee shall
            be $175. For transactions subject to the 1.50% weekly
            rate, a transaction fee of 0.50% of the transaction amount
            shall apply; however, in no event shall this fee be less
            than $175.
          </p>
          <p>
            These rates shall be applied on a per-transaction basis
            and shall not be contingent upon any overarching credit
            line. Payro reserves the unilateral right to amend or
            modify these rates at any time, at its sole discretion. It
            is the sole responsibility of the borrower to review the
            applicable rates prior to initiating any transaction.
            Furthermore, Payro retains the right to override the
            stated standard transaction rates and impose an
            alternative rate, whether higher or lower, at its
            discretion. Additionally, a transaction fee shall be
            applied as follows:
          </p>
        </div>
        <h5> Disclosures & Disclaimers</h5>

        <div className="text">
          <p>
            No Guarantee of Terms: The above-listed rates, fees, and
            terms are subject to change without prior notice at the
            sole discretion of Payro. The rates outlined herein
            reflect our standard transaction pricing but do not
            constitute a contractual obligation or guarantee of
            availability.
          </p>
          <p>
            Borrower Responsibility: Borrowers are responsible for
            reviewing the applicable transaction rates and fees prior
            to initiating any transaction. Payro shall not be liable
            for any failure by the borrower to review or understand
            the applicable rates.
          </p>
          <p>
            Modification & Waiver: Payro reserves the right to modify,
            waive, or apply alternative rates or fees at its sole
            discretion. Any such modification, waiver, or
            discretionary adjustment shall not be deemed a precedent
            or entitlement for future transactions.
          </p>
          <p>
            Non-Binding Communication: This notice is for
            informational purposes only and does not constitute an
            offer, commitment, or guarantee of financing. All
            transactions are subject to approval and compliance with
            applicable terms and conditions.
          </p>
          <p>
            Limitation of Liability: Under no circumstances shall
            Payro be liable for any indirect, incidental, special, or
            consequential damages arising from or related to any
            changes in transaction rates, fees, or terms.
          </p>
          <p>
            By proceeding with any transaction on or after February
            15, 2025, you acknowledge and agree to be bound by these
            revised terms. Should you have any questions or require
            further clarification, please contact us at (833)271-4499
            or email support@payrofinance.com.
          </p>
        </div>
      </div>
    </div>
  );
}
